import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchReservation,
  fetchReservationFulFilled,
} from './actions';
import { ConciergeReservation } from 'medium/api';

const updateReservationEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_RESERVATION),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(ConciergeReservation.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(fetchReservation()),
          );
        }),
      );
    }),
  );
};

const fetchReservationEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESERVATION),
    mergeMap(() => {
      return from(Service.request(ConciergeReservation.list)).pipe(
        map((response: any) => fetchReservationFulFilled(response)),
      );
    }),
  );
};

export default {
  updateReservationEpic,
  fetchReservationEpic,
};
