import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import SectionItemPageList from 'context/Section/Page/Item/List';
import SectionItemPageCreate from 'context/Section/Page/Item/Create';
import SectionItemPageEdit from 'context/Section/Page/Item/Edit';

import SectionCategoryPageList from 'context/Section/Page/Category/List';
import SectionCategoryPageCreate from 'context/Section/Page/Category/Create';
import SectionCategoryPageEdit from 'context/Section/Page/Category/Edit';

const routes: AppRouteDefinition[] = [
  {
    path: Route.LIST,
    Component: withMetadata(SectionItemPageList, {
      title: 'Section Item',
    }),
    guarded: true,
  },
  {
    path: Route.CREATE,
    Component: withMetadata(SectionItemPageCreate, {
      title: 'Create Section Item',
    }),
    guarded: true,
  },
  {
    path: Route.EDIT,
    Component: withMetadata(SectionItemPageEdit, {
      title: 'Edit Section Item',
    }),
    guarded: true,
  },

  // Section Categories
  {
    path: Route.CATEGORY_LIST,
    Component: withMetadata(SectionCategoryPageList, {
      title: 'Section Category',
    }),
    guarded: true,
  },
  {
    path: Route.CATEGORY_CREATE,
    Component: withMetadata(SectionCategoryPageCreate, {
      title: 'Create Section Category',
    }),
    guarded: true,
  },
  {
    path: Route.CATEGORY_EDIT,
    Component: withMetadata(SectionCategoryPageEdit, {
      title: 'Edit Section Category',
    }),
    guarded: true,
  },
];

export default routes;
