export enum ActionType {
  FETCH_TENANTS = 'FETCH_TENANTS',
  CHANGE_TENANT = 'CHANGE_TENANT',
}

export interface Tenant {
  id: string;
  avatar: string;
  text: string;
  subText: string;
}

export interface TenantState {
  tenants: Tenant[];
  currentTenant: Tenant | undefined;
}

export interface FetchAction {
  type: ActionType.FETCH_TENANTS;
  tenants: Tenant[];
}

export interface ChangeAction {
  type: ActionType.CHANGE_TENANT;
  tenant: Tenant;
}

export type TenantAction = FetchAction | ChangeAction;
