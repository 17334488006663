import React, { Component, ReactNode } from 'react';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as AdminRoute } from 'context/Courier/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import {
  TableHeadDefinition,
  TableRowDataDefinition,
  TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';

class AdminPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchCouriers();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Courier',
        route: formatRoute(AdminRoute.LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'avatar',
          content: 'Avatar',
          isSortable: false,
          width: 5,
        },
        {
          key: 'firstName',
          content: 'First Name',
          isSortable: false,
          width: 25,
        },
        {
          key: 'lastName',
          content: 'Last Name',
          isSortable: false,
          width: 25,
        },
        {
          key: 'email',
          content: 'Email',
          shouldTruncate: true,
          isSortable: false,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      avatar: ({ avatar }: TableRowDataDefinition & {
        avatar: string,
      }): ReactNode => (
        <AvatarItem avatar={<Avatar src={avatar} />} />
      ),
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Courier'
              head={head}
              data={this.props.couriers}
              dataTransformation={dataTransformation}
              showActions={true}
              creationRoute={AdminRoute.CREATE}
              modificationRoute={(id: string): string => formatRoute(AdminRoute.EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removeCourier(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ courier }: any) => {
  return {
    couriers: courier.couriers,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchCouriers: () => dispatch({ type: ActionType.FETCH_COURIERS }),
    removeCourier: (id: string) => {
      dispatch({ type: ActionType.REMOVE_COURIER, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPageList);
