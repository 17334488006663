export const ActionType = {
  FETCH_MAP_REQUESTS: 'FETCH_MAP_REQUESTS',
  FETCH_MAP_REQUESTS_FULFILLED: 'FETCH_MAP_REQUESTS_FULFILLED',

  FETCH_MAP_REQUEST: 'FETCH_MAP_REQUEST',
  FETCH_MAP_REQUEST_FULFILLED: 'FETCH_MAP_REQUEST_FULFILLED',

  UPDATE_MAP_REQUEST: 'UPDATE_MAP_REQUEST',

  MODIFY_REQUEST: 'MODIFY_REQUEST',
  SHOW_FORM: 'SHOW_FORM',
  MUTATE_FORM: 'MUTATE_FORM',
  ADD_ORDER: 'ADD_ORDER',
};
