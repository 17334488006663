import React, { Component } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';

export default class TabGift extends Component<any, any> {
  render() {
    const dataTransformation: TableRowDataTransformation = {
      price: ({ price, quantity }: any) => quantity * price + '€',
    };

    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'title',
              content: 'Product',
              isSortable: false,
              width: 25,
            },
            {
              key: 'quantity',
              content: 'Quantity',
              isSortable: false,
              width: 25,
            },
            {
              key: 'price',
              content: 'Total',
              isSortable: false,
              width: 25,
            },
          ],
        }}
        dataTransformation={dataTransformation}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={(this.props.request.data.gifts || []).map(
          (gift: any) => ({
            id: gift.item.id,
            title: gift.item.title,
            quantity: gift.quantity,
            price: Number(gift.item.price),
          }),
        )}
      />
    );
  }
}
