import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import { Redirect, withRouter } from 'react-router';
import FormFooter from 'context/Core/Component/Form/Footer';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';

class ServiceItemTypeForm extends Component<any, any> {
  static defaultProps = {
    name: '',
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={Route.ITEM_LIST} />;
    }

    return (
      <section>
        <ButtonBack path={Route.ITEM_LIST} titleBefore={'Service Item Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'service-item'} >

              <Field name={'name'} defaultValue={this.props.name} isRequired={true} label={'Item Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Item Name'} {...fieldProps} />}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ conciergeService: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_SERVICE_ITEM : ActionType.CREATE_SERVICE_ITEM;
      dispatch({ type, payload: { ...formData, id } });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceItemTypeForm));
