import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import RentingTypePageList from './../Page/Type/List';
import RentingTypePageCreate from './../Page/Type/Create';
import RentingTypePageEdit from './../Page/Type/Edit';

import RentingSubtypePageList from './../Page/Subtype/List';
import RentingSubtypePageCreate from './../Page/Subtype/Create';
import RentingSubtypePageEdit from './../Page/Subtype/Edit';

const routes: AppRouteDefinition[] = [
  {
    path: Route.TYPE_LIST,
    Component: withMetadata(RentingTypePageList, {
      title: 'Renting Type',
    }),
    guarded: true,
  },
  {
    path: Route.TYPE_CREATE,
    Component: withMetadata(RentingTypePageCreate, {
      title: 'Create Renting Type',
    }),
    guarded: true,
  },
  {
    path: Route.TYPE_EDIT,
    Component: withMetadata(RentingTypePageEdit, {
      title: 'Edit Renting Type',
    }),
    guarded: true,
  },

  // Sub Types
  {
    path: Route.SUBTYPE_LIST,
    Component: withMetadata(RentingSubtypePageList, {
      title: 'Renting Subtype',
    }),
    guarded: true,
  },
  {
    path: Route.SUBTYPE_CREATE,
    Component: withMetadata(RentingSubtypePageCreate, {
      title: 'Create Renting Subtype',
    }),
    guarded: true,
  },
  {
    path: Route.SUBTYPE_EDIT,
    Component: withMetadata(RentingSubtypePageEdit, {
      title: 'Edit Renting Subtype',
    }),
    guarded: true,
  },
];

export default routes;
