import { ActionType } from './types';

const initialState = {
  items: [],
  sectionCategories: [],
  currentSectionItem: undefined,
  currentSectionCategory: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_SECTION_ITEMS:
    case ActionType.FETCH_SECTION_ITEM:
    case ActionType.FETCH_SECTION_ITEM_FULFILLED:
      return { ...state, currentSectionItem: action.currentSectionItem };

    case ActionType.REMOVE_SECTION_ITEM:
      return { ...state };

    case ActionType.FETCH_SECTION_ITEMS_FULFILLED:
      const items = action.items || [];
      return { ...state, items };

    case ActionType.CREATE_SECTION_ITEM:
      return { ...state };

    // Section Categories
    case ActionType.FETCH_SECTION_CATEGORIES:
    case ActionType.FETCH_SECTION_CATEGORY:
    case ActionType.FETCH_SECTION_CATEGORY_FULFILLED:
      return { ...state, currentSectionCategory: action.currentSectionCategory };

    case ActionType.REMOVE_SECTION_CATEGORY:
      return { ...state };

    case ActionType.FETCH_SECTION_CATEGORIES_FULFILLED:
      const sectionCategories = action.sectionCategories || [];
      return { ...state, sectionCategories };

    case ActionType.CREATE_SECTION_CATEGORY:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    default:
      return state;
  }
};
