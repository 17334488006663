import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as SectionRoutes } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import FormImageInput from 'context/Core/Component/Form/ImageInput';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import TextArea from '@atlaskit/textarea';
import Select from '@atlaskit/select';
import { Redirect, withRouter } from 'react-router';

class SectionItemForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    description: '',
    image: '',
    url: '',
    items: [],
    type: '',
  };

  constructor(props: any) {
    super(props);

    this.state = { image: undefined };
    this.setImage = this.setImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  setImage(file: any) {
    this.setState({ ...this.state, image: file });
  }

  handleChange() {
    console.log(arguments);
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={SectionRoutes.LIST} />;
    }

    return (
      <section>
        <ButtonBack path={SectionRoutes.LIST} titleBefore={'Section Item Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.state.image, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'page'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <Field
                name={'type'}
                defaultValue={{ value: 'place', label: 'Place' }}
                isRequired={false}
                label={'Select Type'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <Select
                    className={'single-select'}
                    classNamePrefix={'react-select'}
                    options={[{ value: 'place', label: 'Place' }, { value: 'url', label: 'Link' }]}
                    placeholder={'Select type'}
                    onChange={this.handleChange}
                    {...fieldProps}
                  />
                )}
              </Field>

              <Field name={'url'} defaultValue={this.props.url} isRequired={true} label={'Url'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Url'} {...fieldProps} />}
              </Field>

              <Field name={'description'} defaultValue={this.props.description} isRequired={true} label={'Description'}>
                {({ fieldProps }: { fieldProps: any }) => <TextArea placeholder={'Description'} {...fieldProps} />}
              </Field>

              <FormImageInput
                form={'page'}
                fieldName={'image'}
                isLabelHidden
                fieldValue={this.props.image}
                value={this.props.image}
                fieldLabel={'Image'}
                imageSelected={this.setImage}
              />

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ section: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, image: any, id: any) => {
      formData.image = image;
      formData.type = formData.type.value;
      const form = new FormData();
      for (const key in formData) {
        if (!formData.hasOwnProperty(key)) {
          continue;
        }

        form.append(key, formData[key]);
      }

      const type = id ? ActionType.UPDATE_SECTION_ITEM : ActionType.CREATE_SECTION_ITEM;
      const payload = form;
      if (id) {
        payload.append('id', id);
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SectionItemForm));
