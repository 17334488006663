import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { ActionType } from '../Store/types';
import { connect } from 'react-redux';
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import moment from 'moment';

import AccommodationRequest from './RequestType/Accommodation';
import PersonalServiceRequest from './RequestType/PersonalService';
import RentingRequest from './RequestType/Renting';
import ReservationRequest from './RequestType/Reservation';
import TripPlanningRequest from './RequestType/TripPlanning';
import ContactRequest from './RequestType/Contact';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { Label } from '@atlaskit/field-base';
import TextArea from '@atlaskit/textarea';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Avatar from '@atlaskit/avatar';
import Comment, {
  CommentAuthor,
  CommentTime,
} from '@atlaskit/comment';

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${(props: any) =>
  props.isDragDisabled
    ? 'white'
    : props.isDragging
    ? 'white'
    : 'white'};
`;

const AnchorWrapper = styled.a`
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  box-sizing: border-box;
  min-height: 40px;
  margin-bottom: 8px;
  user-select: none;
  color: rgb(9, 30, 66);
  display: flex;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: transparent;
  padding: 8px;
  text-decoration: none !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

const ImageWrapper = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  border-radius: 50%;
`;

const DivWrapper = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  box-sizing: border-box;
  min-height: 40px;
  margin-bottom: -5px;
  user-select: none;
  color: rgb(9, 30, 66);
  display: flex;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: transparent;
  padding: 8px;
  text-decoration: none !important;
  padding-top: 0px;
`;

const BottomWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  -webkit-box-align: center;
  align-items: center;
`;

const SmallLeft = styled.small`
  color: rgba(9, 30, 66, 0.71);
  -webkit-box-flex: 0;
  flex-grow: 0;
  background-color: rgb(255, 250, 230);
  font-weight: normal;
  margin: 0px;
  border-radius: 2px;
  padding: 4px;
  margin-left: 5px;
`;

const requestTypeMap: any = {
  'accommodation': 'Accommodation Request',
  'personal-service': 'Personal Service Request',
  'renting': 'Renting Request',
  'reservation': 'Reservation Request',
  'trip-planning': 'Trip Planning Request',
  'contact': 'Contact Request',
};

class Task extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = { showModal: false, editorState: undefined };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidMount(): void {
    this.showModal = this.showModal.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
  }

  showModal() {
    this.setState({
      ...this.state,
      showModal: !this.state.showModal,
    });
  }

  formUpdate({ form, request, action, email }: any) {
    this.props.updateRequest({ ...request, form: { ...form, email }, action });
  }

  onEditorStateChange(editorState: any) {
    this.setState({
      editorState,
    });
  }

  render() {
    const isDragDisabled = false;
    const requestTypeComponents: any = (request: any): any => {
      const types: any = {
        'accommodation': <AccommodationRequest request={request} />,
        'personal-service': <PersonalServiceRequest request={request} />,
        'renting': <RentingRequest request={request} />,
        'reservation': <ReservationRequest request={request} />,
        'trip-planning': <TripPlanningRequest request={request} />,
        'contact': <ContactRequest request={request} />,
      };

      return types[request.type];
    };

    const statuses: any = [
      { label: 'Requested', value: 'requested' },
      { label: 'Needs More Info', value: 'needs-more-info' },
      { label: 'Partner Searching', value: 'partner-searching' },
      { label: 'Partner Found', value: 'partner-found' },
      { label: 'Offer Search', value: 'offer-search' },
      { label: 'Offer Sent', value: 'offer-sent' },
      { label: 'Offer Rejected', value: 'offer-rejected' },
      { label: 'Offer Approved', value: 'offer-approved' },
      { label: 'Resolved', value: 'resolved' },
    ];

    const footer = (props: any) => (
      <ModalFooter showKeyline={props.showKeyline} style={{ marginTop: 35 }}>
        <span />
        <div style={{ display: 'flex' }}>
          <Button appearance={'primary'} type={'submit'}>
            Submit
          </Button>
          <div style={{ marginLeft: 15 }}>
            <Button appearance={'danger'} onClick={() => this.showModal()}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalFooter>
    );

    return (
      <>
        <Draggable
          draggableId={this.props.request.id}
          index={this.props.index}
          isDragDisabled={isDragDisabled}
        >
          {(provided: any, snapshot: any) => (
            <Container
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              innerRef={provided.innerRef}
              isDragging={snapshot.isDragging}
              isDragDisabled={isDragDisabled}
            >
              <AnchorWrapper href={`/`} onClick={(e: any) => {
                e.preventDefault();
                this.props.fetchRequest(this.props.request.id);
                this.showModal();
              }}>
                <ImageWrapper
                  src={`https://eu.ui-avatars.com/api/?name=${this.props.request.type}&background=162a4d&color=fff`} />
                <DivWrapper>
                  <TextWrapper>
                    { requestTypeMap[this.props.request.type] }
                  </TextWrapper>
                  <BottomWrapper>
                    <SmallLeft>
                      {this.props.request.data.email}
                    </SmallLeft>
                  </BottomWrapper>
                </DivWrapper>
              </AnchorWrapper>
            </Container>
          )}
        </Draggable>
        {
          this.state.showModal && <ModalTransition>
            <ModalDialog
              heading={'Concierge Request'}
              width={1200}
              components={{
                Container: ({ children, className }: any) => (
                  <Form onSubmit={(data: any) => this.formUpdate({
                    form: data,
                    request: this.props.fetchedRequest,
                    action: 'update',
                    email: this.state.editorState !== undefined
                      ? JSON.stringify(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))
                      : '',
                  })}>
                    {({ formProps }: any) => (
                      <form {...formProps} className={className}>
                        {children}
                      </form>
                    )}
                  </Form>
                ),
                Footer: footer,
              }}
            >
              {
                requestTypeComponents(this.props.fetchedRequest)
              }
              {
                this.props.fetchedRequest.status !== 'resolved' &&
                <>
                  <Field name={'email'}
                         defaultValue={this.props.fetchedRequest.email}
                         isRequired={false} label={'Email'}>
                    {({ fieldProps }: { fieldProps: any }) =>
                      <Editor
                        editorState={this.state.editorState}
                        toolbarClassName={'toolbarClassName'}
                        wrapperClassName={'wrapperClassName'}
                        editorClassName={'editorClassName'}
                        onEditorStateChange={this.onEditorStateChange}
                        {...fieldProps}
                      />
                    }
                  </Field>
                </>
              }

              {
                this.props.fetchedRequest.status !== 'needs-more-info' &&
                  <>
                    <Field
                      name={'partner'}
                      defaultValue={
                        this.props.partners.filter(
                          (option: any) =>
                            this.props.fetchedRequest.partner &&
                            this.props.fetchedRequest.partner.includes(option.value),
                        )
                      }
                      isRequired={false}
                      label={'Select Partner'}
                    >
                      {({ fieldProps }: { fieldProps: any }) => (
                        <Select
                          className={'multi-select'}
                          classNamePrefix={'react-select'}
                          options={this.props.partners}
                          placeholder={'Choose a partner'}
                          {...fieldProps}
                        />
                      )}
                    </Field>

                    {
                      [
                        'offer-rejected',
                        'offer-approved',
                        'offer-rejected-by-system',
                        'resolved',
                      ].includes(this.props.fetchedRequest.status) &&
                      <>
                        <Field
                          name={'type'}
                          defaultValue={
                            statuses.filter(
                              (option: any) =>
                                this.props.fetchedRequest.status
                                && this.props.fetchedRequest.status.includes(option.value),
                            )
                          }
                          isRequired={false}
                          label={'Select Status'}
                        >
                          {({ fieldProps }: { fieldProps: any }) => (
                            <Select
                              className={'multi-select'}
                              classNamePrefix={'react-select'}
                              options={statuses}
                              placeholder={'Choose a status'}
                              {...fieldProps}
                            />
                          )}
                        </Field>

                        <Field name={'cost'}
                               defaultValue={this.props.fetchedRequest.cost} isRequired={false} label={'Cost'}>
                          {({ fieldProps }: { fieldProps: any }) =>
                            <TextField type={'number'} placeholder={'Cost'} {...fieldProps} />}
                        </Field>

                        <Field name={'income'} style={{ marginBottom: 20 }}
                               defaultValue={this.props.fetchedRequest.income} isRequired={false} label={'Income'}>
                          {({ fieldProps }: { fieldProps: any }) =>
                            <TextField type={'number'} placeholder={'Income'} {...fieldProps} />}
                        </Field>
                      </>
                    }
                  </>
              }

              {
                <Field name={'comment'}
                       defaultValue={''} isRequired={false} label={'Comment'}>
                  {({ fieldProps }: { fieldProps: any }) =>
                    <TextArea type={'comment'} placeholder={'Leave a comment as note'} {...fieldProps} />
                  }
                </Field>
              }
              {
                this.props.fetchedRequest && this.props.fetchedRequest.hasOwnProperty('comments') && <>
                  <Label label={'Comments'} />
                  {
                    this.props.fetchedRequest.comments.map((
                      { comment, user, createdAt }: { comment: string, user: any, createdAt: any },
                    ) => {
                      return <Comment
                        key={createdAt}
                        avatar={
                          <Avatar
                            src={user.avatar}
                            label={`${user.firstName} ${user.lastName}`}
                            size={'medium'}
                          />
                        }
                        author={<CommentAuthor>{user.firstName} {user.lastName}</CommentAuthor>}
                        type={'dispatcher'}
                        time={
                          <CommentTime>{moment(createdAt).fromNow()}</CommentTime>
                        }
                        content={comment}
                      />;
                    })
                  }
                </>
              }
            </ModalDialog>
          </ModalTransition>
        }
      </>
    );
  }
}

const mapStateToProps = ({ conciergeCenter: { request }, partner: { partnerItems } }: any) => {
  return {
    fetchedRequest: request,
    partners: partnerItems.map(({ id, name }: any) => {
      return { label: name, value: id };
    }),
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRequest: (id: any) => dispatch({ type: ActionType.FETCH_REQUEST, id }),
    updateRequest: (data: any) => dispatch({ type: ActionType.UPDATE_REQUEST, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
