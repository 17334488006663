import { ActionType } from './types';

const initialState = {
  items: [],
  drinkCategories: [],
  currentDrinkItem: undefined,
  currentDrinkCategory: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_DRINK_ITEMS:
    case ActionType.FETCH_DRINK_ITEM:
    case ActionType.FETCH_DRINK_ITEM_FULFILLED:
      return { ...state, currentDrinkItem: action.currentDrinkItem };

    case ActionType.REMOVE_DRINK_ITEM:
      return { ...state };

    case ActionType.FETCH_DRINK_ITEMS_FULFILLED:
      const items = action.items || [];
      return { ...state, items };

    case ActionType.CREATE_DRINK_ITEM:
      return { ...state };

    // Drink Categories
    case ActionType.FETCH_DRINK_CATEGORIES:
    case ActionType.FETCH_DRINK_CATEGORY:
    case ActionType.FETCH_DRINK_CATEGORY_FULFILLED:
      return { ...state, currentDrinkCategory: action.currentDrinkCategory };

    case ActionType.REMOVE_DRINK_CATEGORY:
      return { ...state };

    case ActionType.FETCH_DRINK_CATEGORIES_FULFILLED:
      const drinkCategories = action.drinkCategories || [];
      return { ...state, drinkCategories };

    case ActionType.CREATE_DRINK_CATEGORY:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    default:
      return state;
  }
};
