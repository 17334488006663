import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ContextProvider } from 'react-sortly';

import store from './store';
import Router from 'context/Core/Router';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// eslint-disable-next-line no-undef
ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
        <ContextProvider>
          <Router />
      </ContextProvider>
    </DndProvider>
  </Provider>,
  document.getElementById('app-root'),
);
