import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchDispatchers,
  redirectAfterCreation,
  fetchDispatcherFullFilled,
  fetchDispatchersFullFilled,
} from './actions';
import { Admin } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchDispatchersEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DISPATCHERS),
    mergeMap(() => {
      return from(Service.request(Admin.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchDispatchersFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createDispatcherEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_DISPATCHER),
    mergeMap(({ payload }: any) => {
      return from(Service.request(Admin.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Dispatcher successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeDispatcherEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_DISPATCHER),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Admin.remove, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchDispatchers()),
            of(showNotification('Dispatcher successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateDispatcherEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_DISPATCHER),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Admin.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchDispatchers()),
            of(showNotification('Dispatcher successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchDispatcherEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DISPATCHER),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Admin.find, { id })).pipe(
        map((response: any) => fetchDispatcherFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchDispatchersEpic,
  createDispatcherEpic,
  fetchDispatcherEpic,
  updateDispatcherEpic,
  removeDispatcherEpic,
};
