import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as DrinkRoutes } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import TextArea from '@atlaskit/textarea';
import Select from '@atlaskit/select';
import { Redirect, withRouter } from 'react-router';

class DrinkItemForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    description: '',
    price: '',
    category: '',
  };

  componentDidMount(): void {
    this.props.fetchDrinkCategories();
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={DrinkRoutes.LIST} />;
    }

    return (
      <section>
        <ButtonBack path={DrinkRoutes.LIST} titleBefore={'Drink Item Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'drink-item'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <Field name={'description'}
                     defaultValue={this.props.description} isRequired={false} label={'Description'}>
                {({ fieldProps }: { fieldProps: any }) => <TextArea placeholder={'Description'} {...fieldProps} />}
              </Field>

              <Field name={'price'} type={'number'} defaultValue={this.props.price} isRequired={true} label={'Price'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Price'} {...fieldProps} />}
              </Field>

              <Field
                name={'category'}
                defaultValue={
                  this.props.drinkCategories.filter(
                    (option: any) => this.props.category === option.value,
                  )
                }
                isRequired={false}
                label={'Select Category'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <Select
                    className={'single-select'}
                    classNamePrefix={'react-select'}
                    options={this.props.drinkCategories}
                    placeholder={'Select category'}
                    {...fieldProps}
                  />
                )}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ orderDrink: { shouldRedirect, drinkCategories } }: any) => {
  return {
    shouldRedirect,
    drinkCategories: drinkCategories.map(({ title, id }: any) => ({ label: title, value: id })),
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      formData.category = formData.category.value;
      const type = id ? ActionType.UPDATE_DRINK_ITEM : ActionType.CREATE_DRINK_ITEM;
      const payload = formData;
      if (id) {
        formData.id = id;
      }

      dispatch({ type, payload });
    },

    fetchDrinkCategories: () => dispatch({ type: ActionType.FETCH_DRINK_CATEGORIES }),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DrinkItemForm));
