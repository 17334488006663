import { ActionType } from './types';

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

// Items
export function fetchServiceItems() {
  return {
    type: ActionType.FETCH_SERVICE_ITEMS,
  };
}

export function fetchServiceItemsFulFilled(serviceItems: [] = []) {
  return {
    type: ActionType.FETCH_SERVICE_ITEMS_FULFILLED,
    serviceItems,
  };
}

export function fetchServiceItemFulFilled(currentServiceItem: object) {
  return {
    type: ActionType.FETCH_SERVICE_ITEM_FULFILLED,
    currentServiceItem,
  };
}

export function fetchServiceItem(id: string) {
  return {
    type: ActionType.FETCH_SERVICE_ITEM,
    id,
  };
}

export function createServiceItem(data: object) {
  return {
    type: ActionType.CREATE_SERVICE_ITEM,
    data,
  };
}

export function updateServiceItem(id: string, data: object) {
  return {
    type: ActionType.UPDATE_SERVICE_ITEM,
    id,
    data,
  };
}

export function removeServiceItem(id: string) {
  return {
    type: ActionType.REMOVE_SERVICE_ITEM,
    id,
  };
}
