import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ModuleTable from '../../../../Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from '../../../../Core/Component/Layout/Module/Table/types';

export const H4 = styled.h4`
  display: inline-block;
  margin-top: 5px;
`;

class AccommodationRequest extends React.Component<any, any> {
  render() {
    const {
      name,
      phonePrefix,
      email,
      phoneNumber,
      checkInDate,
      checkOutDate,
      budget,
      numberOfPeople,
      numberOfBedrooms,
      notes,
    } = this.props.request.data;

    const dataTransformation: TableRowDataTransformation = {
      checkInDate: ({ checkInDate }: any) => moment(checkInDate).format('D MMMM YYYY'),
      checkOutDate: ({ checkOutDate }: any) => moment(checkOutDate).format('D MMMM YYYY'),
      phoneNumber: ({ phonePrefix, phoneNumber }: any) => phonePrefix + phoneNumber,
    };

    return (
      <>
        <ModuleTable
          head={{
            cells: [
              {
                key: 'id',
                content: '#',
                isSortable: true,
                width: 5,
              },
              {
                key: 'name',
                content: 'Name',
                isSortable: false,
                width: 25,
              },
              {
                key: 'phoneNumber',
                content: 'Phone',
                isSortable: false,
                width: 25,
              },
              {
                key: 'email',
                content: 'Email',
                isSortable: false,
                width: 30,
              },
              {
                key: 'budget',
                content: 'Budget',
                isSortable: false,
                width: 25,
              },
              {
                key: 'numberOfPeople',
                content: 'Number Of People',
                isSortable: false,
                width: 30,
              },
              {
                key: 'numberOfBedrooms',
                content: 'Number Of Bedrooms',
                isSortable: false,
                width: 30,
              },
              {
                key: 'checkInDate',
                content: 'Check In Date',
                isSortable: false,
                width: 30,
              },
              {
                key: 'checkOutDate',
                content: 'Check Out Date',
                isSortable: false,
                width: 30,
              },
              {
                key: 'notes',
                content: 'Notes',
                isSortable: false,
                width: 30,
              },
            ],
          }}
          dataTransformation={dataTransformation}
          showActions={false}
          showTableTop={[]}
          rowsPerPage={[]}
          data={[{
            id: this.props.request.id,
            name,
            phonePrefix,
            email,
            phoneNumber,
            checkInDate,
            checkOutDate,
            budget,
            numberOfPeople,
            numberOfBedrooms,
            notes,
          }]}
        />
      </>
    );
  }
}

export default AccommodationRequest;
