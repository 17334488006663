import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import ServiceItemPageList from './../Page/Item/List';
import ServiceItemPageCreate from './../Page/Item/Create';
import ServiceItemPageEdit from './../Page/Item/Edit';

const routes: AppRouteDefinition[] = [
  // Items
  {
    path: Route.ITEM_LIST,
    Component: withMetadata(ServiceItemPageList, {
      title: 'Service Item',
    }),
    guarded: true,
  },
  {
    path: Route.ITEM_CREATE,
    Component: withMetadata(ServiceItemPageCreate, {
      title: 'Create Service Item',
    }),
    guarded: true,
  },
  {
    path: Route.ITEM_EDIT,
    Component: withMetadata(ServiceItemPageEdit, {
      title: 'Edit Service Item',
    }),
    guarded: true,
  },
];

export default routes;
