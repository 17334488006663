import React, { Component } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';

export default class MarketTabProducts extends Component<any, any> {
  render() {
    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'label',
              content: 'Product',
              isSortable: false,
              width: 25,
            },
            {
              key: 'quantity',
              content: 'Quantity',
              isSortable: false,
              width: 25,
            },
          ],
        }}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={(this.props.request.data.products || []).map(
          (item: any) => ({
            id: this.props.request.id,
            label: item.label,
            quantity: item.quantity,
          }),
        )}
      />
    );
  }
}
