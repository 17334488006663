import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ContainerHeader,
  ItemAvatar,
  Switcher,
} from '@atlaskit/navigation-next';
import ChevD from '@atlaskit/icon/glyph/chevron-down';
import { GlobalState } from 'store/types';
import { Tenant, TenantState } from 'context/Tenant/Store/types';
import { changeTenant, fetchTenants } from 'context/Tenant/Store/actions';
import { TenantSwitcherProps, TenantSwitcherState } from './types';

class TenantSwitcher extends Component<TenantSwitcherProps, TenantSwitcherState> {
  state = {
    options: [],
  };

  create = () => ({
    onClick: () => {
      // this should open a modal.
    },
    text: 'Create Tenant',
  })

  target = (tenant: Tenant = {
    id: 'none',
    avatar: 'endeavour',
    text: 'No tenant selected',
    subText: 'Select a tenant',
  }) => {
    let isSelected = this.props.currentTenant
      && this.props.currentTenant.id === tenant.id;

    return (
      <ContainerHeader
        before={(itemState: any) => (
          <ItemAvatar
            appearance='square'
            href={null}
            isInteractive={false}
            itemState={itemState}
          />
        )}
        after={ChevD}
        id={tenant.id}
        isSelected={isSelected}
        subText={tenant.subText}
        text={tenant.text}
      />
    );
  }

  onChange = (tenant: Tenant) => {
    if (this.props.changeTenant) {
      this.props.changeTenant(tenant);
    }
  }

  static getDerivedStateFromProps(
    props: TenantSwitcherProps,
  ): TenantSwitcherState {
    return {
      options: [
        {
          label: 'All Tenants',
          options: props.tenants,
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.tenants.length) {
      return;
    }

    // all of these should be moved to somewhere else.
    const fakeTenants: Tenant[] = [
      {
        id: 'mykonos',
        avatar: 'endeavour',
        text: 'Mykonos',
        subText: 'Greece',
      },
      {
        id: 'ibiza',
        avatar: 'endeavour',
        text: 'Ibiza',
        subText: 'Spain',
      },
      {
        id: 'tulum',
        avatar: 'endeavour',
        text: 'Tulum',
        subText: 'Mexico',
      },
    ];

    if (this.props.fetchTenants) {
      this.props.fetchTenants(fakeTenants);
    }

    setTimeout(() => {
      if (this.props.changeTenant) {
        this.props.changeTenant(fakeTenants[0]);
      }
    }, 1000);
  }

  render() {
    return (
      <Switcher
        create={this.create()}
        onChange={this.onChange}
        options={this.state.options}
        target={this.target(this.props.currentTenant)}
        value={this.props.currentTenant}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): TenantState => {
  const { tenants, currentTenant } = state.tenant;
  return {
    tenants,
    currentTenant,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchTenants: (tenants: Tenant[]) => dispatch(fetchTenants(tenants)),
    changeTenant: (tenant: Tenant) => dispatch(changeTenant(tenant)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantSwitcher);
