export const ActionType = {
  FETCH_SECTION_ITEMS: 'FETCH_SECTION_ITEMS',
  FETCH_SECTION_ITEMS_FULFILLED: 'FETCH_SECTION_ITEMS_FULFILLED',
  FETCH_SECTION_ITEM_FULFILLED: 'FETCH_SECTION_ITEM_FULFILLED',
  FETCH_SECTION_ITEM: 'FETCH_SECTION_ITEM',
  CREATE_SECTION_ITEM: 'CREATE_SECTION_ITEM',
  UPDATE_SECTION_ITEM: 'UPDATE_SECTION_ITEM',
  REMOVE_SECTION_ITEM: 'REMOVE_SECTION_ITEM',

  FETCH_SECTION_CATEGORIES: 'FETCH_SECTION_CATEGORIES',
  FETCH_SECTION_CATEGORIES_FULFILLED: 'FETCH_SECTION_CATEGORIES_FULFILLED',
  FETCH_SECTION_CATEGORY_FULFILLED: 'FETCH_SECTION_CATEGORY_FULFILLED',
  FETCH_SECTION_CATEGORY: 'FETCH_SECTION_CATEGORY',
  CREATE_SECTION_CATEGORY: 'CREATE_SECTION_CATEGORY',
  UPDATE_SECTION_CATEGORY: 'UPDATE_SECTION_CATEGORY',
  REMOVE_SECTION_CATEGORY: 'REMOVE_SECTION_CATEGORY',

  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
