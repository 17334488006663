import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import Root from './../Page/Root';

const routes: AppRouteDefinition[] = [
  {
    path: Route.TRIP_PLANNING_ROOT,
    Component: withMetadata(Root, {
      title: 'Trip Planning Builder',
    }),
    guarded: true,
  },
];

export default routes;
