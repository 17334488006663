import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as PageRoutes } from '../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import FormImageInput from 'context/Core/Component/Form/ImageInput';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import TextArea from '@atlaskit/textarea';
import Toggle from '@atlaskit/toggle';
import { Redirect, withRouter } from 'react-router';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class PageForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    description: '',
    content: undefined,
    image: '',
    published: false,
    showInNavigation: false,
    showInFooter: false,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      image: undefined,
      editorState: undefined,
    };

    this.setImage = this.setImage.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  setImage(file: any) {
    this.setState({ ...this.state, image: file });
  }

  componentWillReceiveProps(nextProps: any) {
    if (typeof nextProps.content === 'string') {
      this.onEditorStateChange(
        EditorState.createWithContent(convertFromRaw(JSON.parse(nextProps.content))),
      );
    }
  }

  onEditorStateChange(editorState: any) {
    this.setState({
      editorState,
    });
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={PageRoutes.LIST} />;
    }

    return (
      <section>
        <ButtonBack path={PageRoutes.LIST} titleBefore={'Page Form'} />
        <Form
          onSubmit={
            (data: { data: object }) => this.props.submit(
              data,
              JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
              this.state.image,
              this.props.match.params.id,
            )
          }>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'page'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <Field name={'description'} defaultValue={this.props.description} isRequired={true} label={'Description'}>
                {({ fieldProps }: { fieldProps: any }) => <TextArea placeholder={'Description'} {...fieldProps} />}
              </Field>

              <Field name={'content'}
                     isRequired={false}
                     label={'Content'}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName={'toolbarClassName'}
                    wrapperClassName={'wrapperClassName'}
                    editorClassName={'editorClassName'}
                    onEditorStateChange={this.onEditorStateChange}
                    {...fieldProps}
                  />
                }
              </Field>

              <FormImageInput
                form={'page'}
                fieldName={'image'}
                isLabelHidden
                fieldValue={this.props.image}
                value={this.props.image}
                fieldLabel={'Image'}
                imageSelected={this.setImage}
              />

              <Field
                name={'published'}
                isRequired={true}
                label={'Published'}
                defaultValue={this.props.showInFooter}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Toggle isDefaultChecked={this.props.published} size={'large'} { ...fieldProps } /> }
              </Field>

              <Field
                name={'showInNavigation'}
                isRequired={true}
                label={'Show In Navigation'}
                defaultValue={this.props.showInFooter}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Toggle isDefaultChecked={this.props.showInNavigation} size={'large'} { ...fieldProps } /> }
              </Field>

              <Field
                name={'showInFooter'}
                isRequired={true}
                label={'Show In Footer'}
                defaultValue={this.props.showInFooter}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Toggle isDefaultChecked={this.props.showInFooter} size={'large'} { ...fieldProps } /> }
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ page: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, content: any, image: any, id: any) => {
      formData.image = image;
      formData.content = content;
      const form = new FormData();
      for (const key in formData) {
        if (!formData.hasOwnProperty(key)) {
          continue;
        }

        form.append(key, formData[key]);
      }

      const type = id ? ActionType.UPDATE_PAGE : ActionType.CREATE_PAGE;
      const payload = form;
      if (id) {
        payload.append('id', id);
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageForm));
