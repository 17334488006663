import { ActionType } from './types';

const initialState = {
  settings: {
    fromState: true,
    siteTitle: '',
    siteDescription: '',
    siteLogo: '',
    siteCurrency: '',
    amountPerExtraStop: 0,
    deliveryFee: 0,
    surchargePercent: 0,
    surchargeAfter: 0,
    zones: {
      1: {
        chargeAmount: 15,
        discountReason: '',
        discountAmount: '',
      },

      2: {
        chargeAmount: 50,
        discountReason: '',
        discountAmount: '',
      },

      3: {
        chargeAmount: 100,
        discountReason: '',
        discountAmount: '',
      },
    },
    zoneDefault: 0,
    zoneTimeIncrease: {
      1: {
        from: 0,
        to: 0,
        timeType: 'am',
        amount: 0,
      },

      2: {
        from: 0,
        to: 0,
        timeType: 'am',
        amount: 0,
      },

      3: {
        from: 0,
        to: 0,
        timeType: 'am',
        amount: 0,
      },
    },
    timePriceIncrease: {
      regular: {
        from: 0,
        to: 0,
        timeType: 'am',
        percent: 0,
        enabled: false,
      },

      happyHour: {
        from: 0,
        to: 0,
        timeType: 'am',
        percent: 0,
        enabled: false,
      },
    },
    marketShop: [],
  },
  data: {},
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_SETTINGS:
      return { ...state, settings: initialState.settings };
    case ActionType.FETCH_SETTINGS_FULFILLED:
      return { ...state, settings: action.settings };

    case ActionType.UPDATE_SETTINGS:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
