import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { APP_NAME } from 'config';

export default (Component, { title }) => {
  return (props) => {
    return (
      <Fragment>
        <Helmet>
          <title>{
            title
              ? `${title} | ${APP_NAME}`
              : APP_NAME
          }</title>
        </Helmet>
        <Component {...props} />
      </Fragment>
    );
  };
};
