import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { ServiceInterface } from '../../../../util/Service';
import { ActionType } from './types';
import { flatMap, mergeMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { fetchRequestFulFilled, fetchRequests, fetchRequestsFulFilled } from './actions';
import { ConciergeCenter, ConciergeRequestCenter } from 'medium/api';

const fetchConciergeRequests = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_REQUESTS),
    mergeMap(({ payload }) => {
      return from(Service.request(ConciergeRequestCenter.fetchRequests, {}, payload)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchRequestsFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const fetchConciergeRequest = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_REQUEST),
    mergeMap(({ id }) => {
      return from(Service.request(ConciergeCenter.find, { id })).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchRequestFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const updateRequest = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_REQUEST),
    mergeMap(({ data }: any) => {
      return from(Service.request(ConciergeCenter.update, {}, data)).pipe(
        flatMap(() => {
            return concat(
              of(fetchRequests(state$.value.conciergeCenter.form)),
            );
          },
        ),
      );
    }),
  );
};

export default {
  fetchConciergeRequests,
  fetchConciergeRequest,
  updateRequest,
};
