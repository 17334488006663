import { ActionType } from './types';

export function fetchRentingTypes() {
  return {
    type: ActionType.FETCH_RENTING_TYPES,
  };
}

export function fetchRentingTypesFulFilled(rentingTypes: [] = []) {
  return {
    type: ActionType.FETCH_RENTING_TYPES_FULFILLED,
    rentingTypes,
  };
}

export function fetchRentingTypeFulFilled(rentingType: object) {
  return {
    type: ActionType.FETCH_RENTING_TYPE_FULFILLED,
    rentingType,
  };
}

export function fetchRentingType(id: string) {
  return {
    type: ActionType.FETCH_RENTING_TYPE,
    id,
  };
}

export function createRentingType(data: object) {
  return {
    type: ActionType.CREATE_RENTING_TYPE,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

export function updateRentingType(id: string, data: object) {
  return {
    type: ActionType.UPDATE_RENTING_TYPE,
    id,
    data,
  };
}

export function removeRentingType(id: string) {
  return {
    type: ActionType.REMOVE_RENTING_TYPE,
    id,
  };
}

// Sub Types
export function fetchRentingSubtypes() {
  return {
    type: ActionType.FETCH_RENTING_SUBTYPES,
  };
}

export function fetchRentingSubtypesFulFilled(rentingSubtypes: [] = []) {
  return {
    type: ActionType.FETCH_RENTING_SUBTYPES_FULFILLED,
    rentingSubtypes,
  };
}

export function fetchRentingSubtypeFulFilled(rentingSubType: object) {
  return {
    type: ActionType.FETCH_RENTING_SUBTYPE_FULFILLED,
    rentingSubType,
  };
}

export function fetchRentingSubtype(id: string) {
  return {
    type: ActionType.FETCH_RENTING_SUBTYPE,
    id,
  };
}

export function createRentingSubtype(data: object) {
  return {
    type: ActionType.CREATE_RENTING_SUBTYPE,
    data,
  };
}

export function updateRentingSubtype(id: string, data: object) {
  return {
    type: ActionType.UPDATE_RENTING_SUBTYPE,
    id,
    data,
  };
}

export function removeRentingSubtype(id: string) {
  return {
    type: ActionType.REMOVE_RENTING_SUBTYPE,
    id,
  };
}
