import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as RestaurantRoute } from 'context/Order/Food/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import { TableHeadDefinition } from 'context/Core/Component/Layout/Module/Table/types';
import { withRouter } from 'react-router';
import { ActionType } from '../../Store/types';

class RestaurantMenuPage extends Component<any, any> {
  componentDidMount() {
    this.props.fetchRestaurantTagProducts(
      this.props.match.params.id,
      this.props.match.params.tagId,
    );
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Restaurant',
        route: formatRoute(RestaurantRoute.LIST),
      },
      {
        title: 'Restaurant Category',
        route: formatRoute(RestaurantRoute.TAG_LIST, { id: this.props.match.params.id }),
      },
      {
        title: 'Restaurant Category Product',
        route: formatRoute(RestaurantRoute.TAG_PRODUCT_LIST, {
          id: this.props.match.params.id,
          tagId: this.props.match.params.tagId,
        }),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          width: 25,
        },
        {
          key: 'description',
          content: 'Description',
          width: 25,
        },
        {
          key: 'price',
          content: 'Price',
          width: 25,
        },
      ],
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Restaurant Category Products'
              head={head}
              data={this.props.restaurantTagProducts}
              showActions={true}
              isRankable
              creationRoute={formatRoute(RestaurantRoute.TAG_PRODUCT_CREATE, {
                id: this.props.match.params.id,
                tagId: this.props.match.params.tagId,
              })}
              modificationRoute={
                (id: string): string => formatRoute(
                  RestaurantRoute.TAG_PRODUCT_EDIT, {
                    id: this.props.match.params.id,
                    tagId: this.props.match.params.tagId,
                    productId: id,
                  },
                )
              }
              onDeleteConfirmed={
                (id: string): any => this.props.removeRestaurantTagProduct(
                  id,
                  this.props.match.params.id,
                  this.props.match.params.tagId,
                )
              }
            />
          }
        </section>
      </LayoutWrapper>

    );
  }
}

const mapStateToProps = ({ orderFood: { restaurantTagProducts } }: any) => {
  return {
    restaurantTagProducts,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRestaurantTagProducts: (restaurantId: string, tagId: string) => dispatch({
      type: ActionType.FETCH_TAG_PRODUCTS,
      restaurantId,
      tagId,
    }),
    removeRestaurantTagProduct: (id: string, restaurantId: string, tagId: string) => {
      dispatch({ type: ActionType.REMOVE_TAG_PRODUCT, id, restaurantId, tagId });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RestaurantMenuPage));
