export const ActionType = {
  FETCH_PAGES: 'FETCH_PAGES',
  FETCH_PAGES_FULFILLED: 'FETCH_PAGES_FULFILLED',
  FETCH_PAGE_FULFILLED: 'FETCH_PAGE_FULFILLED',
  FETCH_PAGE: 'FETCH_PAGE',
  CREATE_PAGE: 'CREATE_PAGE',
  UPDATE_PAGE: 'UPDATE_PAGE',
  REMOVE_PAGE: 'REMOVE_PAGE',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
