import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import PartnerTypePageList from './../Page/Type/List';
import PartnerTypePageCreate from './../Page/Type/Create';
import PartnerTypePageEdit from './../Page/Type/Edit';

import PartnerItemPageList from './../Page/Item/List';
import PartnerItemPageCreate from './../Page/Item/Create';
import PartnerItemPageEdit from './../Page/Item/Edit';

const routes: AppRouteDefinition[] = [
  {
    path: Route.TYPE_LIST,
    Component: withMetadata(PartnerTypePageList, {
      title: 'Partner Type',
    }),
    guarded: true,
  },
  {
    path: Route.TYPE_CREATE,
    Component: withMetadata(PartnerTypePageCreate, {
      title: 'Create Partner Type',
    }),
    guarded: true,
  },
  {
    path: Route.TYPE_EDIT,
    Component: withMetadata(PartnerTypePageEdit, {
      title: 'Edit Partner Type',
    }),
    guarded: true,
  },

  // Partner Items
  {
    path: Route.PARTNER_LIST,
    Component: withMetadata(PartnerItemPageList, {
      title: 'Partner Item',
    }),
    guarded: true,
  },
  {
    path: Route.PARTNER_CREATE,
    Component: withMetadata(PartnerItemPageCreate, {
      title: 'Create Partner Item',
    }),
    guarded: true,
  },
  {
    path: Route.PARTNER_EDIT,
    Component: withMetadata(PartnerItemPageEdit, {
      title: 'Edit Partner Item',
    }),
    guarded: true,
  },
];

export default routes;
