import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as CategoryRoutes } from '../../Router/types';
import Form from '../../Component/Category/Form';
import { ActionType } from '../../Store/types';
import { withRouter } from 'react-router';

class DrinkCategoryPageEdit extends Component<any, any> {
  componentDidMount(): void {
    this.props.fetchDrinkCategory(this.props.match.params.id);
  }

  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Drink Category',
              route: formatRoute(CategoryRoutes.CATEGORY_LIST),
            },
            {
              title: 'Edit',
              route: formatRoute(CategoryRoutes.CATEGORY_EDIT),
            },
          ]
        }
        >
        </LayoutTitle>
        <Form {...this.props.currentDrinkCategory} />
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ orderDrink: { currentDrinkCategory } }: any) => {
  return {
    currentDrinkCategory,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchDrinkCategory: (id: string) => dispatch({ type: ActionType.FETCH_DRINK_CATEGORY, ...{ id } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DrinkCategoryPageEdit));
