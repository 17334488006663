import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as SectionRoutes } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import Select from '@atlaskit/select';
import Toggle from '@atlaskit/toggle';
import { Redirect, withRouter } from 'react-router';

class SectionCategoryForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    description: '',
    showOnConcierge: false,
    showOnOrder: false,
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={SectionRoutes.CATEGORY_LIST} />;
    }

    return (
      <section>
        <ButtonBack path={SectionRoutes.CATEGORY_LIST} titleBefore={'Section Category Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'page'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <Field
                name={'items'}
                defaultValue={null}
                isRequired={false}
                label={'Select Items'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <Select
                    isMulti
                    className={'single-select'}
                    classNamePrefix={'react-select'}
                    options={[]}
                    placeholder={'Select section items'}
                    {...fieldProps}
                  />
                )}
              </Field>

              <Field
                name={'showOnConcierge'}
                isRequired={true}
                label={'Show On Concierge'}
                defaultValue={this.props.showOnConcierge}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Toggle isDefaultChecked={this.props.showOnConcierge} size={'large'} { ...fieldProps } /> }
              </Field>

              <Field
                name={'showOnOrder'}
                isRequired={true}
                label={'Show On Order'}
                defaultValue={this.props.showOnOrder}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Toggle isDefaultChecked={this.props.showOnOrder} size={'large'} { ...fieldProps } /> }
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ section: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_SECTION_CATEGORY : ActionType.CREATE_SECTION_CATEGORY;
      const payload = formData;
      if (id) {
        payload.id = id;
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SectionCategoryForm));
