import React from 'react';
import { connect } from 'react-redux';
import '@atlaskit/css-reset';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';

import Column from './Column';
import { ActionType } from '../Store/types';
import { ActionType as PartnerActionType } from '../../../Partner/Store/types';

const Container = styled.div`
  display:flex;
  margin-left: -5px;
`;

class Center extends React.Component<any, any> {
  state: any = {
    columns: {
      'requested': {
        id: 'requested',
        title: 'Requested',
        taskIds: [],
      },
      'needs-more-info': {
        id: 'needs-more-info',
        title: 'Needs More Info',
        taskIds: [],
      },
      'partner-searching': {
        id: 'partner-searching',
        title: 'Partner Search',
        taskIds: [],
      },
      'offer-sent': {
        id: 'offer-sent',
        title: 'Send Offer',
        taskIds: [],
      },
      resolved: {
        id: 'resolved',
        title: 'Outcome',
        taskIds: [],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ['requested', 'needs-more-info', 'partner-searching', 'offer-sent', 'resolved'],
  };

  componentDidMount(): void {
    for (let k in this.state.columns) {
      if (!this.state.columns.hasOwnProperty(k)) {
        continue;
      }

      const item = this.state.columns[k];
      item.taskIds = this.props.requests.filter((request: any) => {
        return request.status === item.id;
      });
    }

    this.props.fetchPartnerItems();
  }

  onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    this.props.updateRequest({ id: draggableId, status: destination.droppableId, action: 'statusChange' });

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      // tslint:disable-next-line:no-shadowed-variable
      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    this.setState(newState);
  }

  render() {
    if (!this.props.requests.length) {
      return <></>;
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Container>
          {this.state.columnOrder.map((columnId: any) => {
            const column = this.state.columns[columnId];
            const requests = this.props.requests.filter((item: any) => {
              if (column.id === 'partner-searching' && ['partner-found', 'partner-searching'].includes(item.status)) {
                return item;
              }

              if (column.id === 'offer-sent' && ['offer-search',
                'offer-sent',
              ].includes(item.status)) {
                return item;
              }

              if (
                column.id === 'resolved' &&
                ['offer-rejected', 'offer-approved', 'offer-rejected-by-system', 'resolved'].includes(item.status)
              ) {
                return item;
              }

              return item.status === column.id;
            });

            return (
              <Column key={column.id} column={column} requests={requests} />
            );
          })}
        </Container>
      </DragDropContext>
    );
  }
}

const mapDispatchToProps = (dispatch: any): object => {
  return {
    updateRequest: (data: any) => dispatch({ type: ActionType.UPDATE_REQUEST, data }),
    fetchPartnerItems: () => dispatch({ type: PartnerActionType.FETCH_PARTNER_ITEMS }),
  };
};

export default connect(null, mapDispatchToProps)(Center);
