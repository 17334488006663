export const ActionType = {
  FETCH_RESTAURANT_ITEMS: 'FETCH_RESTAURANT_ITEMS',
  FETCH_RESTAURANT_ITEMS_FULFILLED: 'FETCH_RESTAURANT_ITEMS_FULFILLED',
  FETCH_RESTAURANT_ITEM_FULFILLED: 'FETCH_RESTAURANT_ITEM_FULFILLED',
  FETCH_RESTAURANT_ITEM: 'FETCH_RESTAURANT_ITEM',
  CREATE_RESTAURANT_ITEM: 'CREATE_RESTAURANT_ITEM',
  UPDATE_RESTAURANT_ITEM: 'UPDATE_RESTAURANT_ITEM',
  REMOVE_RESTAURANT_ITEM: 'REMOVE_RESTAURANT_ITEM',

  FETCH_RESTAURANT_CATEGORIES: 'FETCH_RESTAURANT_CATEGORIES',
  FETCH_RESTAURANT_CATEGORIES_FULFILLED: 'FETCH_RESTAURANT_CATEGORIES_FULFILLED',
  FETCH_RESTAURANT_CATEGORY_FULFILLED: 'FETCH_RESTAURANT_CATEGORY_FULFILLED',
  FETCH_RESTAURANT_CATEGORY: 'FETCH_RESTAURANT_CATEGORY',
  CREATE_RESTAURANT_CATEGORY: 'CREATE_RESTAURANT_CATEGORY',
  UPDATE_RESTAURANT_CATEGORY: 'UPDATE_RESTAURANT_CATEGORY',
  REMOVE_RESTAURANT_CATEGORY: 'REMOVE_RESTAURANT_CATEGORY',

  FETCH_RESTAURANT_TAGS: 'FETCH_RESTAURANT_TAGS',
  FETCH_RESTAURANT_TAGS_FULFILLED: 'FETCH_RESTAURANT_TAGS_FULFILLED',
  FETCH_RESTAURANT_TAG_FULFILLED: 'FETCH_RESTAURANT_TAG_FULFILLED',
  FETCH_RESTAURANT_TAG: 'FETCH_RESTAURANT_TAG',
  CREATE_RESTAURANT_TAG: 'CREATE_RESTAURANT_TAG',
  UPDATE_RESTAURANT_TAG: 'UPDATE_RESTAURANT_TAG',
  REMOVE_RESTAURANT_TAG: 'REMOVE_RESTAURANT_TAG',

  FETCH_TAG_PRODUCTS: 'FETCH_TAG_PRODUCTS',
  FETCH_TAG_PRODUCTS_FULFILLED: 'FETCH_TAG_PRODUCTS_FULFILLED',
  FETCH_TAG_PRODUCT_FULFILLED: 'FETCH_TAG_PRODUCT_FULFILLED',
  FETCH_TAG_PRODUCT: 'FETCH_TAG_PRODUCT',
  CREATE_TAG_PRODUCT: 'CREATE_TAG_PRODUCT',
  UPDATE_TAG_PRODUCT: 'UPDATE_TAG_PRODUCT',
  REMOVE_TAG_PRODUCT: 'REMOVE_TAG_PRODUCT',

  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
