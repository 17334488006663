import { ActionType } from './types';

export function fetchSettings() {
  return {
    type: ActionType.FETCH_SETTINGS,
  };
}

export function fetchSettingsFulFilled(settings: object) {
  return {
    type: ActionType.FETCH_SETTINGS_FULFILLED,
    settings,
  };
}

export function updateSettings(data: object) {
  return {
    type: ActionType.FETCH_SETTINGS,
    data,
  };
}
