import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as PartnerRoute } from 'context/Partner/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import {
  TableHeadDefinition, TableRowDataDefinition, TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';

class PartnerItemPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchPartnerItems();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Partner Item',
        route: formatRoute(PartnerRoute.PARTNER_LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'name',
          content: 'Partner Name',
          isSortable: false,
          width: 20,
        },
        {
          key: 'email',
          content: 'Partner Email',
          isSortable: false,
          width: 20,
        },
        {
          key: 'phone',
          content: 'Partner Phone',
          isSortable: false,
          width: 20,
        },
        {
          key: 'type',
          content: 'Belongs To Type/s',
          isSortable: false,
          width: 20,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      type: ({ type }: TableRowDataDefinition & { type: any }): any =>
        (type.map(({ name }: { name: string }) => name)).join(', '),
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Partner Items'
              head={head}
              data={this.props.partnerItems}
              showActions={true}
              dataTransformation={dataTransformation}
              creationRoute={PartnerRoute.PARTNER_CREATE}
              modificationRoute={(id: string): string => formatRoute(PartnerRoute.PARTNER_EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removePartnerItem(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ partner: { partnerItems } }: any) => {
  return {
    partnerItems,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchPartnerItems: () => dispatch({ type: ActionType.FETCH_PARTNER_ITEMS }),
    removePartnerItem: (id: string) => {
      dispatch({ type: ActionType.REMOVE_PARTNER_ITEM, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerItemPageList);
