import cloneDeep from 'lodash.clonedeep';
import { TenantState, TenantAction, ActionType } from './types';

const initialState: TenantState = {
  tenants: [],
  currentTenant: undefined,
};

export default (
  state: TenantState = initialState,
  action: TenantAction,
): TenantState => {
  switch (action.type) {
    case ActionType.FETCH_TENANTS:
      const tenants = [ ...action.tenants ];
      return {
        tenants,
        currentTenant: tenants[0],
      };

    case ActionType.CHANGE_TENANT:
      return {
        ...state,
        currentTenant: cloneDeep(action.tenant),
      };

    default:
      return state;
  }
};
