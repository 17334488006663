import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import List from 'context/Order/Gift/Page/List';
import Create from 'context/Order/Gift/Page/Create';
import Edit from 'context/Order/Gift/Page/Edit';

const routes: AppRouteDefinition[] = [
  {
    path: Route.LIST,
    Component: withMetadata(List, {
      title: 'Gift',
    }),
    guarded: true,
  },
  {
    path: Route.CREATE,
    Component: withMetadata(Create, {
      title: 'Create Gift',
    }),
    guarded: true,
  },
  {
    path: Route.EDIT,
    Component: withMetadata(Edit, {
      title: 'Edit Gift',
    }),
    guarded: true,
  },
];

export default routes;
