import { ActionType } from './types';

const initialState = {
  partnerTypes: [],
  partnerItems: [],
  currentPartnerItem: undefined,
  currentPartnerType: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_PARTNER_TYPES:
    case ActionType.FETCH_PARTNER_TYPE:
    case ActionType.FETCH_PARTNER_TYPE_FULFILLED:
      const partnerType = action.partnerType;
      return { ...state, currentPartnerType: partnerType };

    case ActionType.REMOVE_PARTNER_TYPE:
      return { ...state };

    case ActionType.FETCH_PARTNER_TYPES_FULFILLED:
      const partnerTypes = action.partnerTypes || [];
      return { ...state, partnerTypes };

    case ActionType.CREATE_PARTNER_TYPE:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    // Partners
    case ActionType.FETCH_PARTNER_ITEMS:
    case ActionType.FETCH_PARTNER_ITEM_FULFILLED:
      const { currentPartnerItem } = action;
      return { ...state, currentPartnerItem };

    case ActionType.FETCH_PARTNER_ITEMS_FULFILLED:
      const partnerItems = action.partnerItems || [];
      return { ...state, partnerItems };

    case ActionType.CREATE_PARTNER_ITEM:
      return { ...state };

    case ActionType.REMOVE_PARTNER_ITEM:
      return { ...state };

    default:
      return state;
  }
};
