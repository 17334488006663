import { ActionType } from './types';

const initialState = {
  servicePartners: [],
  serviceItems: [],
  currentServiceItem: undefined,
  currentRentingPartner: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    case ActionType.FETCH_SERVICE_ITEMS:
    case ActionType.FETCH_SERVICE_ITEM_FULFILLED:
      const { currentServiceItem } = action;
      return { ...state, currentServiceItem };

    case ActionType.FETCH_SERVICE_ITEMS_FULFILLED:
      const serviceItems = action.serviceItems || [];
      return { ...state, serviceItems };

    case ActionType.CREATE_SERVICE_ITEM:
      return { ...state };

    case ActionType.REMOVE_SERVICE_ITEM:
      return { ...state };

    default:
      return state;
  }
};
