import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as RestaurantRoutes } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import FormImageInput from 'context/Core/Component/Form/ImageInput';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import { TimePicker } from '@atlaskit/datetime-picker';
import FormFooter from 'context/Core/Component/Form/Footer';
import Select from '@atlaskit/select';
import { Redirect, withRouter } from 'react-router';
import { DateTimeControlled } from '../../../../Core/Component/Form/DateTimeControlled';

class RForm extends Component<any, any> {
  static defaultProps = {
    name: '',
    slug: '',
    thumbnail: '',
    category: [],
    workingHours: {
      0: {
        from: '',
        to: '',
      },
      1: {
        from: '',
        to: '',
      },
      2: {
        from: '',
        to: '',
      },
      3: {
        from: '',
        to: '',
      },
      4: {
        from: '',
        to: '',
      },
      5: {
        from: '',
        to: '',
      },
      6: {
        from: '',
        to: '',
      },
    },
    position: '',
  };

  componentDidMount(): void {
    this.props.fetchRestaurantCategories();
  }

  constructor(props: any) {
    super(props);

    this.state = { thumbnail: undefined };
    this.setImage = this.setImage.bind(this);
  }

  setImage(file: any) {
    this.setState({ ...this.state, thumbnail: file });
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    const workingHoursMap: any = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };

    if (redirectToReferrer === true) {
      return <Redirect to={RestaurantRoutes.LIST} />;
    }

    return (
      <section>
        <ButtonBack path={RestaurantRoutes.LIST} titleBefore={'Restaurant Item Form'} />
        <Form
          onSubmit={
            (data: { data: object }) => this.props.submit(data, this.state.thumbnail, this.props.match.params.id)
          }>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'page'} >

              <Field name={'name'} defaultValue={this.props.name} isRequired={true} label={'Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Name'} {...fieldProps} />}
              </Field>

              <Field name={'position'} defaultValue={this.props.position} isRequired={true} label={'Location'}>
                {({ fieldProps }: { fieldProps: any }) =>
                  <TextField placeholder={'latitude,longitude'} {...fieldProps} />}
              </Field>

              <FormImageInput
                form={'page'}
                fieldName={'thumbnail'}
                isLabelHidden
                fieldValue={this.props.thumbnail}
                value={this.props.thumbnail}
                fieldLabel={'Thumbnail'}
                imageSelected={this.setImage}
              />

              <Field name={'slug'} defaultValue={this.props.slug} isRequired={true} label={'Slug'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Slug'} {...fieldProps} />}
              </Field>

              <Field
                name={'category'}
                defaultValue={
                  this.props.restaurantCategories.filter(
                    (option: any) => this.props.category && this.props.category.includes(option.value),
                  )
                }
                isRequired={true}
                label={'Select Tags'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <Select
                    isMulti
                    className={'multi-select'}
                    classNamePrefix={'react-select'}
                    options={this.props.restaurantCategories}
                    placeholder={'Choose a tag'}
                    {...fieldProps}
                  />
                )}
              </Field>

              <h4>Working Hours</h4>
              {
                this.props.workingHours[0].from !== '' && [...Array(7)].map((_, i: number) => {
                  return (
                    <div style={{ width: '100%', display: 'inline-flex'}} key={i}>
                      <div style={{width: '50%', marginRight: '25px'}}>
                        <Field
                          name={`workingHours[${i}].from`}
                          isRequired={true}
                          label={`${workingHoursMap[i]} From:`}
                          defaultValue={this.props.workingHours[i].from}
                        >
                          {({ fieldProps }: { fieldProps: any }) => (
                            <DateTimeControlled initialValue={this.props.workingHours[i].from}>
                              {({ value, onValueChange, onBlur, isOpen }: any) => (
                                <TimePicker
                                  {...fieldProps}
                                  selectProps={{ classNamePrefix: 'timepicker-select' }}
                                  value={value}
                                  onChange={(val: any) => {
                                    onValueChange(val);
                                    fieldProps.onChange(val);
                                  }}
                                  timeFormat={'HH:mm'}
                                  onBlur={onBlur}
                                  times={[
                                    '00:30',
                                    '01:00',
                                    '01:30',
                                    '02:00',
                                    '02:30',
                                    '03:00',
                                    '03:30',
                                    '04:00',
                                    '04:30',
                                    '05:00',
                                    '05:30',
                                    '06:00',
                                    '06:30',
                                    '07:00',
                                    '07:30',
                                    '08:00',
                                    '08:30',
                                    '09:00',
                                    '09:30',
                                    '10:00',
                                    '10:30',
                                    '11:00',
                                    '11:30',
                                    '12:00',
                                    '12:30',
                                    '13:00',
                                    '13:30',
                                    '14:00',
                                    '14:30',
                                    '15:00',
                                    '15:30',
                                    '16:00',
                                    '17:30',
                                    '18:00',
                                    '18:30',
                                    '19:00',
                                    '19:30',
                                    '20:00',
                                    '20:30',
                                    '21:00',
                                    '21:30',
                                    '22:00',
                                    '22:30',
                                    '23:00',
                                    '23:30',
                                    '00:00',
                                  ]}
                                  isOpen={isOpen}
                                  timeIsEditable
                                />
                              )}
                            </DateTimeControlled>
                          )}
                        </Field>
                      </div>
                      <div style={{width: '50%'}}>
                        <Field
                          name={`workingHours[${i}].to`}
                          isRequired={true}
                          label={`${workingHoursMap[i]} To:`}
                          defaultValue={this.props.workingHours[i].to}
                        >
                          {({ fieldProps }: { fieldProps: any }) => (
                            <DateTimeControlled initialValue={this.props.workingHours[i].to}>
                              {({ value, onValueChange, onBlur, isOpen }: any) => (
                                <TimePicker
                                  {...fieldProps}
                                  selectProps={{ classNamePrefix: 'timepicker-select' }}
                                  value={value}
                                  onChange={(val: any) => {
                                    onValueChange(val);
                                    fieldProps.onChange(val);
                                  }}
                                  timeFormat={'HH:mm'}
                                  times={[
                                    '00:30',
                                    '01:00',
                                    '01:30',
                                    '02:00',
                                    '02:30',
                                    '03:00',
                                    '03:30',
                                    '04:00',
                                    '04:30',
                                    '05:00',
                                    '05:30',
                                    '06:00',
                                    '06:30',
                                    '07:00',
                                    '07:30',
                                    '08:00',
                                    '08:30',
                                    '09:00',
                                    '09:30',
                                    '10:00',
                                    '10:30',
                                    '11:00',
                                    '11:30',
                                    '12:00',
                                    '12:30',
                                    '13:00',
                                    '13:30',
                                    '14:00',
                                    '14:30',
                                    '15:00',
                                    '15:30',
                                    '16:00',
                                    '17:30',
                                    '18:00',
                                    '18:30',
                                    '19:00',
                                    '19:30',
                                    '20:00',
                                    '20:30',
                                    '21:00',
                                    '21:30',
                                    '22:00',
                                    '22:30',
                                    '23:00',
                                    '23:30',
                                    '00:00',
                                  ]}
                                  onBlur={onBlur}
                                  isOpen={isOpen}
                                  timeIsEditable
                                />
                              )}
                            </DateTimeControlled>
                          )}
                        </Field>
                      </div>
                    </div>
                  );
                })
              }
              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ orderFood: { shouldRedirect, restaurantCategories } }: any) => {
  return {
    shouldRedirect,
    restaurantCategories: restaurantCategories.map(({ id, title }: any) => {
      return { label: title, value: id };
    }),
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, thumbnail: any, id: any) => {
      formData.thumbnail = thumbnail;
      formData.category = formData.category.map(({ value }: { value: string } )  => value);

      const form = new FormData();
      for (const key in formData) {
        if (key.includes('workingHour')) {
          continue;
        }

        if (!formData.hasOwnProperty(key)) {
          continue;
        }

        if (key === 'category') {
          for (const k in formData.category) {
            form.append(`category[${k}]`, formData.category[k]);
          }

          continue;
        }

        form.append(key, formData[key]);
      }

      [...Array(7)].map((_, k) => {
        if (!formData.workingHours || (formData.workingHours && !formData.workingHours.hasOwnProperty(k))) {
          return null;
        }

        const selectedValue = (formData.workingHours[k] && formData.workingHours[k].hasOwnProperty('from'))
          ? formData.workingHours[k] : false;

        const { from, to }: { from: string, to: string} = selectedValue || { from: '09:00', to: '24:00' };
        form.append(`workingHours[${k}][from]`, from);
        form.append(`workingHours[${k}][to]`, to);

        return '';
      });

      const type = id ? ActionType.UPDATE_RESTAURANT_ITEM : ActionType.CREATE_RESTAURANT_ITEM;
      const payload = form;
      if (id) {
        payload.append('id', id);
      }

      dispatch({ type, payload });
    },

    fetchRestaurantCategories: () => dispatch({ type: ActionType.FETCH_RESTAURANT_CATEGORIES }),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RForm));
