import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchPages,
  redirectAfterCreation,
  fetchPageFullFilled,
  fetchPagesFullFilled,
} from './actions';
import { Page } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchPagesEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_PAGES),
    mergeMap(() => {
      return from(Service.request(Page.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchPagesFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createPageEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_PAGE),
    mergeMap(({ payload }: any) => {
      return from(Service.request(Page.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Page successfully created.')),
          );
        }),
      );
    }),
  );
};

const removePageEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_PAGE),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Page.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchPages()),
            of(showNotification('Page successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updatePageEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_PAGE),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Page.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchPages()),
            of(showNotification('Page successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchPageEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_PAGE),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Page.find, { id })).pipe(
        map((response: any) => fetchPageFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchPagesEpic,
  createPageEpic,
  fetchPageEpic,
  updatePageEpic,
  removePageEpic,
};
