import { ActionType } from './types';

const initialState = {
  couriers: [],
  currentCourier: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_COURIERS:
    case ActionType.FETCH_COURIER:
    case ActionType.FETCH_COURIER_FULFILLED:
      const courier = action.courier;
      return { ...state, currentCourier: courier };

    case ActionType.REMOVE_COURIER:
      return { ...state };

    case ActionType.FETCH_COURIERS_FULFILLED:
      const couriers = action.couriers || [];
      return { ...state, couriers };

    case ActionType.CREATE_COURIER:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    default:
      return state;
  }
};
