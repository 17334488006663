import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as RestaurantRoute } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import { Redirect, withRouter } from 'react-router';
import { formatRoute } from 'react-router-named-routes';

class RCForm extends Component<any, any> {
  static defaultProps = {
    title: '',
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={formatRoute(RestaurantRoute.TAG_LIST, { id: this.props.match.params.id } )} />;
    }

    return (
      <section>
        <ButtonBack
          path={ formatRoute(RestaurantRoute.TAG_LIST, { id: this.props.match.params.id } ) }
          titleBefore={'Restaurant Category Form'}
        />
        <Form
          onSubmit={
            (data: { data: object }) => this.props.submit(
              data,
              this.props.match.params.id,
              this.props.match.params.tagId,
            )
          }>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'tag'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ orderFood: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, restaurantId: any, id: any) => {
      const type = id ? ActionType.UPDATE_RESTAURANT_TAG : ActionType.CREATE_RESTAURANT_TAG;
      const payload = formData;
      if (id) {
        payload.id = id;
      }

      dispatch({ type, payload, restaurantId });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RCForm));
