import { ActionType } from './types';

export function fetchPartnerTypes() {
  return {
    type: ActionType.FETCH_PARTNER_TYPES,
  };
}

export function fetchPartnerTypesFulFilled(partnerTypes: [] = []) {
  return {
    type: ActionType.FETCH_PARTNER_TYPES_FULFILLED,
    partnerTypes,
  };
}

export function fetchPartnerTypeFulFilled(partnerType: object) {
  return {
    type: ActionType.FETCH_PARTNER_TYPE_FULFILLED,
    partnerType,
  };
}

export function fetchPartnerType(id: string) {
  return {
    type: ActionType.FETCH_PARTNER_TYPE,
    id,
  };
}

export function createPartnerType(data: object) {
  return {
    type: ActionType.CREATE_PARTNER_TYPE,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

export function updatePartnerType(id: string, data: object) {
  return {
    type: ActionType.UPDATE_PARTNER_TYPE,
    id,
    data,
  };
}

export function removePartnerType(id: string) {
  return {
    type: ActionType.REMOVE_PARTNER_TYPE,
    id,
  };
}

// Partners
export function fetchPartnerItems() {
  return {
    type: ActionType.FETCH_PARTNER_ITEMS,
  };
}

export function fetchPartnerItemsFulFilled(partnerItems: [] = []) {
  return {
    type: ActionType.FETCH_PARTNER_ITEMS_FULFILLED,
    partnerItems,
  };
}

export function fetchPartnerItemFulFilled(currentPartnerItem: object) {
  return {
    type: ActionType.FETCH_PARTNER_ITEM_FULFILLED,
    currentPartnerItem,
  };
}

export function fetchPartnerItem(id: string) {
  return {
    type: ActionType.FETCH_PARTNER_ITEM,
    id,
  };
}

export function createPartnerItem(data: object) {
  return {
    type: ActionType.CREATE_PARTNER_ITEM,
    data,
  };
}

export function updatePartnerItem(id: string, data: object) {
  return {
    type: ActionType.UPDATE_PARTNER_ITEM,
    id,
    data,
  };
}

export function removePartnerItem(id: string) {
  return {
    type: ActionType.REMOVE_PARTNER_ITEM,
    id,
  };
}
