import React, { Component } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';
import Lozenge from '@atlaskit/lozenge';

export default class TabPayment extends Component<any, any> {
  render() {
    const dataTransformation: TableRowDataTransformation = {
      paymentType: ({ paymentType }: any) => paymentType === 'cache' ? 'Cache' : 'Credit Card/PayPal',
      paymentStatus: ({ paymentStatus }: { paymentStatus: string }) =>
        <Lozenge appearance={'success'}>{paymentStatus.toUpperCase()}</Lozenge>,
    };

    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'paymentType',
              content: 'Type',
              isSortable: false,
              width: 25,
            },
            {
              key: 'paymentStatus',
              content: 'Status',
              isSortable: false,
              width: 25,
            },
            {
              key: 'paymentTransactionId',
              content: 'Transaction ID',
              isSortable: false,
              width: 30,
            },
          ],
        }}
        dataTransformation={dataTransformation}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={[{
          id: this.props.request.id,
          paymentType: this.props.request.paymentType,
          paymentStatus: this.props.request.paymentStatus,
          paymentTransactionId: this.props.request.paymentTransactionId,
        }]}
      />
    );
  }
}
