import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as RestaurantRoute } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import { Redirect, withRouter } from 'react-router';

class RCForm extends Component<any, any> {
  static defaultProps = {
    title: '',
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={RestaurantRoute.CATEGORY_LIST} />;
    }

    return (
      <section>
        <ButtonBack path={RestaurantRoute.CATEGORY_LIST} titleBefore={'Restaurant Tag Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'page'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ orderFood: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_RESTAURANT_CATEGORY : ActionType.CREATE_RESTAURANT_CATEGORY;
      const payload = formData;
      if (id) {
        payload.id = id;
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RCForm));
