import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as ConciergeRoutes } from 'context/Concierge/Center/Router/types';
import { BreadcrumbDefinition } from 'context/Core/types';
import Center from '../Component/Center';
import { fetchRequests } from '../Store/actions';
import Button from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { ButtonWrapper } from '../../../Core/Component/Layout/Module/Table';
import styled from 'styled-components';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { ActionType } from '../Store/types';
import { Label } from '@atlaskit/field-base';
import { DatepickerControlled } from '../../../Core/Component/Form/DatepickerControlled';
import moment from 'moment';
import { DatePicker } from '@atlaskit/datetime-picker';
import { withRouter } from 'react-router';

const DivWrapper = styled.div`
  display: inline-flex;
  width: 100%
  justify-content: space-between;
`;

const conciergeRequestTypes = [
  'all',
  'accommodation',
  'personal-service',
  'renting',
  'reservation',
  'trip-planning',
  'contact',
];

const conciergeRequestStatuses = [
  'all',
  'requested',
  'needs-more-info',
  'partner-searching',
  'partner-found',
  'offer-search',
  'offer-sent',
  'offer-rejected',
  'offer-approved',
  'offer-rejected-by-system',
  'resolved',
];

class ReservationRootPage extends Component<any, any> {
  constructor(props: any) {
    super(props);

    if (!Object.keys(props.socket._callbacks).includes('$new-concierge')) {
      // tslint:disable-next-line:only-arrow-functions
      props.socket.on('new-concierge', function({ payload }: any) {
        props.addRequest(payload);
      });
    }
  }

  async componentDidMount() {
    if (this.props.match.params.hasOwnProperty('id')) {
      await this.props.mutateForm('id', this.props.match.params.id);
    }

    this.props.fetchRequests(this.props.form);
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Concierge Center',
        route: formatRoute(ConciergeRoutes.CONCIERGE_CENTER),
      },
    ];

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <DivWrapper style={{ zIndex: 999999, marginBottom: 15 }}>
          <div style={{ marginRight: 10, width: '100%' }}>
            <div style={{ marginTop: '-9px' }}>
              <Label label={'From'} />
            </div>
            <DatepickerControlled initialValue={moment(Date.now()).subtract(1, 'days').toString()}>
              {({ value, onValueChange, onBlur }) => (
                <DatePicker
                  value={value}
                  onChange={(val: any) => {
                    this.props.mutateForm('from', val);
                    onValueChange(val);
                  }}
                  onBlur={onBlur}
                />
              )}
            </DatepickerControlled>
          </div>
          <div style={{ marginRight: 10, width: '100%' }}>
            <div style={{ marginTop: '-9px' }}>
              <Label label={'To'} />
            </div>
            <DatepickerControlled initialValue={moment(Date.now()).add(1, 'days').toString()}>
              {({ value, onValueChange, onBlur }) => (
                <DatePicker
                  value={value}
                  onChange={(val: any) => {
                    this.props.mutateForm('to', val);
                    onValueChange(val);
                  }}
                  onBlur={onBlur}
                />
              )}
            </DatepickerControlled>
          </div>
          <div style={{ marginRight: 10, width: '100%' }}>
            <Field
              defaultValue={{ label: 'All', value: 'all' }}
              name={'status'}
              label={'Status'}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Select
                  classNamePrefix={'react-select'}
                  options={conciergeRequestStatuses.map((item: string) => ({ label: item.toUpperCase(), value: item }))}
                  placeholder={'Status'}
                  {...fieldProps}
                  onChange={(val: any) => {
                    fieldProps.onChange(val.value);
                    this.props.mutateForm('status', val.value);
                  }}
                  style={{
                    menu: (styles: any) => ({ ...styles, zIndex: 999 }),
                  }}
                  value={
                    conciergeRequestStatuses
                      .map((item: string) => ({ label: item.toUpperCase(), value: item }))
                      .find((item: any) => item.value === this.props.form.status) ||
                    { label: 'All', value: 'all' }
                  }
                />
              )}
            </Field>
          </div>
          <div style={{ marginRight: 10, width: '100%' }}>
            <Field
              defaultValue={{ label: 'All', value: 'all' }}
              name={'type'}
              label={'Type'}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Select
                  classNamePrefix={'react-select'}
                  options={conciergeRequestTypes.map((item: string) => ({ label: item.toUpperCase(), value: item }))}
                  placeholder={'Type'}
                  {...fieldProps}
                  onChange={(val: any) => {
                    fieldProps.onChange(val.value);
                    this.props.mutateForm('type', val.value);
                  }}
                  style={{
                    menu: (styles: any) => ({ ...styles, zIndex: 999 }),
                  }}
                  value={
                    conciergeRequestTypes
                      .map((item: string) => ({ label: item.toUpperCase(), value: item }))
                      .find((item: any) => item.value === this.props.form.type) ||
                    { label: 'All', value: 'all' }
                  }
                />
              )}
            </Field>
          </div>
          <ButtonWrapper style={{ float: 'right', paddingRight: 10, paddingTop: 35 }}>
            <Button
              appearance='primary'
              iconBefore={<SearchIcon label='Search' size='small' />}
              onClick={async () => {
                await this.props.mutateForm('id', null);
                this.props.history.push(formatRoute(ConciergeRoutes.CONCIERGE_CENTER, { id: null }));
                this.props.fetchRequests(this.props.form);
              }}
            >
              Search
            </Button>
          </ButtonWrapper>
        </DivWrapper>
        <section>
          {
            this.props.requests.length > 0 ? (
              <Center requests={this.props.requests} />
            ) : (<></>)
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ conciergeCenter: { requests, form }, core: { socket } }: any) => {
  return {
    requests,
    form,
    socket,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRequests: (payload: any = {}) => dispatch(fetchRequests(payload)),
    mutateForm: (key: string, value: any) => dispatch({ type: ActionType.MUTATE_FORM, key, value }),
    addRequest: (payload: any) => dispatch({ type: ActionType.ADD_REQUEST, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationRootPage));
