import { ActionType } from './types';

const initialState = {
  reservation: [],
  data: [],
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_RESERVATION:
    case ActionType.FETCH_RESERVATION_FULFILLED:
      return { ...state, reservation: action.reservation };

    case ActionType.UPDATE_RESERVATION:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
