import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as RestaurantCategoryRoute } from 'context/Order/Food/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import { TableHeadDefinition } from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';

class RestaurantCategoryPage extends Component<any, any> {
  componentDidMount() {
    this.props.fetchRestaurantCategories();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Restaurant Tag',
        route: formatRoute(RestaurantCategoryRoute.CATEGORY_LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          isSortable: false,
          width: 25,
        },
      ],
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Restaurant Tag'
              head={head}
              data={this.props.restaurantCategories}
              showActions={true}
              isRankable
              creationRoute={RestaurantCategoryRoute.CATEGORY_CREATE}
              modificationRoute={(id: string): string => formatRoute(RestaurantCategoryRoute.CATEGORY_EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removeRestaurantCategory(id)}
            />
          }
        </section>
      </LayoutWrapper>

    );
  }
}

const mapStateToProps = ({ orderFood: { restaurantCategories } }: any) => {
  return {
    restaurantCategories,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRestaurantCategories: () => dispatch({ type: ActionType.FETCH_RESTAURANT_CATEGORIES }),
    removeRestaurantCategory: (id: string) => {
      dispatch({ type: ActionType.REMOVE_RESTAURANT_CATEGORY, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantCategoryPage);
