import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as RestaurantRoute } from 'context/Order/Food/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import { TableHeadDefinition, TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '@atlaskit/button';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import { ActionType } from '../../Store/types';

class RestaurantMenuPage extends Component<any, any> {
  componentDidMount() {
    this.props.fetchRestaurantTags(this.props.match.params.id);
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Restaurant',
        route: formatRoute(RestaurantRoute.LIST),
      },
      {
        title: 'Restaurant Menu',
        route: formatRoute(RestaurantRoute.TAG_LIST, { id: this.props.match.params.id }),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          width: 25,
        },
        {
          key: '_id',
          content: 'Menu Products',
          width: 25,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      _id: ({ _id }): ReactNode => (
        <Link to={formatRoute(RestaurantRoute.TAG_PRODUCT_LIST, { id: this.props.match.params.id, tagId: _id })}>
          <Button appearance={'default'} iconBefore={<AppSwitcherIcon label={'Tag Products'} size='small' />} />
        </Link>
      ),
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Restaurant Menu'
              head={head}
              data={this.props.restaurantTags}
              showActions={true}
              dataTransformation={dataTransformation}
              isRankable
              creationRoute={formatRoute(RestaurantRoute.TAG_CREATE, { id: this.props.match.params.id })}
              modificationRoute={
                (id: string): string => formatRoute(
                  RestaurantRoute.TAG_EDIT, { id: this.props.match.params.id, tagId: id },
                )
              }
              onDeleteConfirmed={
                (id: string): any => this.props.removeRestaurantTag(
                  id,
                  this.props.match.params.id,
                )
              }
            />
          }
        </section>
      </LayoutWrapper>

    );
  }
}

const mapStateToProps = ({ orderFood: { restaurantTags } }: any) => {
  return {
    restaurantTags,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRestaurantTags: (restaurantId: any) => dispatch({ type: ActionType.FETCH_RESTAURANT_TAGS, restaurantId }),
    removeRestaurantTag: (id: string, restaurantId: string) => {
      dispatch({ type: ActionType.REMOVE_RESTAURANT_TAG, id, restaurantId });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RestaurantMenuPage));
