import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import DrinkItemPageList from 'context/Order/Drink/Page/Item/List';
import DrinkItemPageCreate from 'context/Order/Drink/Page/Item/Create';
import DrinkItemPageEdit from 'context/Order/Drink/Page/Item/Edit';

import DrinkCategoryPageList from 'context/Order/Drink/Page/Category/List';
import DrinkCategoryPageCreate from 'context/Order/Drink/Page/Category/Create';
import DrinkCategoryPageEdit from 'context/Order/Drink/Page/Category/Edit';

const routes: AppRouteDefinition[] = [
  {
    path: Route.LIST,
    Component: withMetadata(DrinkItemPageList, {
      title: 'Drink Item',
    }),
    guarded: true,
  },
  {
    path: Route.CREATE,
    Component: withMetadata(DrinkItemPageCreate, {
      title: 'Create Drink Item',
    }),
    guarded: true,
  },
  {
    path: Route.EDIT,
    Component: withMetadata(DrinkItemPageEdit, {
      title: 'Edit Drink Item',
    }),
    guarded: true,
  },

  // Drink Categories
  {
    path: Route.CATEGORY_LIST,
    Component: withMetadata(DrinkCategoryPageList, {
      title: 'Drink Category',
    }),
    guarded: true,
  },
  {
    path: Route.CATEGORY_CREATE,
    Component: withMetadata(DrinkCategoryPageCreate, {
      title: 'Create Drink Category',
    }),
    guarded: true,
  },
  {
    path: Route.CATEGORY_EDIT,
    Component: withMetadata(DrinkCategoryPageEdit, {
      title: 'Edit Drink Category',
    }),
    guarded: true,
  },
];

export default routes;
