import { ActionType } from './types';

export function fetchCouriers() {
  return {
    type: ActionType.FETCH_COURIERS,
  };
}

export function fetchCouriersFullFilled(couriers: [] = []) {
  return {
    type: ActionType.FETCH_COURIERS_FULFILLED,
    couriers,
  };
}

export function fetchCourierFullFilled(courier: object) {
  return {
    type: ActionType.FETCH_COURIER_FULFILLED,
    courier,
  };
}

export function fetchCourier(id: string) {
  return {
    type: ActionType.FETCH_COURIER,
    id,
  };
}

export function createCourier(data: object) {
  return {
    type: ActionType.CREATE_COURIER,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

export function updateCourier(id: string, data: object) {
  return {
    type: ActionType.UPDATE_COURIER,
    id,
    data,
  };
}

export function removeCourier(id: string) {
  return {
    type: ActionType.REMOVE_COURIER,
    id,
  };
}
