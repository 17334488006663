import { TenantAction, Tenant, ActionType } from './types';

export function fetchTenants(tenants: Tenant[]): TenantAction {
  return {
    type: ActionType.FETCH_TENANTS,
    tenants,
  };
}

export function changeTenant(tenant: Tenant): TenantAction {
  return {
    type: ActionType.CHANGE_TENANT,
    tenant,
  };
}
