import { ActionType } from './types';

const initialState = {
  items: [],
  restaurantCategories: [],
  restaurantTags: [],
  restaurantTagProducts: [],
  currentRestaurantItem: undefined,
  currentRestaurantCategory: undefined,
  currentRestaurantId: undefined,
  currentRestaurantTag: undefined,
  currentRestaurantTagProduct: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_RESTAURANT_ITEMS:
    case ActionType.FETCH_RESTAURANT_ITEM:
    case ActionType.FETCH_RESTAURANT_ITEM_FULFILLED:
      return { ...state, currentRestaurantItem: action.currentRestaurantItem };

    case ActionType.REMOVE_RESTAURANT_ITEM:
      return { ...state };

    case ActionType.FETCH_RESTAURANT_ITEMS_FULFILLED:
      const items = action.items || [];
      return { ...state, items };

    case ActionType.CREATE_RESTAURANT_ITEM:
      return { ...state };

    // Restaurant Categories
    case ActionType.FETCH_RESTAURANT_CATEGORIES:
    case ActionType.FETCH_RESTAURANT_CATEGORY:
    case ActionType.FETCH_RESTAURANT_CATEGORY_FULFILLED:
      return { ...state, currentRestaurantCategory: action.currentRestaurantCategory };

    case ActionType.REMOVE_RESTAURANT_CATEGORY:
      return { ...state };

    case ActionType.FETCH_RESTAURANT_CATEGORIES_FULFILLED:
      const restaurantCategories = action.restaurantCategories || [];
      return { ...state, restaurantCategories };

    case ActionType.CREATE_RESTAURANT_CATEGORY:
      return { ...state };

    // Restaurant Tags
    case ActionType.FETCH_RESTAURANT_TAGS:
      const currentRestaurantId = action.currentRestaurantId;
      return { ...state, currentRestaurantId };
    case ActionType.FETCH_RESTAURANT_TAG:
    case ActionType.FETCH_RESTAURANT_TAG_FULFILLED:
      return { ...state, currentRestaurantTag: action.currentRestaurantTag };

    case ActionType.REMOVE_RESTAURANT_TAG:
      return { ...state };

    case ActionType.FETCH_RESTAURANT_TAGS_FULFILLED:
      const restaurantTags = action.restaurantTags || [];
      return { ...state, restaurantTags };

    case ActionType.CREATE_RESTAURANT_TAG:
      return { ...state };

    // Restaurant Tag Products
    case ActionType.FETCH_TAG_PRODUCTS:
      return {
        ...state,
        currentRestaurantId: action.currentRestaurantId,
        currentRestaurantTagId: action.currentRestaurantTagId,
      };
    case ActionType.FETCH_TAG_PRODUCT:
    case ActionType.FETCH_TAG_PRODUCT_FULFILLED:
      return {
        ...state,
        currentRestaurantId: action.currentRestaurantId,
        currentRestaurantTag: action.currentRestaurantTag,
        currentRestaurantTagProduct: action.currentRestaurantTagProduct,
      };

    case ActionType.REMOVE_TAG_PRODUCT:
      return { ...state };

    case ActionType.FETCH_TAG_PRODUCTS_FULFILLED:
      const restaurantTagProducts = action.restaurantTagProducts || [];
      return { ...state, restaurantTagProducts };

    case ActionType.CREATE_TAG_PRODUCT:
      return {
        ...state,
        currentRestaurantId: action.currentRestaurantId,
        currentRestaurantTag: action.currentRestaurantTag,
      };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    default:
      return state;
  }
};
