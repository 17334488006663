import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as Routes } from '../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import { Redirect, withRouter } from 'react-router';

class GiftForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    price: '',
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={Routes.LIST} />;
    }

    return (
      <section>
        <ButtonBack path={Routes.LIST} titleBefore={'Gift Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'gift'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Gift Name'}>
                {({ fieldProps }: { fieldProps: any }) =>
                  <TextField placeholder={'Gift Name'} {...fieldProps} /> }
              </Field>

              <Field name={'price'} defaultValue={this.props.price} isRequired={true} label={'Gift Price'}>
                {({ fieldProps }: { fieldProps: any }) =>
                  <TextField type={'number'} placeholder={'Gift Price'} {...fieldProps} />}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ orderGift: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_GIFT : ActionType.CREATE_GIFT;
      let payload = formData;
      if (id) {
        payload.id = id;
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GiftForm));
