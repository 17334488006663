import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchRentingTypes,
  redirectAfterCreation,
  fetchRentingTypesFulFilled,
  fetchRentingTypeFulFilled,
  fetchRentingSubtypesFulFilled,
  fetchRentingSubtypeFulFilled,
  fetchRentingSubtypes,
} from './actions';
import {
  ConciergeRentingSubtype,
  ConciergeRentingType,
} from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchRentingTypesEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RENTING_TYPES),
    mergeMap(() => {
      return from(Service.request(ConciergeRentingType.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchRentingTypesFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createRentingTypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_RENTING_TYPE),
    mergeMap(({ payload }: any) => {
      return from(Service.request(ConciergeRentingType.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Renting type successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeRentingTypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_RENTING_TYPE),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(ConciergeRentingType.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchRentingTypes()),
            of(showNotification('RentingType successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateRentingTypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_RENTING_TYPE),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(ConciergeRentingType.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchRentingTypes()),
            of(showNotification('RentingType successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchRentingTypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RENTING_TYPE),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(ConciergeRentingType.find, { id })).pipe(
        map((response: any) => fetchRentingTypeFulFilled(response)),
      );
    }),
  );
};

// Sub types
const fetchRentingSubtypesEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RENTING_SUBTYPES),
    mergeMap(() => {
      return from(Service.request(ConciergeRentingSubtype.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchRentingSubtypesFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createRentingSubtypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_RENTING_SUBTYPE),
    mergeMap(({ payload }: any) => {
      return from(Service.request(ConciergeRentingSubtype.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Renting subtype successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeRentingSubtypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_RENTING_SUBTYPE),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(ConciergeRentingSubtype.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchRentingSubtypes()),
            of(showNotification('Renting Subtype successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateRentingSubtypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_RENTING_SUBTYPE),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(ConciergeRentingSubtype.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchRentingSubtypes()),
            of(showNotification('Renting subtype successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchRentingSubtypeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RENTING_SUBTYPE),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(ConciergeRentingSubtype.find, { id })).pipe(
        map((response: any) => fetchRentingSubtypeFulFilled(response)),
      );
    }),
  );
};

export default {
  fetchRentingTypesEpic,
  createRentingTypeEpic,
  fetchRentingTypeEpic,
  updateRentingTypeEpic,
  removeRentingTypeEpic,

  // Sub types
  fetchRentingSubtypesEpic,
  createRentingSubtypeEpic,
  removeRentingSubtypeEpic,
  updateRentingSubtypeEpic,
  fetchRentingSubtypeEpic,
};
