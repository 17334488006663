import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as SectionRoute } from 'context/Section/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import {
  TableHeadDefinition,
  TableRowDataDefinition,
  TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';
import Avatar, { AvatarItem } from '@atlaskit/avatar';

class SectionItemPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchSectionItems();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Section Item',
        route: formatRoute(SectionRoute.LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'image',
          content: 'Image',
          isSortable: false,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          isSortable: false,
          width: 25,
        },
        {
          key: 'description',
          content: 'Description',
          isSortable: false,
          width: 25,
        },
        {
          key: 'type',
          content: 'Type',
          isSortable: false,
          width: 10,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      image: ({ image }: TableRowDataDefinition & {
        image: string,
      }): ReactNode => (
        <AvatarItem avatar={<Avatar src={image} />} />
      ),
    };
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Section Item'
              head={head}
              data={this.props.items}
              dataTransformation={dataTransformation}
              showActions={true}
              creationRoute={SectionRoute.CREATE}
              modificationRoute={(id: string): string => formatRoute(SectionRoute.EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removeSectionItem(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ section: { items } }: any) => {
  return {
    items,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchSectionItems: () => dispatch({ type: ActionType.FETCH_SECTION_ITEMS }),
    removeSectionItem: (id: string) => {
      dispatch({ type: ActionType.REMOVE_SECTION_ITEM, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionItemPageList);
