import { ActionType } from './types';

export function fetchSectionItems() {
  return {
    type: ActionType.FETCH_SECTION_ITEMS,
  };
}

export function fetchSectionItemsFullFilled(items: [] = []) {
  return {
    type: ActionType.FETCH_SECTION_ITEMS_FULFILLED,
    items,
  };
}

export function fetchSectionItemFullFilled(currentSectionItem: object) {
  return {
    type: ActionType.FETCH_SECTION_ITEM_FULFILLED,
    currentSectionItem,
  };
}

export function fetchSectionItem(id: string) {
  return {
    type: ActionType.FETCH_SECTION_ITEM,
    id,
  };
}

export function updateSectionItem(id: string, data: object) {
  return {
    type: ActionType.UPDATE_SECTION_ITEM,
    id,
    data,
  };
}

export function removeSectionItem(id: string) {
  return {
    type: ActionType.REMOVE_SECTION_ITEM,
    id,
  };
}

// Section Categories
export function fetchSectionCategories() {
  return {
    type: ActionType.FETCH_SECTION_CATEGORIES,
  };
}

export function fetchSectionCategoriesFullFilled(sectionCategories: [] = []) {
  return {
    type: ActionType.FETCH_SECTION_CATEGORIES_FULFILLED,
    sectionCategories,
  };
}

export function fetchSectionCategoryFullFilled(currentSectionCategory: object) {
  return {
    type: ActionType.FETCH_SECTION_CATEGORY_FULFILLED,
    currentSectionCategory,
  };
}

export function fetchSectionCategory(id: string) {
  return {
    type: ActionType.FETCH_SECTION_CATEGORY,
    id,
  };
}

export function updateSectionCategory(id: string, data: object) {
  return {
    type: ActionType.UPDATE_SECTION_CATEGORY,
    id,
    data,
  };
}

export function removeSectionCategory(id: string) {
  return {
    type: ActionType.REMOVE_SECTION_CATEGORY,
    id,
  };
}

export function createSectionCategory(data: object) {
  return {
    type: ActionType.CREATE_SECTION_CATEGORY,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}
