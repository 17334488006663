import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Route as BlogRoutes } from '../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import FormImageInput from 'context/Core/Component/Form/ImageInput';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import TextArea from '@atlaskit/textarea';
import Toggle from '@atlaskit/toggle';
import { Redirect, withRouter } from 'react-router';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SelectCreatable from '../../Core/Component/Form/SelectCreatable';

class BlogForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    description: '',
    content: undefined,
    image: '',
    published: false,
    tags: [],
  };

  constructor(props: any) {
    super(props);

    this.state = { image: undefined, editorState: undefined, tags: [] };
    this.setImage = this.setImage.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentWillReceiveProps(nextProps: any) {
    if (typeof nextProps.content === 'string') {
      this.onEditorStateChange(
        EditorState.createWithContent(convertFromRaw(JSON.parse(nextProps.content))),
      );
    }
  }

  setImage(file: any) {
    this.setState({ ...this.state, image: file });
  }

  setTags(tags: any) {
    this.setState({
      ...this.state,
      tags,
    });
  }

  onEditorStateChange(editorState: any) {
    this.setState({
      editorState,
    });
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={BlogRoutes.LIST} />;
    }

    let tags: any = [];
    this.props.tags.map((item: any, i: any) =>
      Object.values(item).map((it: any, index: any) => tags.push({ label: it, value: it })));

    return (
      <section>
        <ButtonBack path={BlogRoutes.LIST} titleBefore={'Blog Form'} />
        <Form
          onSubmit={
            (data: { data: object }) => this.props.submit(
              data,
              JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
              this.state.image,
              this.props.match.params.id,
              this.state.tags,
            )
          }>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'blog'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <Field name={'description'} defaultValue={this.props.description} isRequired={true} label={'Description'}>
                {({ fieldProps }: { fieldProps: any }) => <TextArea placeholder={'Description'} {...fieldProps} />}
              </Field>

              <Field name={'content'}
                     isRequired={false}
                     label={'Content'}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName={'toolbarClassName'}
                    wrapperClassName={'wrapperClassName'}
                    editorClassName={'editorClassName'}
                    onEditorStateChange={this.onEditorStateChange}
                    {...fieldProps}
                  />
                }
              </Field>

              <FormImageInput
                form={'blog'}
                fieldName={'image'}
                isLabelHidden
                fieldValue={this.props.image}
                value={this.props.image}
                fieldLabel={'Image'}
                imageSelected={this.setImage}
              />

              <Field
                name={'published'}
                isRequired={true}
                label={'Published'}
                defaultValue={this.props.showInFooter}
              >
                {({ fieldProps }: { fieldProps: any }) =>
                  <Toggle isDefaultChecked={this.props.published} size={'large'} { ...fieldProps } /> }
              </Field>

              <Field
                name={'tags'}
                defaultValue={tags}
                isRequired={false}
                label={'Create SEO Tags'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <SelectCreatable
                    className={'single-select'}
                    classNamePrefix={'react-select'}
                    placeholder={'Create SEO Tags'}
                    handleTagsChange={(ts: any) => this.setTags(ts)}
                    {...fieldProps}
                  />
                )}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ blog: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, content: any, image: any, id: any, tags: []) => {
      formData.image = image;
      formData.content = content;
      const form = new FormData();
      for (const key in formData) {
        if (!formData.hasOwnProperty(key) || key === 'tags') {
          continue;
        }

        form.append(key, formData[key]);
      }

      const type = id ? ActionType.UPDATE_BLOG : ActionType.CREATE_BLOG;
      const payload = form;
      if (id) {
        payload.append('id', id);
      }

      for (let k in tags) {
        if (_.isEmpty(tags[k])) {
          continue;
        }

        payload.append(`tags[${k}]`, tags[k]);
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlogForm));
