import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as Routes } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import TextArea from '@atlaskit/textarea';
import { Redirect, withRouter } from 'react-router';
import { formatRoute } from 'react-router-named-routes';
import AddItem from '@atlaskit/icon/glyph/add-item';
import RemoveIcon from '@atlaskit/icon/glyph/trash';

class RMForm extends Component<any, any> {
  static defaultProps = {
    title: '',
    description: '',
    price: '',
    extras: [],
  };

  constructor(props: any) {
    super(props);
    this.state = {
      extras: props.extras.length ? props.extras : [],
    };
  }

  componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    this.setState({
      ...this.state,
      extras: nextProps.extras,
    });
  }

  componentDidMount(): void {
    this.addExtra = this.addExtra.bind(this);
    this.removeExtra = this.removeExtra.bind(this);
  }

  addExtra() {
    const extras = this.state.extras.concat([ { label: '', price: '' } ]);
    this.setState({
      extras,
    });
  }

  removeExtra(k: number) {
    const extras = [...this.state.extras];
    extras.splice(k, 1);

    this.setState({
      extras,
    });
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={formatRoute(Routes.TAG_PRODUCT_LIST, {
        id: this.props.match.params.id,
        tagId: this.props.match.params.tagId,
      })} />;
    }

    return (
      <section>
        <ButtonBack path={formatRoute(Routes.TAG_PRODUCT_LIST, {
          id: this.props.match.params.id,
          tagId: this.props.match.params.tagId,
        })} titleBefore={'Restaurant Category Products'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(
            data,
            this.props.match.params.productId,
            this.props.match.params.tagId,
            this.props.match.params.id,
          )}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'r-category-item'} >

              <Field name={'title'} defaultValue={this.props.title} isRequired={true} label={'Title'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Title'} {...fieldProps} />}
              </Field>

              <Field name={'description'}
                     defaultValue={this.props.description} isRequired={false} label={'Description'}>
                {({ fieldProps }: { fieldProps: any }) => <TextArea placeholder={'Description'} {...fieldProps} />}
              </Field>

              <Field name={'price'} defaultValue={this.props.price} isRequired={true} label={'Price'}>
                {({ fieldProps }: { fieldProps: any }) =>
                  <TextField type={'number'} placeholder={'Price'} {...fieldProps} />}
              </Field>

              <h4>Extras</h4>
              {
                this.state.extras.map((_: any, k: any) => {
                  return (
                    <div style={{ width: '100%', display: 'inline-flex'}} key={k}>
                      <div style={{width: '50%', marginRight: '25px'}}>
                        <Field
                          name={`extras[${k}].label`}
                          defaultValue={this.state.extras[k].label}
                          isRequired={true}
                          label={'Label'}
                        >
                          {({ fieldProps }: { fieldProps: any }) =>
                            <TextField type={'text'} placeholder={'Label'} {...fieldProps} />}
                        </Field>
                      </div>
                      <div style={{ width: '40%' }}>
                        <Field
                          name={`extras[${k}].price`}
                          defaultValue={this.state.extras[k].price}
                          isRequired={true}
                          label={'Price'}
                        >
                          {({ fieldProps }: { fieldProps: any }) =>
                            <TextField type={'number'} placeholder={'Price'} {...fieldProps} />}
                        </Field>
                      </div>

                      <div style={{ width: '10%', marginTop: 35, marginLeft: 25 }}>
                        <Button
                          appearance='primary'
                          iconBefore={<RemoveIcon label='Remove' size='small' />}
                          onClick={() => this.removeExtra(k)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  );
                })
              }
              <div style={{ marginTop: 20 }}>
                <Button
                  appearance='primary'
                  iconBefore={<AddItem label='Add' size='small' />}
                  onClick={this.addExtra}
                >
                  Add
                </Button>
              </div>
              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ orderFood: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any, tagId: string, restaurantId: string) => {
      const type = id ? ActionType.UPDATE_TAG_PRODUCT : ActionType.CREATE_TAG_PRODUCT;
      const payload = formData;
      const extras = payload.extras;
      if (id) {
        formData.id = id;
      }

      let normalizedPayload: any = {};
      for (let k in payload) {
        if (k.includes('extras[')) {
          continue;
        }

        normalizedPayload[k] = payload[k];
      }

      normalizedPayload.extras = extras;

      dispatch({ type, payload, tagId, restaurantId });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RMForm));
