import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as PageRoute } from 'context/Page/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import Lozenge from '@atlaskit/lozenge';
import {
  TableHeadDefinition,
  TableRowDataDefinition,
  TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../Store/types';

class AdminPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchPages();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Page',
        route: formatRoute(PageRoute.LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          isSortable: false,
          width: 25,
        },
        {
          key: 'description',
          content: 'Description',
          isSortable: false,
          width: 25,
        },
        {
          key: 'published',
          content: 'Published',
          isSortable: false,
          width: 10,
        },
        {
          key: 'showInFooter',
          content: 'Footer',
          isSortable: false,
          width: 10,
        },
        {
          key: 'showInNavigation',
          content: 'Navigation',
          isSortable: false,
          width: 10,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      published: ({ published }: TableRowDataDefinition & { published: boolean }): ReactNode => (
        <Lozenge appearance={ published === true ? 'success' : 'removed' }>
          { published ? 'Published' : 'Hidden' }
        </Lozenge>
      ),

      showInFooter: ({ showInFooter }: TableRowDataDefinition & { showInFooter: boolean }): ReactNode => (
        <Lozenge appearance={ showInFooter === true ? 'success' : 'removed' }>
          { showInFooter ? 'Visible' : 'Hidden' }
        </Lozenge>
      ),

      showInNavigation: ({ showInNavigation }: TableRowDataDefinition & { showInNavigation: boolean }): ReactNode => (
        <Lozenge appearance={ showInNavigation === true ? 'success' : 'removed' }>
          { showInNavigation ? 'Visible' : 'Hidden' }
        </Lozenge>
      ),
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Page'
              head={head}
              data={this.props.pages}
              dataTransformation={dataTransformation}
              showActions={true}
              creationRoute={PageRoute.CREATE}
              modificationRoute={(id: string): string => formatRoute(PageRoute.EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removePage(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ page: { pages } }: any) => {
  return {
    pages,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchPages: () => dispatch({ type: ActionType.FETCH_PAGES }),
    removePage: (id: string) => {
      dispatch({ type: ActionType.REMOVE_PAGE, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPageList);
