import React, { Component } from 'react';
import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Service from '../../../../util/Service';
import { NotificationWeb } from '../../../../medium/api';

const BANNER_HEIGHT = 51;
const Icon = <WarningIcon label={'Warning icon'} secondaryColor={'inherit'}/>;

class WebPushBanner extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = { isSubscribed: true, worker: {} };
    // this.checkForPushNotifications = this.checkForPushNotifications.bind(this);
    this.setType = this.setType.bind(this);
    this.hashString = this.hashString.bind(this);
    this.register = this.register.bind(this);
  }

  // componentDidMount(): void {
  //   this.checkForPushNotifications();
  // }

  setType(type: string = 'worker', value: any) {
    if (type === 'worker') {
      this.setState({
        ...this.state,
        worker: value,
      });
    }

    if (type === 'isSubscribed') {
      this.props.setOffset(value ? 0 : 51);
      this.setState({
        ...this.state,
        isSubscribed: value,
      });
    }
  }

  hashString(base64String: string) {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let rawData = window.atob((base64String + padding).replace(/-/g, '+').replace(/_/g, '/'));
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  register() {
    let ctx = this;
    let applicationKey = 'BOH7FXakwi-h-WqPtCgEUj-S7uNoIyvP_9N-MqSym1DfzcU6v2mcXNtIE6d6LJnXyuAnE0kmRv5HFxPw9txQgSM';
    let data = { userVisibleOnly: true, applicationServerKey: ctx.hashString(applicationKey) };
    ctx.state.worker
      .pushManager
      .subscribe(data)
      .then(async (subscription: any) => {
        Service.request(NotificationWeb.subscribe, {}, { subscription })
          .then(() => ctx.setType('isSubscribed', true))
          .catch(() => ctx.setType('isSubscribed', false));
      })
      .catch((err: any) => {
        ctx.setType('isSubscribed', false);
        // tslint:disable-next-line:no-console
        console.log('Failed to subscribe user: ', err.message);
      })
    ;
  }

  // checkForPushNotifications() {
  //   let ctx = this;
  //   let serviceWorkerAvailableOnDevice = 'serviceWorker' in navigator && 'PushManager' in window;
  //   if (!serviceWorkerAvailableOnDevice) {
  //     // tslint:disable-next-line:no-console
  //     console.error('Push notifications are not supported on this device.');
  //   }

  //   navigator
  //     .serviceWorker
  //     .register('/sw.js')
  //     .then(serviceWorker => {
  //       ctx.setType('worker', serviceWorker);
  //       serviceWorker
  //         .pushManager
  //         .getSubscription()
  //         .then((subscription: any) => ctx.setType('isSubscribed', !(subscription === null)))
  //       ;
  //     })
  //     .catch(error => {
  //       // tslint:disable-next-line:no-console
  //       console.error('Service Worker Error', error.message);
  //     })
  //   ;
  // }

  render() {
    if (this.state.isSubscribed) {
      return ('');
    }

    return (
      <div
        style={{
          height: BANNER_HEIGHT,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          marginRight: '-17px',
          overflow: 'initial',
        }}
      >
        <Banner appearance={'announcement'} icon={Icon} isOpen>
          Minion, ease your work by clicking
          <button
            style={{
              marginRight: '10px',
              marginLeft: '10px',
              border: 'none',
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
            onClick={this.register}>
            Subscribe
          </button>
          button to receive push notifications.
        </Banner>
      </div>
    );
  }
}

export default WebPushBanner;
