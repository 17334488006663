import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import styled from 'styled-components';

import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as RentingRoute } from 'context/Concierge/Renting/Router/types';
import { BreadcrumbDefinition } from 'context/Core/types';
import TreeRenderer from 'context/Core/Component/Tree/Renderer';

import { ActionType } from '../Store/types';

const TopWrapper = styled.div`
  margin: 0 0 30px 0;
`;

class ReservationRootPage extends Component<any, any> {
  componentDidMount() {
    this.props.fetchReservation();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Reservation Builder',
        route: formatRoute(RentingRoute.TYPE_LIST),
      },
    ];

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          <TopWrapper>
            <h3 style={{ display: 'inline-block' }}>
              Reservation Builder
            </h3>
          </TopWrapper>
          {
            <TreeRenderer
              reservation={this.props.reservation}
              onUpdate={(payload: any) => this.props.updateReservation(payload)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ conciergeReservation: { reservation } }: any) => {
  return {
    reservation,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchReservation: () => dispatch({ type: ActionType.FETCH_RESERVATION }),
    updateReservation: (payload: any) => dispatch({ type: ActionType.UPDATE_RESERVATION, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRootPage);
