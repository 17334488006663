export const ActionType = {
  FETCH_DISPATCHERS: 'FETCH_DISPATCHERS',
  FETCH_DISPATCHERS_FULFILLED: 'FETCH_DISPATCHERS_FULFILLED',
  FETCH_DISPATCHER_FULFILLED: 'FETCH_DISPATCHER_FULFILLED',
  FETCH_DISPATCHER: 'FETCH_DISPATCHER',
  CREATE_DISPATCHER: 'CREATE_DISPATCHER',
  UPDATE_DISPATCHER: 'UPDATE_DISPATCHER',
  REMOVE_DISPATCHER: 'REMOVE_DISPATCHER',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
