import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as ItemRoutes } from '../../Router/types';
import Form from '../../Component/Restaurant/Form';

class RestaurantCreatePage extends Component<any, any> {
  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Restaurant Item',
              route: formatRoute(ItemRoutes.LIST),
            },
            {
              title: 'Create',
              route: formatRoute(ItemRoutes.CREATE),
            },
          ]
        }
        >
        </LayoutTitle>
        <Form />
      </LayoutWrapper>
    );
  }
}

export default connect()(RestaurantCreatePage);
