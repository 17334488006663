import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as ItemRoutes } from '../../Router/types';
import Form from '../../Component/Restaurant/Form';
import { ActionType } from '../../Store/types';
import { withRouter } from 'react-router';

class RestaurantEditPage extends Component<any, any> {
  componentDidMount(): void {
    this.props.fetchRestaurantItem(this.props.match.params.id);
  }

  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Restaurant Item',
              route: formatRoute(ItemRoutes.LIST),
            },
            {
              title: 'Edit',
              route: formatRoute(ItemRoutes.EDIT),
            },
          ]
        }
        >
        </LayoutTitle>
        <Form {...this.props.currentRestaurantItem} />
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ orderFood: { currentRestaurantItem } }: any) => {
  return {
    currentRestaurantItem,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRestaurantItem: (id: string) => dispatch({ type: ActionType.FETCH_RESTAURANT_ITEM, ...{ id } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RestaurantEditPage));
