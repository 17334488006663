import { ActionType } from './types';
import moment from 'moment';

const initialState = {
  requests: [],
  request: {},
  showForm: false,
  form: {
    from: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD').toString(),
    to: moment(new Date()).add(1, 'days').format('YYYY-MM-DD').toString(),
    status: 'all',
    type: 'all',
    zone: 'all',
    restaurant: 'all',
    overlay: true,
    id: null,
  },
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.ADD_ORDER:
      return { ...state, requests: [action.payload].concat(state.requests)};
    case ActionType.MUTATE_FORM:
      return { ...state, ...{ form: { ...state.form, [action.key]: action.value } } };
    case ActionType.SHOW_FORM:
      return { ...state, showForm: action.show };
    case ActionType.FETCH_MAP_REQUEST:
      return { ...state, request: initialState.request };

    case ActionType.FETCH_MAP_REQUEST_FULFILLED:
      return { ...state, request: action.request };

    case ActionType.UPDATE_MAP_REQUEST:
      return {
        ...state,
        data: action.data,
        request: action.data.action === 'REMOVE' ? {} : state.request,
      };

    case ActionType.MODIFY_REQUEST:
      return { ...state, id: action.id, payload: action.payload };

    case ActionType.FETCH_MAP_REQUESTS:
      return { ...state, requests: initialState.requests, request: {}, payload: action.payload || state.form };

    case ActionType.FETCH_MAP_REQUESTS_FULFILLED:
      return { ...state, requests: action.requests };

    default:
      return state;
  }
};
