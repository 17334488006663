import { ActionType } from './types';

export function fetchPages() {
  return {
    type: ActionType.FETCH_PAGES,
  };
}

export function fetchPagesFullFilled(pages: [] = []) {
  return {
    type: ActionType.FETCH_PAGES_FULFILLED,
    pages,
  };
}

export function fetchPageFullFilled(page: object) {
  return {
    type: ActionType.FETCH_PAGE_FULFILLED,
    page,
  };
}

export function fetchPage(id: string) {
  return {
    type: ActionType.FETCH_PAGE,
    id,
  };
}

export function createPage(data: object) {
  return {
    type: ActionType.CREATE_PAGE,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

export function updatePage(id: string, data: object) {
  return {
    type: ActionType.UPDATE_PAGE,
    id,
    data,
  };
}

export function removePage(id: string) {
  return {
    type: ActionType.REMOVE_PAGE,
    id,
  };
}
