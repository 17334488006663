import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  redirectAfterCreation,
  fetchServiceItemFulFilled,
  fetchServiceItems,
  fetchServiceItemsFulFilled,
} from './actions';
import { ConciergeServiceItem } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

// Service Items
const fetchServiceItemsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SERVICE_ITEMS),
    mergeMap(() => {
      return from(Service.request(ConciergeServiceItem.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchServiceItemsFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createServiceItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_SERVICE_ITEM),
    mergeMap(({ payload }: any) => {
      return from(Service.request(ConciergeServiceItem.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Service item successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeServiceItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_SERVICE_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(ConciergeServiceItem.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchServiceItems()),
            of(showNotification('Service item successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateServiceItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_SERVICE_ITEM),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(ConciergeServiceItem.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchServiceItems()),
            of(showNotification('Service item successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchServiceItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SERVICE_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(ConciergeServiceItem.find, { id })).pipe(
        map((response: any) => fetchServiceItemFulFilled(response)),
      );
    }),
  );
};

export default {
  // Items
  fetchServiceItemsEpic,
  createServiceItemEpic,
  removeServiceItemEpic,
  updateServiceItemEpic,
  fetchServiceItemEpic,
};
