import React, { Component } from 'react';
import { connect } from 'react-redux';
import { colors } from '@atlaskit/theme';
import { formatRoute } from 'react-router-named-routes';

import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as Routes } from 'context/Settings/Router/types';
import { BreadcrumbDefinition } from 'context/Core/types';
import { ButtonItem, MenuGroup, Section, HeadingItem } from '@atlaskit/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faMoneyBill, faChartLine, faMapMarker, faShoppingBag } from '@fortawesome/free-solid-svg-icons';

import * as _ from 'lodash';
import { ActionType } from '../Store/types';
import Form, { Field } from '@atlaskit/form';
import FormFooter from '../../Core/Component/Form/Footer';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import styled from 'styled-components';
import Select from '@atlaskit/select';
import RemoveIcon from '@atlaskit/icon/glyph/trash';
import Button from '@atlaskit/button';
import AddItem from '@atlaskit/icon/glyph/add-item';

// tslint:disable-next-line:no-var-requires
const DataObjectParser = require('dataobject-parser');

const DivWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const DivItemWrapper = styled.div`width: 50%;`;
const DivThreeItemWrapper = styled.div`width: 33.33333%;`;
const DivFourItemWrapper = styled.div`width: 25%;`;

class Root extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      visible: 'general',
      marketShop: props.settings.marketShop && props.settings.marketShop.length
        ? props.settings.marketShop : [],
    };

    this.changeForm = this.changeForm.bind(this);
    this.addMarketShopItem = this.addMarketShopItem.bind(this);
    this.removeMarketShopItem = this.removeMarketShopItem.bind(this);
  }

  componentDidMount() {
    this.props.fetchSettings();
  }

  componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    this.setState({
      ...this.state,
      marketShop: nextProps.settings.marketShop,
    });
  }

  changeForm(name: string) {
    this.setState({
      ...this.state,
      visible: name,
    });
  }

  addMarketShopItem() {
    const marketShop = this.state.marketShop.concat([ { from: 0, to: 0, price: 0 } ]);
    this.setState({
      marketShop,
    });
  }

  removeMarketShopItem(k: number) {
    this.setState({
      marketShop: this.state.marketShop.filter((e: any, i: any) => i !== k),
    });
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Global Settings',
        route: formatRoute(Routes.ROOT),
      },
    ];

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section style={{ width: '100%', display: 'flex'}}>
          <div
            style={{
              color: colors.N800,
              border: `1px solid ${colors.N40}`,
              boxShadow:
                '0px 4px 8px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
              borderRadius: 4,
              width: '20%',
              margin: '16px auto',
            }}
          >
            <MenuGroup>
              <Section>
                <HeadingItem>Settings</HeadingItem>
                <ButtonItem
                  elemBefore={<FontAwesomeIcon icon={faChartArea} style={{ width: 24, height: 24 }} />}
                  description={'General Settings'}
                  onClick={() => this.changeForm('general')}
                >
                  General
                </ButtonItem>
                <ButtonItem
                  elemBefore={<FontAwesomeIcon icon={faMoneyBill} style={{ width: 24, height: 24 }} />}
                  description={'Payment Settings'}
                  onClick={() => this.changeForm('payment')}
                >
                  Payment
                </ButtonItem>
                <ButtonItem
                  elemBefore={<FontAwesomeIcon icon={faChartLine} style={{ width: 24, height: 24 }} />}
                  description={'Discount Settings'}
                  onClick={() => this.changeForm('discount')}
                >
                  Discount
                </ButtonItem>
                <ButtonItem
                  elemBefore={<FontAwesomeIcon icon={faMapMarker} style={{ width: 24, height: 24 }} />}
                  description={'Zone Settings'}
                  onClick={() => this.changeForm('zone')}
                >
                  Zone
                </ButtonItem>
                <div style={{ marginBottom: '25px' }}>
                  <ButtonItem
                    elemBefore={<FontAwesomeIcon icon={faShoppingBag} style={{ width: 24, height: 24 }} />}
                    description={'Market Shop Settings'}
                    onClick={() => this.changeForm('market')}
                  >
                    Market Shop
                  </ButtonItem>
                </div>
              </Section>
            </MenuGroup>
          </div>
          <div
            style={{
              color: colors.N800,
              border: `1px solid ${colors.N40}`,
              boxShadow:
                '0px 4px 8px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
              borderRadius: 4,
              width: '80%',
              marginLeft: '10px',
              marginTop: '15px',
              padding: '15px',
              position: 'relative',
              paddingBottom: '65px',
            }}
          >
            <Form
              initialValues={this.props.settings}
              style={{ height: '100%', position: 'relative', width: '100%' }}
              onSubmit={(data: { data: object }) => this.props.updateSettings(data, this.state.marketShop)}>
              {({ formProps }: { formProps: object }) => (
                <form {...formProps} name={'settings'}>
                  {
                    this.state.visible === 'general' ? (
                      <div>
                        <h4>General Information:</h4>
                        <Field
                          name={'siteTitle'}
                          defaultValue={this.props.settings.siteTitle}
                          isRequired={true}
                          label={'Site Title'}
                        >
                          {({ fieldProps }: { fieldProps: any }) =>
                            <TextField placeholder={'Site Title'} {...fieldProps} />}
                        </Field>

                        <Field
                          name={'siteDescription'}
                          defaultValue={this.props.settings.siteDescription}
                          isRequired={true}
                          label={'Site Description'}
                        >
                          {({ fieldProps }: { fieldProps: any }) =>
                            <TextArea placeholder={'Site Description'} {...fieldProps} />}
                        </Field>
                      </div>
                    ) : ('')
                  }

                  {
                    this.state.visible === 'payment' ? (
                      <div>
                        <h4>Payment Information:</h4>
                        <DivWrapper>
                          <DivItemWrapper>
                            <Field
                              name={'siteCurrency'}
                              defaultValue={this.props.settings.siteCurrency}
                              isRequired={true}
                              label={'Site Currency'}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={'€'} {...fieldProps} />}
                            </Field>
                          </DivItemWrapper>
                          <DivItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={'amountPerExtraStop'}
                              defaultValue={this.props.settings.amountPerExtraStop}
                              isRequired={true}
                              type={'number'}
                              label={'Amount Per Extra Stop'}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={'25'} {...fieldProps} />}
                            </Field>
                          </DivItemWrapper>
                        </DivWrapper>

                        <DivWrapper>
                          <DivItemWrapper>
                            <Field
                              name={'surchargePercent'}
                              defaultValue={this.props.settings.surchargePercent}
                              isRequired={true}
                              type={'number'}
                              label={'Surcharge Percent'}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={'15'} {...fieldProps} />}
                            </Field>
                          </DivItemWrapper>
                          <DivItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={'surchargeAfter'}
                              defaultValue={this.props.settings.surchargeAfter}
                              isRequired={true}
                              label={'Surcharge After'}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField
                                  type={'number'}
                                  placeholder={'150'} {...fieldProps} />}
                            </Field>
                          </DivItemWrapper>
                        </DivWrapper>
                      </div>
                    ) : ('')
                  }

                  {
                    this.state.visible === 'discount' ? (
                      <>
                        <h4>Regular:</h4>
                        <DivWrapper>
                          <DivFourItemWrapper>
                            <Field
                              name={`timePriceIncrease.regular.from`}
                              defaultValue={this.props.settings.timePriceIncrease.regular.from}
                              label={`Regular From`}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={`Regular From`} {...fieldProps} />}
                            </Field>
                          </DivFourItemWrapper>
                          <DivFourItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={`timePriceIncrease.regular.to`}
                              defaultValue={this.props.settings.timePriceIncrease.regular.to}
                              label={`Regular To`}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={`Regular To`} {...fieldProps} />}
                            </Field>
                          </DivFourItemWrapper>
                          <DivFourItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={`timePriceIncrease.regular.timeType`}
                              defaultValue={
                                this.props.settings.timePriceIncrease.regular.timeType === 'am' ?
                                  { value: 'am', label: 'AM' } : { value: 'pm', label: 'PM' }
                              }
                              label={'AM/PM'}
                            >
                              {({ fieldProps }: { fieldProps: any }) => (
                                <Select
                                  className={'single-select'}
                                  classNamePrefix={'react-select'}
                                  options={[{ value: 'am', label: 'AM' }, { value: 'pm', label: 'PM' }]}
                                  placeholder={'Select time type'}
                                  {...fieldProps}
                                />
                              )}
                            </Field>
                          </DivFourItemWrapper>
                          <DivFourItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={`timePriceIncrease.regular.percent`}
                              defaultValue={this.props.settings.timePriceIncrease.regular.percent}
                              label={`Regular Percent`}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField
                                  type={'number'}
                                  placeholder={15} {...fieldProps} />}
                            </Field>
                          </DivFourItemWrapper>
                        </DivWrapper>
                        <Field
                          name={`timePriceIncrease.regular.enabled`}
                          defaultValue={
                            this.props.settings.timePriceIncrease.regular.enabled ?
                              { value: true, label: 'Enabled' } : { value: false, label: 'Disabled' }
                          }
                          label={'Enable/Disable'}
                        >
                          {({ fieldProps }: { fieldProps: any }) => (
                            <Select
                              className={'single-select'}
                              classNamePrefix={'react-select'}
                              options={[{ value: true, label: 'Enabled' }, { value: false, label: 'Disabled' }]}
                              placeholder={'Select time type'}
                              {...fieldProps}
                            />
                          )}
                        </Field>

                        <h4>Happy Hour:</h4>
                        <DivWrapper key={`timePriceHappy`}>
                          <DivFourItemWrapper>
                            <Field
                              name={`timePriceIncrease.happyHour.from`}
                              defaultValue={this.props.settings.timePriceIncrease.happyHour.from}
                              label={`Happy Hour From`}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={`Happy Hour From`} {...fieldProps} />}
                            </Field>
                          </DivFourItemWrapper>
                          <DivFourItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={`timePriceIncrease.happyHour.to`}
                              defaultValue={this.props.settings.timePriceIncrease.happyHour.to}
                              label={`Happy Hour To`}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField placeholder={`Happy Hour To`} {...fieldProps} />}
                            </Field>
                          </DivFourItemWrapper>
                          <DivFourItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={`timePriceIncrease.happyHour.timeType`}
                              defaultValue={
                                this.props.settings.timePriceIncrease.happyHour.timeType === 'am' ?
                                  { value: 'am', label: 'AM' } : { value: 'pm', label: 'PM' }
                                }
                              label={'AM/PM'}
                            >
                              {({ fieldProps }: { fieldProps: any }) => (
                                <Select
                                  className={'single-select'}
                                  classNamePrefix={'react-select'}
                                  options={[{ value: 'am', label: 'AM' }, { value: 'pm', label: 'PM' }]}
                                  placeholder={'Select time type'}
                                  {...fieldProps}
                                />
                              )}
                            </Field>
                          </DivFourItemWrapper>
                          <DivFourItemWrapper style={{ marginLeft: 15 }}>
                            <Field
                              name={`timePriceIncrease.happyHour.percent`}
                              defaultValue={this.props.settings.timePriceIncrease.happyHour.percent}
                              label={`Happy Hour Percent`}
                            >
                              {({ fieldProps }: { fieldProps: any }) =>
                                <TextField
                                  type={'number'}
                                  placeholder={15} {...fieldProps} />}
                            </Field>
                          </DivFourItemWrapper>
                        </DivWrapper>
                        <Field
                          name={`timePriceIncrease.happyHour.enabled`}
                          defaultValue={
                            this.props.settings.timePriceIncrease.happyHour.enabled ?
                              { value: true, label: 'Enabled' } : { value: false, label: 'Disabled' }
                          }
                          isRequired={false}
                          label={'Enable/Disable'}
                        >
                          {({ fieldProps }: { fieldProps: any }) => (
                            <Select
                              className={'single-select'}
                              classNamePrefix={'react-select'}
                              options={[{ value: true, label: 'Enabled' }, { value: false, label: 'Disabled' }]}
                              placeholder={'Select time type'}
                              {...fieldProps}
                            />
                          )}
                        </Field>
                      </>
                    ) : ('')
                  }

                  {
                    this.state.visible === 'zone' ? (
                      [1, 2, 3].map((value) => {
                        const zone = value;

                        return (
                          <>
                            <h4>Zone {zone}:</h4>
                            <DivWrapper key={`${zone}-zone`}>
                              <DivThreeItemWrapper>
                                <Field
                                  name={`zones.${zone}.chargeAmount`}
                                  defaultValue={this.props.settings.zones[zone].chargeAmount}
                                  isRequired={true}
                                  type={'number'}
                                  label={`Zone ${zone} Charge Amount`}
                                >
                                  {({ fieldProps }: { fieldProps: any }) =>
                                    <TextField
                                      type={'number'}
                                      placeholder={`Zone ${zone} Charge Amount`} {...fieldProps} />}
                                </Field>
                              </DivThreeItemWrapper>
                              <DivThreeItemWrapper style={{ marginLeft: 15 }}>
                                <Field
                                  name={`zones.${zone}.discountReason`}
                                  defaultValue={this.props.settings.zones[zone].discountReason}
                                  label={`Zone ${zone} Discount Reason`}
                                >
                                  {({ fieldProps }: { fieldProps: any }) =>
                                    <TextField placeholder={`Zone ${zone} Discount Reason`} {...fieldProps} />}
                                </Field>
                              </DivThreeItemWrapper>
                              <DivThreeItemWrapper style={{ marginLeft: 15 }}>
                                <Field
                                  name={`zones.${zone}.discountAmount`}
                                  defaultValue={this.props.settings.zones[zone].discountAmount}
                                  label={`Zone ${zone} Discount Amount`}
                                >
                                  {({ fieldProps }: { fieldProps: any }) =>
                                    <TextField
                                      type={'number'}
                                      placeholder={`Zone ${zone} Discount Amount`} {...fieldProps} />}
                                </Field>
                              </DivThreeItemWrapper>
                            </DivWrapper>
                            <DivWrapper key={`${zone}-zoneTime`}>
                              <DivFourItemWrapper>
                                <Field
                                  name={`zoneTimeIncrease.${zone}.from`}
                                  defaultValue={this.props.settings.zoneTimeIncrease[zone].from}
                                  label={`Zone ${zone} Increase From`}
                                >
                                  {({ fieldProps }: { fieldProps: any }) =>
                                    <TextField placeholder={`Zone ${zone} Increase From`} {...fieldProps} />}
                                </Field>
                              </DivFourItemWrapper>
                              <DivFourItemWrapper style={{ marginLeft: 15 }}>
                                <Field
                                  name={`zoneTimeIncrease.${zone}.to`}
                                  defaultValue={this.props.settings.zoneTimeIncrease[zone].to}
                                  label={`Zone ${zone} Increase To`}
                                >
                                  {({ fieldProps }: { fieldProps: any }) =>
                                    <TextField placeholder={`Zone ${zone} Increase To`} {...fieldProps} />}
                                </Field>
                              </DivFourItemWrapper>
                              <DivFourItemWrapper style={{ marginLeft: 15 }}>
                                <Field
                                  name={`zoneTimeIncrease.${zone}.timeType`}
                                  defaultValue={
                                    this.props.settings.zoneTimeIncrease[zone].timeType === 'am' ?
                                      { value: 'am', label: 'AM' } : { value: 'pm', label: 'PM' }
                                  }
                                  isRequired={false}
                                  label={'AM/PM'}
                                >
                                  {({ fieldProps }: { fieldProps: any }) => (
                                    <Select
                                      className={'single-select'}
                                      classNamePrefix={'react-select'}
                                      options={[{ value: 'am', label: 'AM' }, { value: 'pm', label: 'PM' }]}
                                      placeholder={'Select time type'}
                                      {...fieldProps}
                                    />
                                  )}
                                </Field>
                              </DivFourItemWrapper>
                              <DivFourItemWrapper style={{ marginLeft: 15 }}>
                                <Field
                                  name={`zoneTimeIncrease.${zone}.amount`}
                                  defaultValue={this.props.settings.zoneTimeIncrease[zone].amount}
                                  label={`Zone ${zone} Increase Amount`}
                                >
                                  {({ fieldProps }: { fieldProps: any }) =>
                                    <TextField
                                      type={'number'}
                                      placeholder={`Zone ${zone} Increase Amount`} {...fieldProps} />}
                                </Field>
                              </DivFourItemWrapper>
                            </DivWrapper>
                          </>
                        );
                      })
                    ) : ('')
                  }

                  {
                    this.state.visible === 'market' ? (
                      <>
                        <h4>Market Shop Rules:</h4>
                        {
                          this.state.marketShop.map((_: any, k: any) => {
                            return (
                              <DivWrapper key={`${k}-market-shop`}>
                                <DivFourItemWrapper>
                                  <Field
                                    name={`marketShop[${k}].from`}
                                    defaultValue={this.state.marketShop[k].from || 0}
                                    isRequired={true}
                                    label={`From`}
                                  >
                                    {({ fieldProps }: { fieldProps: any }) =>
                                      <TextField placeholder={`From`} {...fieldProps} />}
                                  </Field>
                                </DivFourItemWrapper>
                                <DivFourItemWrapper style={{ marginLeft: 15 }}>
                                  <Field
                                    name={`marketShop[${k}].to`}
                                    defaultValue={this.state.marketShop[k].to || 0}
                                    isRequired={true}
                                    label={`To`}
                                  >
                                    {({ fieldProps }: { fieldProps: any }) =>
                                      <TextField placeholder={`To`} {...fieldProps} />}
                                  </Field>
                                </DivFourItemWrapper>
                                <DivFourItemWrapper style={{ marginLeft: 15 }}>
                                  <Field
                                    name={`marketShop[${k}].price`}
                                    defaultValue={this.state.marketShop[k].price || 0}
                                    isRequired={true}
                                    label={`Price`}
                                  >
                                    {({ fieldProps }: { fieldProps: any }) =>
                                      <TextField
                                        type={'number'}
                                        placeholder={15} {...fieldProps} />}
                                  </Field>
                                </DivFourItemWrapper>
                                <DivFourItemWrapper style={{ marginLeft: 15 }}>
                                  <div style={{ marginTop: '34px' }}>
                                    <Button
                                      appearance='primary'
                                      iconBefore={<RemoveIcon label='' size='small' />}
                                      onClick={() => this.removeMarketShopItem(k)}
                                    >
                                    </Button>
                                  </div>
                                </DivFourItemWrapper>
                              </DivWrapper>
                            );
                          })
                        }

                        <div style={{ marginTop: 20 }}>
                          <Button
                            appearance='primary'
                            iconBefore={<AddItem label='Add' size='small' />}
                            onClick={() => this.addMarketShopItem()}
                          >
                            Add
                          </Button>
                        </div>
                      </>
                    ) : ('')
                  }

                  <div style={{ position: 'absolute', bottom: 0, margin: '15px', right: 0}}>
                    <FormFooter />
                  </div>
                </form>
              )}
            </Form>
          </div>
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ settings: { settings } }: any) => {
  return {
    settings,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchSettings: () => dispatch({ type: ActionType.FETCH_SETTINGS }),
    updateSettings: (data: any) => {
      let transpose: any = {};
      for (let k in data) {
        if (!k.includes('.') || !data.hasOwnProperty(k)) {
          continue;
        }

        transpose[k] = data[k];
      }

      let dirtyData: any = DataObjectParser.transpose(transpose).data();
      let newData: any = {};
      for (let k in data) {
        if (k.includes('.') || !data.hasOwnProperty(k)) {
          continue;
        }

        newData[k] = data[k];
      }

      let payload = _.merge(dirtyData, newData);

      const hasTimePriceIncrease = payload.hasOwnProperty('timePriceIncrease');
      const hasZoneTimeIncrease = payload.hasOwnProperty('zoneTimeIncrease');
      const hasRegular = hasTimePriceIncrease && payload.timePriceIncrease.regular;
      const hasHappyHour = hasTimePriceIncrease && payload.timePriceIncrease.happyHour;

      if (hasZoneTimeIncrease) {
        for (let k of [1, 2, 3]) {
          if (payload.zoneTimeIncrease[k].timeType) {
            const { value } = payload.zoneTimeIncrease[k].timeType;
            payload.zoneTimeIncrease[k].timeType = value;
          }
        }
      }

      if (hasRegular) {
        if (payload.timePriceIncrease.regular.timeType) {
          const { value } = payload.timePriceIncrease.regular.timeType;
          payload.timePriceIncrease.regular.timeType = value;
        }

        if (payload.timePriceIncrease.regular.enabled) {
          const { value } = payload.timePriceIncrease.regular.enabled;
          payload.timePriceIncrease.regular.enabled = value;
        }
      }

      if (hasHappyHour) {
        if (payload.timePriceIncrease.happyHour.timeType) {
          const { value } = payload.timePriceIncrease.happyHour.timeType;
          payload.timePriceIncrease.happyHour.timeType = value;
        }

        if (payload.timePriceIncrease.happyHour.enabled) {
          const { value } = payload.timePriceIncrease.happyHour.enabled;
          payload.timePriceIncrease.happyHour.enabled = value;
        }
      }

      if (payload.hasOwnProperty('marketShop')) {
        payload.marketShop = payload.marketShop.filter((item: any) => !_.isEmpty(item.price));
      }

      dispatch({ type: ActionType.UPDATE_SETTINGS, payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
