import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchBlogs,
  redirectAfterCreation,
  fetchBlogFullFilled,
  fetchBlogsFullFilled,
} from './actions';
import { Blog } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchBlogsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_BLOGS),
    mergeMap(() => {
      return from(Service.request(Blog.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchBlogsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createBlogEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_BLOG),
    mergeMap(({ payload }: any) => {
      return from(Service.request(Blog.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Blog successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeBlogEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_BLOG),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Blog.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchBlogs()),
            of(showNotification('Blog successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateBlogEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_BLOG),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Blog.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchBlogs()),
            of(showNotification('Blog successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchBlogEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_BLOG),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Blog.find, { id })).pipe(
        map((response: any) => fetchBlogFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchBlogsEpic,
  createBlogEpic,
  fetchBlogEpic,
  updateBlogEpic,
  removeBlogEpic,
};
