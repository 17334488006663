import axios from 'axios';
import store from 'store/index';
import { ActionType } from 'context/Core/Store/types';

axios.interceptors.request.use(config => {
  store.dispatch({ type: ActionType.SHOW_LOADER });
  return config;
}, error => {
  store.dispatch({ type: ActionType.SHOW_LOADER });
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  store.dispatch({ type: ActionType.SHOW_LOADER });
  return response;
}, error => Promise.reject(error));

axios.interceptors.response.use(response => response, error => {
  store.dispatch({ type: ActionType.SHOW_LOADER });
  return Promise.reject(error);
});

export default axios;
