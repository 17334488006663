import React, { Component } from 'react';
import { CreatableSelect } from '@atlaskit/select';

const defaultOptions: any = [];

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

interface State {
  isLoading: boolean;
  options: Array<{ label: string; value: string }>;
  value?: {};
}

export default class SelectCreatable extends Component<any, State> {
  state: State = {
    isLoading: false,
    options: defaultOptions,
    value: undefined,
  };

  handleChange = (newValue: any) => {
    this.setState({ value: newValue });
    this.props.handleTagsChange(newValue.map((item: any) => item.value));
  }

  handleCreate = (inputValue: any) => {
    this.setState({ isLoading: true });
    const { options } = this.state;
    const newOption = createOption(inputValue);
    this.setState({
      isLoading: false,
      options: [...options, newOption],
      value: newOption,
    });
  }

  render() {
    const { isLoading, options, value } = this.state;
    return (
      <CreatableSelect
        isClearable
        isMulti
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.handleChange}
        onCreateOption={this.handleCreate}
        options={options}
        value={value}
      />
    );
  }
}
