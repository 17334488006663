import { ActionType } from './types';

export function fetchTripPlanning() {
  return {
    type: ActionType.FETCH_TRIP_PLANNING,
  };
}

export function fetchTripPlanningFulFilled(tripPlanning: object) {
  return {
    type: ActionType.FETCH_TRIP_PLANNING_FULFILLED,
    tripPlanning,
  };
}

export function updateTripPlanning(data: object) {
  return {
    type: ActionType.FETCH_TRIP_PLANNING,
    data,
  };
}
