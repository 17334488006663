import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route as Routes } from '../Router/types';
import Form, { Field } from '@atlaskit/form';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import FormImageInput from 'context/Core/Component/Form/ImageInput';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../Store/types';
import FormFooter from 'context/Core/Component/Form/Footer';
import { Redirect, withRouter } from 'react-router';

class AdminForm extends Component<any, any> {
  static defaultProps = {
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    email: '',
    avatar: '',
  };

  constructor(props: any) {
    super(props);

    this.state = { avatar: undefined };
    this.setAvatar = this.setAvatar.bind(this);
  }

  setAvatar(file: any) {
    this.setState({ ...this.state, avatar: file });
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={Routes.LIST} />;
    }

    return (
      <section>
        <ButtonBack path={Routes.LIST} titleBefore={'Courier Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.state.avatar, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'courier'} >

              <Field name={'firstName'} defaultValue={this.props.firstName} isRequired={true} label={'First Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'First Name'} {...fieldProps} />}
              </Field>

              <Field name={'lastName'} defaultValue={this.props.lastName} isRequired={true} label={'Last Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Last Name'} {...fieldProps} />}
              </Field>

              <Field name={'email'} defaultValue={this.props.email} isRequired={true} label={'Email Address'}>
                {({ fieldProps }: { fieldProps: any }) => (
                  <TextField placeholder={'Email Address'} type={'email'} {...fieldProps} />
                )}
              </Field>

              <Field name={'password'}
                     defaultValue={this.props.password}
                     isRequired={!this.props.match.params.id}
                     label={'Password'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <TextField placeholder={'Password'} type={'password'} {...fieldProps} />
                )}
              </Field>

              <FormImageInput
                form={'courier'}
                fieldName={'avatar'}
                isLabelHidden
                fieldValue={this.props.avatar}
                value={this.props.avatar}
                fieldLabel={'Avatar'}
                imageSelected={this.setAvatar}
              />

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ courier }: any) => {
  return {
    shouldRedirect: courier.shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, avatar: any, id: any) => {
      formData.avatar = avatar;
      const form = new FormData();
      for (const key in formData) {
        if (!formData.hasOwnProperty(key)) {
          continue;
        }

        form.append(key, formData[key]);
      }

      const type = id ? ActionType.UPDATE_COURIER : ActionType.CREATE_COURIER;
      const payload = form;
      if (id) {
        payload.append('id', id);
      }

      dispatch({ type, payload });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminForm));
