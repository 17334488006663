import { ActionType } from './types';

const initialState = {
  tripPlanning: [],
  data: [],
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_TRIP_PLANNING:
    case ActionType.FETCH_TRIP_PLANNING_FULFILLED:
      return { ...state, tripPlanning: action.tripPlanning };

    case ActionType.UPDATE_TRIP_PLANNING:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
