import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import PageList from 'context/Page/Page/List';
import PageCreate from 'context/Page/Page/Create';
import PageEdit from 'context/Page/Page/Edit';

const routes: AppRouteDefinition[] = [
  {
    path: Route.LIST,
    Component: withMetadata(PageList, {
      title: 'Page',
    }),
    guarded: true,
  },
  {
    path: Route.CREATE,
    Component: withMetadata(PageCreate, {
      title: 'Create Page',
    }),
    guarded: true,
  },
  {
    path: Route.EDIT,
    Component: withMetadata(PageEdit, {
      title: 'Edit Page',
    }),
    guarded: true,
  },
];

export default routes;
