import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchGifts,
  redirectAfterCreation,
  fetchGiftFullFilled,
  fetchGiftsFullFilled,
} from './actions';
import { Gift } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchGiftsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_GIFTS),
    mergeMap(() => {
      return from(Service.request(Gift.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchGiftsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createGiftEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_GIFT),
    mergeMap(({ payload }: any) => {
      return from(Service.request(Gift.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Gift successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeGiftEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_GIFT),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Gift.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchGifts()),
            of(showNotification('Gift successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateGiftEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_GIFT),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Gift.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchGifts()),
            of(showNotification('Gift successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchGiftEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_GIFT),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Gift.find, { id })).pipe(
        map((response: any) => fetchGiftFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchGiftsEpic,
  createGiftEpic,
  fetchGiftEpic,
  updateGiftEpic,
  removeGiftEpic,
};
