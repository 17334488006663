import { ActionType } from './types';

const initialState = {
  pages: [],
  currentPage: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_PAGES:
    case ActionType.FETCH_PAGE:
    case ActionType.FETCH_PAGE_FULFILLED:
      const page = action.page;
      return { ...state, currentPage: page };

    case ActionType.REMOVE_PAGE:
      return { ...state };

    case ActionType.FETCH_PAGES_FULFILLED:
      const pages = action.pages || [];
      return { ...state, pages };

    case ActionType.CREATE_PAGE:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    default:
      return state;
  }
};
