import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as Routes } from '../../Router/types';
import Form from '../../Component/Type/Form';
import { ActionType } from '../../Store/types';
import { withRouter } from 'react-router';

class RentingTypePageEdit extends Component<any, any> {
  componentDidMount(): void {
    this.props.fetchRentingType(this.props.match.params.id);
  }

  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Renting Type',
              route: formatRoute(Routes.TYPE_LIST),
            },
            {
              title: 'Edit',
              route: formatRoute(Routes.TYPE_EDIT),
            },
          ]
        }
        >
        </LayoutTitle>
        <Form {...this.props.rentingType} />
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ conciergeRenting }: any) => {
  return {
    rentingType: conciergeRenting.currentRentingType,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRentingType: (id: string) => dispatch({ type: ActionType.FETCH_RENTING_TYPE, ...{ id } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RentingTypePageEdit));
