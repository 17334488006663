export enum Route {
  LIST = '/restaurant/item',
  EDIT = '/restaurant/item/:id',
  CREATE = '/restaurant/item/create',

  CATEGORY_LIST = '/restaurant/tag',
  CATEGORY_EDIT = '/restaurant/tag/:id',
  CATEGORY_CREATE = '/restaurant/tag/create',

  TAG_LIST = '/restaurant/item/:id/tag',
  TAG_EDIT = '/restaurant/item/:id/tag/:tagId',
  TAG_CREATE = '/restaurant/item/:id/tag/create',

  TAG_PRODUCT_LIST = '/restaurant/item/:id/category/:tagId/products',
  TAG_PRODUCT_EDIT = '/restaurant/item/:id/category/:tagId/products/:productId',
  TAG_PRODUCT_CREATE = '/restaurant/item/:id/category/:tagId/create/products',
}
