/* eslint-disable */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
// tslint:disable-next-line:no-var-requires
const markerCluster = require('@google/markerclustererplus').default;

const evtNames = [
  'click',
  'dblclick',
  'dragend',
  'mousedown',
  'mouseout',
  'mouseover',
  'mouseup',
  'recenter',
];

const MarkerClusterer = (props: any) => {
  const { map, google, markers } = props;

  const handleEvent = ({ event, marker, entry }: any) => {
    if (props[event]) {
      props[event]({
        props,
        marker,
        event,
        entry,
      });
    }
  };

  // This hook works like ComponentWillMount
  // The  hook isn't really needed, this whole thing worked without it,
  // I added the hook so that I could implement a cleanup function
  useEffect(() => {
    if (map && markers) {
      const mapMarkers = markers.map((marker: any) => {
        const entry = new google.maps.Marker({
          position: {
            lat: marker.position.lat,
            lng: marker.position.lng,
          },
          map,
          name: marker.name,
          icon: marker.icon,
          animation: marker.animation,
        });

        evtNames.forEach((e: any) => {
          entry.addListener(e, () => handleEvent({
            event: e,
            marker,
            entry,
          }));
        });

        return entry;
      });

      const clusterer = new markerCluster(map, mapMarkers, {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      });

      // Cleanup function. Note, this is only returned if we create the markers
      return () => {
        clusterer.clearMarkers();
      };
    }
  }, [map, google, markers]);

  return null;
};

markerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  })),
};

export default MarkerClusterer;
