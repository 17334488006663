import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import ReservationRootPage from './../Page/Root';

const routes: AppRouteDefinition[] = [
  {
    path: Route.RESERVATION_ROOT,
    Component: withMetadata(ReservationRootPage, {
      title: 'Reservation Builder',
    }),
    guarded: true,
  },
];

export default routes;
