import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as RestaurantMenuRoutes } from '../../Router/types';
import Form from '../../Component/RestaurantMenu/Form';
import { ActionType } from '../../Store/types';
import { withRouter } from 'react-router';

class RestaurantCategoryPageEdit extends Component<any, any> {
  componentDidMount(): void {
    this.props.fetchRestaurantTag(this.props.match.params.tagId);
  }

  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Restaurant',
              route: formatRoute(RestaurantMenuRoutes.LIST),
            },
            {
              title: 'Restaurant Category',
              route: formatRoute(RestaurantMenuRoutes.TAG_LIST, { id: this.props.match.params.id }),
            },
            {
              title: 'Create',
              route: formatRoute(RestaurantMenuRoutes.TAG_CREATE),
            },
          ]
        }
        >
        </LayoutTitle>
        <Form {...this.props.currentRestaurantTag} />
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ orderFood: { currentRestaurantTag } }: any) => {
  return {
    currentRestaurantTag,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRestaurantTag: (id: string) => dispatch({ type: ActionType.FETCH_RESTAURANT_TAG, ...{ id } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RestaurantCategoryPageEdit));
