import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { ServiceInterface } from '../../../../util/Service';
import { ActionType } from './types';
import { flatMap, mergeMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { fetchMapRequestAction, fetchRequestFulFilled, fetchRequests, fetchRequestsFulFilled } from './actions';
import { OrderRequestCenter } from 'medium/api';

const fetchMapRequests = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_MAP_REQUESTS),
    mergeMap(({ payload }: any) => {
      return from(Service.request(OrderRequestCenter.fetchRequests, {}, payload)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchRequestsFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const fetchMapRequest = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_MAP_REQUEST),
    mergeMap(({ id }) => {
      return from(Service.request(OrderRequestCenter.find, { id })).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchRequestFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const updateMapRequest = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_MAP_REQUEST),
    mergeMap(({ data }: any) => {
      return from(Service.request(OrderRequestCenter.update, {}, data)).pipe(
        flatMap(() => {
            return concat(
              of(fetchRequests(state$.value.orderCenter.form)),
            );
          },
        ),
      );
    }),
  );
};

const modifyRequest = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.MODIFY_REQUEST),
    mergeMap(({ id, payload }: any) => {
      return from(Service.request(OrderRequestCenter.modifyRequest, { id }, payload)).pipe(
        flatMap(() => {
            return concat(
              of(
                payload.action !== 'REMOVE'
                  ? fetchMapRequestAction(id)
                  : fetchRequests(state$.value.orderCenter.form)
              ),
            );
          },
        ),
      );
    }),
  );
};
export default {
  fetchMapRequests,
  fetchMapRequest,
  updateMapRequest,
  modifyRequest,
};
