import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import { Redirect, withRouter } from 'react-router';
import FormFooter from 'context/Core/Component/Form/Footer';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';

class PartnerTypeForm extends Component<any, any> {
  static defaultProps = {
    name: '',
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={Route.TYPE_LIST} />;
    }

    return (
      <section>
        <ButtonBack path={Route.TYPE_LIST} titleBefore={'Partner Type Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'partner-type'} >

              <Field name={'name'} defaultValue={this.props.name} isRequired={true} label={'Type Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Type Name'} {...fieldProps} />}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ partner: { shouldRedirect } }: any) => {
  return {
    shouldRedirect,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_PARTNER_TYPE : ActionType.CREATE_PARTNER_TYPE;
      dispatch({ type, payload: { ...formData, id } });
    },
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartnerTypeForm));
