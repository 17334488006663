import React, { Component, ReactNode } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';
import Avatar, { AvatarItem } from '@atlaskit/avatar';

export default class TabDrink extends Component<any, any> {
  render() {
    const dataTransformation: TableRowDataTransformation = {
      category: ({ category }: any & {
        category: string,
      }): ReactNode => (
        <AvatarItem avatar={<Avatar src={'/'}/>} primaryText={category.title} />
      ),
      price: ({price, quantity}: any) => quantity * price + '€',
    };

    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'category',
              content: 'Category',
              isSortable: false,
              width: 25,
            },
            {
              key: 'title',
              content: 'Product',
              isSortable: false,
              width: 25,
            },
            {
              key: 'quantity',
              content: 'Quantity',
              isSortable: false,
              width: 25,
            },
            {
              key: 'price',
              content: 'Total',
              isSortable: false,
              width: 25,
            },
          ],
        }}
        dataTransformation={dataTransformation}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={(this.props.request.data.drinks || []).map(
          (drink: any) => ({
            id: drink.item.id,
            title: drink.item.title,
            quantity: drink.quantity,
            category: drink.category,
            price: Number(drink.item.price),
          }),
        )}
      />
    );
  }
}
