import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchDrinkItems,
  redirectAfterCreation,
  fetchDrinkItemFullFilled,
  fetchDrinkItemsFullFilled,
  fetchDrinkCategoryFullFilled,
  fetchDrinkCategories,
  fetchDrinkCategoriesFullFilled,
} from './actions';
import { DrinkCategory, DrinkItem } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchDrinkItemsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DRINK_ITEMS),
    mergeMap(() => {
      return from(Service.request(DrinkItem.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchDrinkItemsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createDrinkItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_DRINK_ITEM),
    mergeMap(({ payload }: any) => {
      return from(Service.request(DrinkItem.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Drink Item successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeDrinkItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_DRINK_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(DrinkItem.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchDrinkItems()),
            of(showNotification('Drink Item successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateDrinkItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_DRINK_ITEM),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(DrinkItem.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchDrinkItems()),
            of(showNotification('Drink Item successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchDrinkItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DRINK_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(DrinkItem.find, { id })).pipe(
        map((response: any) => fetchDrinkItemFullFilled(response)),
      );
    }),
  );
};

// Drink Categories
const fetchDrinkCategoriesEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DRINK_CATEGORIES),
    mergeMap(() => {
      return from(Service.request(DrinkCategory.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchDrinkCategoriesFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createDrinkCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_DRINK_CATEGORY),
    mergeMap(({ payload }: any) => {
      return from(Service.request(DrinkCategory.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Drink Category successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeDrinkCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_DRINK_CATEGORY),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(DrinkCategory.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchDrinkCategories()),
            of(showNotification('Drink Category successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateDrinkCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_DRINK_CATEGORY),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(DrinkCategory.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchDrinkCategories()),
            of(showNotification('Drink Category successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchDrinkCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DRINK_CATEGORY),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(DrinkCategory.find, { id })).pipe(
        map((response: any) => fetchDrinkCategoryFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchDrinkItemsEpic,
  fetchDrinkItemEpic,
  createDrinkItemEpic,
  removeDrinkItemEpic,
  updateDrinkItemEpic,

  // Drink Categories
  fetchDrinkCategoriesEpic,
  fetchDrinkCategoryEpic,
  createDrinkCategoryEpic,
  removeDrinkCategoryEpic,
  updateDrinkCategoryEpic,
};
