export const ActionType = {
  FETCH_REQUESTS: 'FETCH_REQUESTS',
  FETCH_REQUESTS_FULFILLED: 'FETCH_REQUESTS_FULFILLED',

  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_REQUEST_FULFILLED: 'FETCH_REQUEST_FULFILLED',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  MUTATE_FORM: 'MUTATE_FORM',
  ADD_REQUEST: 'ADD_REQUEST',
};
