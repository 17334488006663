import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as DrinkRoute } from 'context/Order/Drink/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import {
  TableHeadDefinition,
  TableRowDataDefinition,
  TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';

class DrinkItemPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchDrinkItems();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Drink Item',
        route: formatRoute(DrinkRoute.LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          isSortable: false,
          width: 25,
        },
        {
          key: 'description',
          content: 'Description',
          isSortable: false,
          width: 25,
        },
        {
          key: 'price',
          content: 'Price',
          isSortable: false,
          width: 25,
        },
        {
          key: 'category',
          content: 'Category',
          isSortable: false,
          width: 10,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      category: ({ category }: TableRowDataDefinition & { category: any }): any => category.title,
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Drink Item'
              head={head}
              data={this.props.items}
              dataTransformation={dataTransformation}
              showActions={true}
              creationRoute={DrinkRoute.CREATE}
              modificationRoute={(id: string): string => formatRoute(DrinkRoute.EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removeDrinkItem(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ orderDrink: { items } }: any) => {
  return {
    items,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchDrinkItems: () => dispatch({ type: ActionType.FETCH_DRINK_ITEMS }),
    removeDrinkItem: (id: string) => {
      dispatch({ type: ActionType.REMOVE_DRINK_ITEM, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrinkItemPageList);
