import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchSectionItems,
  redirectAfterCreation,
  fetchSectionItemFullFilled,
  fetchSectionItemsFullFilled, fetchSectionCategoryFullFilled, fetchSectionCategories, fetchSectionCategoriesFullFilled,
} from './actions';
import { SectionCategory, SectionItem } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchSectionItemsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SECTION_ITEMS),
    mergeMap(() => {
      return from(Service.request(SectionItem.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchSectionItemsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createSectionItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_SECTION_ITEM),
    mergeMap(({ payload }: any) => {
      return from(Service.request(SectionItem.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Section Item successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeSectionItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_SECTION_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(SectionItem.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchSectionItems()),
            of(showNotification('Section Item successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateSectionItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_SECTION_ITEM),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(SectionItem.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchSectionItems()),
            of(showNotification('Section Item successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchSectionItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SECTION_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(SectionItem.find, { id })).pipe(
        map((response: any) => fetchSectionItemFullFilled(response)),
      );
    }),
  );
};

// Section Categories
const fetchSectionCategoriesEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SECTION_CATEGORIES),
    mergeMap(() => {
      return from(Service.request(SectionCategory.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchSectionCategoriesFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createSectionCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_SECTION_CATEGORY),
    mergeMap(({ payload }: any) => {
      return from(Service.request(SectionCategory.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Section Category successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeSectionCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_SECTION_CATEGORY),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(SectionCategory.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchSectionCategories()),
            of(showNotification('Section Category successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateSectionCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_SECTION_CATEGORY),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(SectionCategory.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchSectionCategories()),
            of(showNotification('Section Category successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchSectionCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SECTION_CATEGORY),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(SectionCategory.find, { id })).pipe(
        map((response: any) => fetchSectionCategoryFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchSectionItemsEpic,
  fetchSectionItemEpic,
  createSectionItemEpic,
  removeSectionItemEpic,
  updateSectionItemEpic,

  // Section Categories
  fetchSectionCategoriesEpic,
  fetchSectionCategoryEpic,
  createSectionCategoryEpic,
  removeSectionCategoryEpic,
  updateSectionCategoryEpic,
};
