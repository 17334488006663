import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import { Redirect, withRouter } from 'react-router';
import FormFooter from 'context/Core/Component/Form/Footer';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import Select from '@atlaskit/select';

class PartnerItemForm extends Component<any, any> {
  static defaultProps = {
    name: '',
    email: '',
    phone: '',
    type: [],
  };

  componentDidMount(): void {
    this.props.fetchPartnerTypes();
  }

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={Route.PARTNER_LIST} />;
    }

    return (
      <section>
        <ButtonBack path={Route.PARTNER_LIST} titleBefore={'Partner Item Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'partner-item'} >

              <Field name={'name'} defaultValue={this.props.name} isRequired={true} label={'Partner Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Partner Name'} {...fieldProps} />}
              </Field>

              <Field name={'email'} defaultValue={this.props.email} isRequired={true} label={'Partner Email'}>
                {({ fieldProps }: { fieldProps: any }) =>
                  <TextField type={'email'} placeholder={'Partner Email'} {...fieldProps} />}
              </Field>

              <Field name={'phone'} defaultValue={this.props.phone} isRequired={true} label={'Partner Phone'}>
                {({ fieldProps }: { fieldProps: any }) =>
                  <TextField placeholder={'Partner Phone'} {...fieldProps} />}
              </Field>

              <Field
                name={'type'}
                defaultValue={
                  this.props.partnerTypes.filter(
                    (option: any) => this.props.type && this.props.type.includes(option.value),
                  )
                }
                isRequired={false}
                label={'Select Types'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <Select
                    isMulti
                    className={'multi-select'}
                    classNamePrefix={'react-select'}
                    options={this.props.partnerTypes}
                    placeholder={'Choose a partner type'}
                    {...fieldProps}
                  />
                )}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ partner: { partnerTypes, shouldRedirect } }: any) => {
  return {
    shouldRedirect,
    partnerTypes: partnerTypes.map(({ id, name }: any) => {
      return { label: name, value: id };
    }),
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_PARTNER_ITEM : ActionType.CREATE_PARTNER_ITEM;
      formData.type = formData.type.map(({ value }: { value: string } )  => value);
      dispatch({ type, payload: { ...formData, id } });
    },

    fetchPartnerTypes: () => dispatch({ type: ActionType.FETCH_PARTNER_TYPES }),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartnerItemForm));
