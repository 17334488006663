import React from 'react';
import { H4 } from './Accommodation';
import ModuleTable from '../../../../Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from '../../../../Core/Component/Layout/Module/Table/types';

class ReservationRequest extends React.Component<any, any> {
  render() {
    const {
      name,
      phonePrefix,
      email,
      phoneNumber,
      notes,
      reservationType,
      where,
      answers,
    } = this.props.request.data;

    const dataTransformation: TableRowDataTransformation = {
      reservationType: ({ reservationType }: any) => reservationType === 'known' ? (
        <>
          <H4>Where:</H4> { where } <br/>
        </>
      ) : (
        <>
          <H4>Answers:</H4> { answers } <br/>
        </>
      ),
    };

    return (
      <>
        <ModuleTable
          head={{
            cells: [
              {
                key: 'id',
                content: '#',
                isSortable: true,
                width: 5,
              },
              {
                key: 'name',
                content: 'Name',
                isSortable: false,
                width: 25,
              },
              {
                key: 'phoneNumber',
                content: 'Phone',
                isSortable: false,
                width: 25,
              },
              {
                key: 'email',
                content: 'Email',
                isSortable: false,
                width: 30,
              },
              {
                key: 'reservationType',
                content: 'Type',
                isSortable: false,
                width: 25,
              },
              {
                key: 'notes',
                content: 'Notes',
                isSortable: false,
                width: 30,
              },
            ],
          }}
          dataTransformation={dataTransformation}
          showActions={false}
          showTableTop={[]}
          rowsPerPage={[]}
          data={[{
            id: this.props.request.id,
            name,
            phonePrefix,
            email,
            phoneNumber,
            notes,
            reservationType,
            where,
            answers,
          }]}
        />
      </>
    );
  }
}

export default ReservationRequest;
