import React from 'react';
import { Flipper } from 'react-flip-toolkit';
import { Box } from '@material-ui/core';
import update from 'immutability-helper';
import Button from '@atlaskit/button';

import Sortly, { ID, ItemData, add, remove, insert } from 'react-sortly';
import ItemRenderer from './ItemRenderer';
import AddItem from '@atlaskit/icon/glyph/add-item';
import CheckIcon from '@atlaskit/icon/glyph/check';

interface Item {
  name: string;
  isNew?: boolean;
  depth?: boolean;
}

const TreeRenderer = ({ reservation: data, onUpdate }: any) => {
  const [items, setItems] = React.useState(data);

  React.useEffect(() => { setItems(data); }, [data]);

  const handleChange = (newItems: Array<ItemData<Item>>) => {
    setItems(newItems);
  };

  const handleChangeName = (id: ID, name: string) => {
    const index = items.findIndex((item: any) => item.id === id);
    setItems(update(items, {
      [index]: { name: { $set: name } },
    }));
  };

  const handleDelete = (id: ID) => {
    const index = items.findIndex((item: any) => item.id === id);
    setItems(remove(items, index));
  };

  const handleClickAdd = () => {
    setItems(add(items, {
      id: Date.now(),
      // @ts-ignore
      name: 'Where would you like to go ?',
      isNew: true,
    }));
  };

  const handleReturn = (id: ID) => {
    const index = items.findIndex((item: any) => item.id === id);
    setItems(insert(items, {
      id: Date.now(),
      // @ts-ignore
      name: 'Where would you like to go ?',
      isNew: true,
    }, index));
  };

  return (
    <Box width={{ md: 600 }}>
      {
        items && items.length > 0 ?
          <Flipper
            flipKey={items.map(({ id }: any) => id).join('.')}
          >
            <Sortly
              items={items}
              onChange={handleChange}
            >
              {(props) => (
                <ItemRenderer
                  {...props}
                  onChangeName={handleChangeName}
                  onDelete={handleDelete}
                  onReturn={handleReturn}
                />
              )}
            </Sortly>
          </Flipper>
        : ''
      }
      <Box mt={4}>
        <div style={{ display: 'flex'}}>
          <Button
            appearance='success'
            iconBefore={<AddItem label='Add' size='small' />}
            onClick={handleClickAdd}
          >
            Add
          </Button>

          <div style={{ marginLeft: 15 }}>
            <Button
              appearance='primary'
              iconBefore={<CheckIcon label='Save' size='small' />}
              onClick={() => onUpdate(items)}
            >
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default TreeRenderer;
