import { ActionType } from './types';

export function fetchRequests(payload: any) {
  return {
    type: ActionType.FETCH_MAP_REQUESTS,
    payload,
  };
}

export function fetchRequestsFulFilled(requests: [] = []) {
  return {
    type: ActionType.FETCH_MAP_REQUESTS_FULFILLED,
    requests,
  };
}

export function modifyRequest(id: string, payload: any) {
  return {
    type: ActionType.MODIFY_REQUEST,
    id,
    payload,
  };
}

export function showForm(show: boolean = true) {
  return {
    type: ActionType.SHOW_FORM,
    show,
  };
}

export function mutateForm(key: string, value: any) {
  return {
    type: ActionType.MUTATE_FORM,
    key,
    value,
  };
}

export function fetchMapRequestAction(id: string) {
  return {
    type: ActionType.FETCH_MAP_REQUEST,
    id,
  };
}

export function fetchRequestFulFilled(request: {} = {}) {
  return {
    type: ActionType.FETCH_MAP_REQUEST_FULFILLED,
    request,
  };
}
