import React, { Component } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';

export default class TabContact extends Component<any, any> {
  render() {
    const dataTransformation: TableRowDataTransformation = {
      phoneNumber: ({ phonePrefix, phoneNumber }: any) => phonePrefix + phoneNumber,
    };

    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'name',
              content: 'Name',
              isSortable: false,
              width: 25,
            },
            {
              key: 'phoneNumber',
              content: 'Phone',
              isSortable: false,
              width: 25,
            },
            {
              key: 'email',
              content: 'email',
              isSortable: false,
              width: 30,
            },
            {
              key: 'zone',
              content: 'Zone',
              isSortable: false,
              width: 25,
            },
            {
              key: 'notes',
              content: 'Notes',
              isSortable: false,
              width: 30,
            },
          ],
        }}
        dataTransformation={dataTransformation}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={[{
          id: this.props.request.id,
          name: this.props.request.data.name,
          phoneNumber: this.props.request.data.phoneNumber,
          phonePrefix: this.props.request.data.phonePrefix,
          email: this.props.request.data.email,
          zone: this.props.request.data.zone,
          notes: this.props.request.data.notes,
        }]}
      />
    );
  }
}
