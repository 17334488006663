import { ActionType } from './types';

export function fetchReservation() {
  return {
    type: ActionType.FETCH_RESERVATION,
  };
}

export function fetchReservationFulFilled(reservation: object) {
  return {
    type: ActionType.FETCH_RESERVATION_FULFILLED,
    reservation,
  };
}

export function updateReservation(data: object) {
  return {
    type: ActionType.FETCH_RESERVATION,
    data,
  };
}
