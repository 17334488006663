export const ActionType = {
  FETCH_RENTING_TYPES: 'FETCH_RENTING_TYPES',
  FETCH_RENTING_TYPES_FULFILLED: 'FETCH_RENTING_TYPES_FULFILLED',
  FETCH_RENTING_TYPE_FULFILLED: 'FETCH_RENTING_TYPE_FULFILLED',
  FETCH_RENTING_TYPE: 'FETCH_RENTING_TYPE',
  CREATE_RENTING_TYPE: 'CREATE_RENTING_TYPE',
  UPDATE_RENTING_TYPE: 'UPDATE_RENTING_TYPE',
  REMOVE_RENTING_TYPE: 'REMOVE_RENTING_TYPE',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',

  FETCH_RENTING_SUBTYPES: 'FETCH_RENTING_SUBTYPES',
  FETCH_RENTING_SUBTYPES_FULFILLED: 'FETCH_RENTING_SUBTYPES_FULFILLED',
  FETCH_RENTING_SUBTYPE_FULFILLED: 'FETCH_RENTING_SUBTYPE_FULFILLED',
  FETCH_RENTING_SUBTYPE: 'FETCH_RENTING_SUBTYPE',
  CREATE_RENTING_SUBTYPE: 'CREATE_RENTING_SUBTYPE',
  UPDATE_RENTING_SUBTYPE: 'UPDATE_RENTING_SUBTYPE',
  REMOVE_RENTING_SUBTYPE: 'REMOVE_RENTING_SUBTYPE',
};
