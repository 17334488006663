import React from 'react';
import moment from 'moment';
import ModuleTable from '../../../../Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from '../../../../Core/Component/Layout/Module/Table/types';

class TripPlanningRequest extends React.Component<any, any> {
  render() {
    const {
      name,
      phonePrefix,
      email,
      phoneNumber,
      startDate,
      endDate,
      numberOfPeople,
      answers,
      notes,
    } = this.props.request.data;

    const dataTransformation: TableRowDataTransformation = {
      startDate: ({ startDate }: any) => moment(startDate).format('D MMMM YYYY'),
      endDate: ({ endDate }: any) => moment(endDate).format('D MMMM YYYY'),
      phoneNumber: ({ phonePrefix, phoneNumber }: any) => phonePrefix + phoneNumber,
      answers: ({ answers }: any) => answers.length > 0 ? answers.join(', ') : '',
    };

    return (
      <>
        <ModuleTable
          head={{
            cells: [
              {
                key: 'id',
                content: '#',
                isSortable: true,
                width: 5,
              },
              {
                key: 'name',
                content: 'Name',
                isSortable: false,
                width: 25,
              },
              {
                key: 'answers',
                content: 'Answers',
                isSortable: false,
                width: 25,
              },
              {
                key: 'phoneNumber',
                content: 'Phone',
                isSortable: false,
                width: 25,
              },
              {
                key: 'email',
                content: 'Email',
                isSortable: false,
                width: 30,
              },
              {
                key: 'numberOfPeople',
                content: 'Num of people',
                isSortable: false,
                width: 25,
              },
              {
                key: 'startDate',
                content: 'Start Date',
                isSortable: false,
                width: 30,
              },
              {
                key: 'endDate',
                content: 'End Date',
                isSortable: false,
                width: 30,
              },
              {
                key: 'notes',
                content: 'Notes',
                isSortable: false,
                width: 30,
              },
            ],
          }}
          dataTransformation={dataTransformation}
          showActions={false}
          showTableTop={[]}
          rowsPerPage={[]}
          data={[{
            id: this.props.request.id,
            name,
            phonePrefix,
            email,
            phoneNumber,
            startDate,
            endDate,
            numberOfPeople,
            answers,
            notes,
          }]}
        />
      </>
    );
  }
}

export default TripPlanningRequest;
