export const ActionType = {
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',

  // Items
  FETCH_SERVICE_ITEMS: 'FETCH_SERVICE_ITEMS',
  FETCH_SERVICE_ITEMS_FULFILLED: 'FETCH_SERVICE_ITEMS_FULFILLED',
  FETCH_SERVICE_ITEM_FULFILLED: 'FETCH_SERVICE_ITEM_FULFILLED',
  FETCH_SERVICE_ITEM: 'FETCH_SERVICE_ITEM',
  CREATE_SERVICE_ITEM: 'CREATE_SERVICE_ITEM',
  UPDATE_SERVICE_ITEM: 'UPDATE_SERVICE_ITEM',
  REMOVE_SERVICE_ITEM: 'REMOVE_SERVICE_ITEM',
};
