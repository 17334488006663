export const ActionType = {
  FETCH_COURIERS: 'FETCH_COURIERS',
  FETCH_COURIERS_FULFILLED: 'FETCH_COURIERS_FULFILLED',
  FETCH_COURIER_FULFILLED: 'FETCH_COURIER_FULFILLED',
  FETCH_COURIER: 'FETCH_COURIER',
  CREATE_COURIER: 'CREATE_COURIER',
  UPDATE_COURIER: 'UPDATE_COURIER',
  REMOVE_COURIER: 'REMOVE_COURIER',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
