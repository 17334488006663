import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import moment from 'moment';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as Routes } from 'context/Order/Center/Router/types';
import { BreadcrumbDefinition } from 'context/Core/types';
import Map from '../Component/Map';
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import Form, { Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import _ from 'lodash';
import Tabs from '@atlaskit/tabs';
import { ActionType } from '../Store/types';
import { ActionType as RestaurantActionTypes } from 'context/Order/Food/Store/types';
import TabFood from '../Component/Tab/Food';
import TabGift from '../Component/Tab/Gifts';
import TabDrink from '../Component/Tab/Drinks';
import TabContact from '../Component/Tab/Contact';
import TabBill from '../Component/Tab/Bill';
import TabPayment from '../Component/Tab/Payment';
import CheckIcon from '@atlaskit/icon/glyph/check';
import RemoveIcon from '@atlaskit/icon/glyph/trash';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import RecentIcon from '@atlaskit/icon/glyph/recent';
import MarketTabPayment from '../Component/Tab/Market/Payment';
import MarketTabBill from '../Component/Tab/Market/Bill';
import MarketTabContact from '../Component/Tab/Market/Contact';
import MarketTabProducts from '../Component/Tab/Market/Product';
import TextField from '@atlaskit/textfield';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { ButtonWrapper } from '../../../Core/Component/Layout/Module/Table';
import { Label } from '@atlaskit/field-base';
import { DatePicker } from '@atlaskit/datetime-picker';
import { DatepickerControlled } from '../../../Core/Component/Form/DatepickerControlled';
import TextArea from '@atlaskit/textarea';
import Toggle from '@atlaskit/toggle';
import { withRouter } from 'react-router';
import RemoveModal from '../../../Core/Component/Modal/RemoveModal';

const MapWrapper = styled.div`
  color: rgb(23, 43, 77);
  border: 1px solid rgb(223, 225, 230);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  margin-top: 15px;
  padding: 15px 15px 15px;
  position: relative;
`;

let tabs = [
  { label: 'Food', content: '' },
  { label: 'Gifts', content: '' },
  { label: 'Drinks', content: '' },
  { label: 'Contact', content: '' },
  { label: 'Bill', content: '' },
  { label: 'Payment', content: '' },
];

export const selectRequestTypes = [
  { label: 'All', value: 'all' },
  { label: 'Cart', value: 'cart' },
  { label: 'Market', value: 'market' },
  { label: 'Custom', value: 'custom' },
];

export const zoneSelectTypes = [
  { label: 'All', value: 'all' },
  { label: 'First', value: 1 },
  { label: 'Second', value: 2 },
  { label: 'Third', value: 3 },
];

export const orderRequestStatuses = [
  'deleted',
  'ordered',
  'assigned',
  'waiting',
  'denied',
  'waiting-approved',
  'waiting-denied',
  'delivering',
  'delivered',
  'cancelled',
];

class Root extends Component<any, any> {
  constructor(props: any) {
    super(props);

    if (!Object.keys(props.socket._callbacks).includes('$new-order')) {
      // tslint:disable-next-line:only-arrow-functions
      props.socket.on('new-order', function({ payload }: any) {
        props.addOrder(payload);
      });
    }

    this.onClick = this.onClick.bind(this);
    this.showModal = this.showModal.bind(this);
    this.deliveryTime = this.deliveryTime.bind(this);
    this.totalMarketAmount = this.totalMarketAmount.bind(this);
    this.openModal = this.openModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = {
      showModal: false,
      deliveryTime: 45,
      marketAmount: 0,
      isModalOpen: false,
      removalId: '',
    };
  }

  async componentDidMount() {
    if (this.props.match.params.hasOwnProperty('id')) {
      await this.props.mutateForm('id', this.props.match.params.id);
    }

    this.props.fetchRequests(this.props.form);
    this.props.fetchRestaurantItems();
  }

  componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    if (nextProps.hasOwnProperty('request') && !_.isEmpty(nextProps.request)) {
      this.deliveryTime(nextProps.request.deliveryTime);
      this.totalMarketAmount(nextProps.request.marketAmount);
    }
  }

  hideModal() {
    this.setState({
      isModalOpen: false,
      removalId: '',
    });
  }

  openModal(id: string) {
    this.setState({
      isModalOpen: true,
      removalId: id,
    });
  }

  removeRequest() {
    this.props.modifyRequest(
      this.state.removalId, {
        action: 'REMOVE',
        deliveryTime: this.state.deliveryTime,
      },
    );

    this.showModal();
    this.hideModal();
  }

  onClick(requestId: string) {
    this.props.fetchRequest(requestId);
    this.showModal();
  }

  totalMarketAmount(amount: any) {
    this.setState({
      ...this.state,
      marketAmount: amount,
    });
  }

  deliveryTime(time: any) {
    this.setState({
      ...this.state,
      deliveryTime: time,
    });
  }

  showModal() {
    this.setState({
      ...this.state,
      showModal: !this.state.showModal,
    });
  }

  render() {
    if (this.props.request.hasOwnProperty('type')) {
        tabs = this.props.request.type === 'market'
          ? tabs.filter((tab: any) => !['Gifts', 'Drinks'].includes(tab.label))
          : tabs;
    }

    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Ordering Map',
        route: formatRoute(Routes.ORDER_CENTER),
      },
    ];

    const hideActionableButton = [
      'waiting',
      'assigned',
      'denied',
      'waiting-denied',
      'delivering',
      'delivered',
      'cancelled',
    ].includes(this.props.request.status);

    return (
      <LayoutWrapper>
        <RemoveModal
          isOpen={this.state.isModalOpen}
          hide={() => this.hideModal()}
          onRemove={() => this.removeRequest()}
        />
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <ButtonWrapper>
          <Button
            appearance='primary'
            iconBefore={<SearchIcon label='Show Form' size='small' />}
            onClick={() => this.props.triggerForm(!this.props.showForm)}
          >
            { this.props.showForm ? 'Hide Form' : 'Show Form' }
          </Button>
        </ButtonWrapper>

        <div style={{ display: 'flex', width: '100%' }}>
          <MapWrapper style={{ width: this.props.showForm ? '70%' : '100%' }}>
            <Map requests={this.props.requests} onMarkerClick={this.onClick} showOverlay={this.props.form.overlay} />
            {
              this.state.showModal && <ModalTransition>
                <ModalDialog
                  heading={'Order Request'}
                  width={'x-large'}
                  components={{
                    Container: ({ children, className }: any) => (
                      <Form onSubmit={() => undefined}>
                        {({ formProps }: any) => (
                          <form {...formProps} className={className}>
                            {children}
                          </form>
                        )}
                      </Form>
                    ),
                    Footer: (props: any) => (
                      <ModalFooter showKeyline={props.showKeyline} style={{ marginTop: 35 }}>
                        <span />
                        <div style={{ display: 'flex' }}>
                          {
                            !hideActionableButton && <div style={{ marginLeft: 15 }}>
                              <Button
                                appearance={'primary'} type={'submit'}
                                iconBefore={<CheckIcon label='Approve' size='small'/>}
                                onClick={() => this.props.modifyRequest(
                                  this.props.request.id, {
                                    action: 'APPROVE',
                                    deliveryTime: this.state.deliveryTime,
                                    marketAmount: this.state.marketAmount,
                                  },
                                )}
                              >
                                Approve
                              </Button>
                            </div>
                          }
                          {
                            !hideActionableButton && this.props.request.status !== 'waiting-approved'
                            && <div style={{ marginLeft: 15 }}>
                              <Button
                                appearance={'primary'} type={'submit'}
                                iconBefore={<RecentIcon primaryColor={'#fff'} label='Wait' size='small'/>}
                                onClick={() => this.props.modifyRequest(
                                  this.props.request.id, {
                                    action: 'WAIT',
                                    deliveryTime: this.state.deliveryTime,
                                    marketAmount: this.state.marketAmount,
                                  },
                                )}
                              >
                                Wait
                              </Button>
                            </div>
                          }
                          {
                            !hideActionableButton && <div style={{ marginLeft: 15 }}>
                              <Button
                                appearance={'primary'} type={'submit'}
                                iconBefore={<CrossIcon label='Deny' size='small'/>}
                                onClick={() => this.props.modifyRequest(
                                  this.props.request.id, {
                                    action: 'DENY',
                                    deliveryTime: this.state.deliveryTime,
                                  },
                                )}
                              >
                                Deny
                              </Button>
                            </div>
                          }
                          {
                            this.props.request.deleted !== true && <div style={{ marginLeft: 15 }}>
                              <Button
                                appearance={'danger'} type={'submit'}
                                iconBefore={<RemoveIcon label='Remove' size='small'/>}
                                onClick={() => this.openModal(this.props.request.id)}>
                                Remove
                              </Button>
                            </div>
                          }
                          <div style={{ marginLeft: 15 }}>
                            <Button appearance={'default'} onClick={() => this.showModal()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </ModalFooter>
                    ),
                  }}
                >
                  {
                    this.props.request.hasOwnProperty('type') ? (
                      <>
                        <Tabs tabs={tabs.map((tab: any) => {
                          if (this.props.request.type === 'custom' && tab.label === 'Payment') {
                            return tab.content = '';
                          }

                          if (this.props.request.type === 'market') {
                            if (tab.label === 'Food') {
                              tab.label = 'Products';
                              tab.content = <MarketTabProducts key={tab.label} request={this.props.request} />;
                              return tab;
                            }

                            if (tab.label === 'Contact') {
                              tab.content = <MarketTabContact key={tab.label} request={this.props.request} />;
                              return tab;
                            }

                            if (tab.label === 'Bill') {
                              tab.content = <MarketTabBill key={tab.label} request={this.props.request} />;
                              return tab;
                            }

                            if (tab.label === 'Payment') {
                              tab.content = <MarketTabPayment key={tab.label} request={this.props.request} />;
                              return tab;
                            }

                            return tab;
                          }

                          if (tab.label === 'Food') {
                            tab.content = <TabFood key={tab.label} request={this.props.request} />;
                          }

                          if (tab.label === 'Gifts') {
                            tab.content = <TabGift key={tab.label} request={this.props.request} />;
                          }

                          if (tab.label === 'Drinks') {
                            tab.content = <TabDrink key={tab.label} request={this.props.request} />;
                          }

                          if (tab.label === 'Contact') {
                            tab.content = <TabContact key={tab.label} request={this.props.request} />;
                          }

                          if (tab.label === 'Bill') {
                            tab.content = <TabBill key={tab.label} request={this.props.request} />;
                          }

                          if (tab.label === 'Payment') {
                            tab.content = <TabPayment key={tab.label} request={this.props.request} />;
                          }

                          return tab;
                        })} />
                      </>
                    ) : (<></>)
                  }
                  {
                    this.props.request.hasOwnProperty('type') ? (
                      <>
                        <div style={{ padding: 10, marginTop: -15 }}>
                          <Field
                            name={'description'}
                            defaultValue={this.props.request.data.notes}
                            isDisabled={true}
                            label={'Customer Notes'}
                          >
                            {({ fieldProps }: { fieldProps: any }) =>
                              <TextArea placeholder={'Customer Notes'} {...fieldProps} />}
                          </Field>
                        </div>
                      </>
                    ) : (<></>)
                  }
                  {
                    this.props.request.type === 'market' && <div style={{ padding: 10, marginTop: -15 }}>
                      <Field
                        name={'totalMarketAmount'}
                        type={'number'}
                        label={'Total Amount'}
                        isDisabled={hideActionableButton}
                        defaultValue={this.props.request.marketAmount}
                      >
                        {
                          ({ fieldProps }: { fieldProps: any }) => <TextField
                            placeholder={'Market Amount'}
                            {...fieldProps}
                            onChange={({ currentTarget: { value } }: any) => this.totalMarketAmount(value)}
                            value={this.state.marketAmount}
                          />
                        }
                      </Field>
                    </div>
                  }
                  {
                    <div style={{ padding: 10, marginTop: -15 }} key={'deliveryTimeKey'}>
                      <Field
                        name={'deliveryTime'}
                        type={'number'}
                        label={'Delivery Time/Wait'}
                        isDisabled={hideActionableButton}
                        defaultValue={this.props.request.deliveryTime}
                      >
                        {
                          ({ fieldProps }: { fieldProps: any }) => <TextField
                            placeholder={'45'}
                            {...fieldProps}
                            onChange={({ target: { value } }: any) => this.deliveryTime(value)}
                            value={this.state.deliveryTime}
                          />
                        }
                      </Field>
                    </div>
                  }
                  {
                    <div style={{ padding: 10, marginTop: -15 }}>
                      <Field
                        name={'status'}
                        defaultValue={(orderRequestStatuses.map((status: string): any => ({
                          label: status.toUpperCase(), value: status,
                        }))).find((item: any) => this.props.request.status === item.value)}
                        isRequired={false}
                        isDisabled={true}
                        label={'Current Order Status'}
                      >
                        {({ fieldProps }: { fieldProps: any }) => (
                          <Select
                            disabled={true}
                            className={'single-select'}
                            classNamePrefix={'react-select'}
                            options={
                              orderRequestStatuses.map((status: string) => ({
                                label: status.toUpperCase(), value: status,
                              }))
                            }
                            placeholder={'Current Order Status'}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                  }
                </ModalDialog>
              </ModalTransition>
            }
          </MapWrapper>
          {
            this.props.showForm && <MapWrapper style={{ marginLeft: 15, width: '30%' }}>
              <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                <div style={{ width: '50%'}}>
                  <Label label={'From'} />
                  <DatepickerControlled initialValue={moment(Date.now()).subtract(1, 'days').toString()}>
                    {({ value, onValueChange, onBlur }) => (
                      <DatePicker
                        value={value}
                        onChange={(val: any) => {
                          this.props.mutateForm('from', val);
                          onValueChange(val);
                        }}
                        onBlur={onBlur}
                      />
                    )}
                  </DatepickerControlled>
                </div>
                <div style={{ width: '45%', marginLeft: 15 }}>
                  <Label label={'To'} />
                  <DatepickerControlled initialValue={moment(Date.now()).add(1, 'days').toString()}>
                    {({ value, onValueChange, onBlur }) => (
                      <DatePicker
                        value={value}
                        onChange={(val: any) => {
                          this.props.mutateForm('to', val);
                          onValueChange(val);
                        }}
                        onBlur={onBlur}
                      />
                    )}
                  </DatepickerControlled>
                </div>

                <div style={{ width: '50%' }}>
                  <Field
                    name={'status'}
                    label={'Status'}
                  >
                    {({ fieldProps }: { fieldProps: any }) => (
                      <Select
                        defaultValue={{ label: 'All', value: 'all' }}
                        classNamePrefix={'react-select'}
                        options={orderRequestStatuses.concat(['all']).map((status: string) => ({
                          label: status.toUpperCase(), value: status,
                        }))}
                        placeholder={'Status'}
                        {...fieldProps}
                        onChange={(val: any) => {
                          fieldProps.onChange(val.value);
                          this.props.mutateForm('status', val.value);
                        }}
                        value={
                          orderRequestStatuses.concat(['all']).map((status: string) => ({
                            label: status.toUpperCase(), value: status,
                          })).find((item: any) => item.value === this.props.form.status) ||
                          { label: 'All', value: 'all' }
                        }
                      />
                    )}
                  </Field>
                </div>
                <div style={{ width: '45%', marginLeft: 15 }}>
                  <Field
                    name={'type'}
                    label={'Type'}
                  >
                    {({ fieldProps }: { fieldProps: any }) => (
                      <Select
                        defaultValue={{ label: 'All', value: 'all' }}
                        classNamePrefix={'react-select'}
                        options={selectRequestTypes}
                        placeholder={'Type'}
                        {...fieldProps}
                        onChange={(val: any) => {
                          fieldProps.onChange(val.value);
                          this.props.mutateForm('type', val.value);
                        }}
                        value={
                          selectRequestTypes.find((item: any) => item.value === this.props.form.type) ||
                          { label: 'All', value: 'all' }
                        }
                      />
                    )}
                  </Field>
                </div>

                <div style={{ width: '100%' }}>
                  <Field
                    defaultValue={{ label: 'All', value: 'all' }}
                    name={'zone'}
                    label={'Zone'}
                  >
                    {({ fieldProps }: { fieldProps: any }) => (
                      <Select
                        classNamePrefix={'react-select'}
                        options={zoneSelectTypes}
                        placeholder={'Zone'}
                        {...fieldProps}
                        onChange={(val: any) => {
                          fieldProps.onChange(val.value);
                          this.props.mutateForm('zone', val.value);
                        }}
                        value={
                          zoneSelectTypes.find((item: any) => item.value === this.props.form.zone) ||
                          { label: 'All', value: 'all' }
                        }
                      />
                    )}
                  </Field>
                </div>

                <div style={{ width: '100%' }}>
                  <Field
                    defaultValue={{ label: 'All', value: 'all' }}
                    name={'restaurant'}
                    label={'Restaurant'}
                  >
                    {({ fieldProps }: { fieldProps: any }) => (
                      <Select
                        classNamePrefix={'react-select'}
                        options={
                          this.props.restaurantItems.concat([{ id: 'all', name: 'All' }]).map(
                            (restaurant: any) => ({ value: restaurant.id, label: restaurant.name }),
                          )
                        }
                        placeholder={'Restaurant'}
                        {...fieldProps}
                        onChange={(val: any) => {
                          fieldProps.onChange(val.value);
                          this.props.mutateForm('restaurant', val.value);
                        }}
                        value={
                          this.props.restaurantItems.concat([{ id: 'all', name: 'All' }]).map(
                            (restaurant: any) => ({ value: restaurant.id, label: restaurant.name }),
                          ).find((item: any) => item.value === this.props.form.restaurant) ||
                          { label: 'All', value: 'all' }
                        }
                      />
                    )}
                  </Field>
                </div>

                <div style={{ width: '100%' }}>
                  <Field
                    name={'zoneOverlay'}
                    label={'Show Zone Overlay'}
                  >
                    {({ fieldProps }: { fieldProps: any }) => (
                      <div style={{ float: 'right' }}>
                        <Toggle
                          size={'large'}
                          isDefaultChecked
                          {...fieldProps}
                          onChange={(event: any) => {
                            fieldProps.onChange(event.currentTarget.checked);
                            this.props.mutateForm('overlay', event.currentTarget.checked);
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                  <Button
                    appearance={'primary'}
                    iconBefore={<SearchIcon label={'Search'} size={'small'} />}
                    onClick={async () => {
                      await this.props.mutateForm('id', null);
                      this.props.history.push(formatRoute(Routes.ORDER_CENTER, { id: null }));
                      this.props.fetchRequests(this.props.form);
                    }}
                    style={{ width: '100%' }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </MapWrapper>
          }
        </div>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({
  orderFood: { items },
  orderCenter: {
    requests,
    request,
    showForm,
    form,
  },
  core: { socket },
}: any,
) => {
  return {
    requests,
    request,
    showForm,
    form,
    restaurantItems: items,
    socket,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRequests: (payload: any) => dispatch({ type: ActionType.FETCH_MAP_REQUESTS, payload }),
    fetchRequest: (id: string) => dispatch({ type: ActionType.FETCH_MAP_REQUEST, id }),
    triggerForm: (show: boolean) => dispatch({ type: ActionType.SHOW_FORM, show }),
    modifyRequest: (id: string, payload: any) => dispatch({ type: ActionType.MODIFY_REQUEST, id, payload }),
    mutateForm: (key: string, value: any) => dispatch({ type: ActionType.MUTATE_FORM, key, value }),
    fetchRestaurantItems: () => dispatch({ type: RestaurantActionTypes.FETCH_RESTAURANT_ITEMS }),
    addOrder: (payload: any) => dispatch({ type: ActionType.ADD_ORDER, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Root));
