import { ActionType } from './types';

export function fetchDrinkItems() {
  return {
    type: ActionType.FETCH_DRINK_ITEMS,
  };
}

export function fetchDrinkItemsFullFilled(items: [] = []) {
  return {
    type: ActionType.FETCH_DRINK_ITEMS_FULFILLED,
    items,
  };
}

export function fetchDrinkItemFullFilled(currentDrinkItem: object) {
  return {
    type: ActionType.FETCH_DRINK_ITEM_FULFILLED,
    currentDrinkItem,
  };
}

export function fetchDrinkItem(id: string) {
  return {
    type: ActionType.FETCH_DRINK_ITEM,
    id,
  };
}

export function updateDrinkItem(id: string, data: object) {
  return {
    type: ActionType.UPDATE_DRINK_ITEM,
    id,
    data,
  };
}

export function removeDrinkItem(id: string) {
  return {
    type: ActionType.REMOVE_DRINK_ITEM,
    id,
  };
}

// Drink Categories
export function fetchDrinkCategories() {
  return {
    type: ActionType.FETCH_DRINK_CATEGORIES,
  };
}

export function fetchDrinkCategoriesFullFilled(drinkCategories: [] = []) {
  return {
    type: ActionType.FETCH_DRINK_CATEGORIES_FULFILLED,
    drinkCategories,
  };
}

export function fetchDrinkCategoryFullFilled(currentDrinkCategory: object) {
  return {
    type: ActionType.FETCH_DRINK_CATEGORY_FULFILLED,
    currentDrinkCategory,
  };
}

export function fetchDrinkCategory(id: string) {
  return {
    type: ActionType.FETCH_DRINK_CATEGORY,
    id,
  };
}

export function updateDrinkCategory(id: string, data: object) {
  return {
    type: ActionType.UPDATE_DRINK_CATEGORY,
    id,
    data,
  };
}

export function removeDrinkCategory(id: string) {
  return {
    type: ActionType.REMOVE_DRINK_CATEGORY,
    id,
  };
}

export function createDrinkCategory(data: object) {
  return {
    type: ActionType.CREATE_DRINK_CATEGORY,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}
