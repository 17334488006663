import React from 'react';
import styled from 'styled-components';
import Task from './Task';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: rgb(235, 236, 240);
  transition: background-color 0.2s ease 0s;
`;

const Title = styled.h4`
  -webkit-box-flex: 1;
  flex-grow: 1;
  user-select: none;
  position: relative;
  padding: 8px;
  transition: background-color 0.2s ease 0s;
`;

const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  opacity: inherit;
  user-select: none;
  padding: 8px 8px 0px;
  border-width: 8px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
  min-height: 100%;
  background-color: ${(props: any) =>
  props.isDraggingOver ? '#162a4db8' : 'rgb(235, 236, 240)'}
`;

class Column extends React.Component<any, any> {
  render() {
    return (
      <Container>
        <TitleWrapper>
          <Title>
            {this.props.column.title}
          </Title>
        </TitleWrapper>
        <Droppable droppableId={this.props.column.id} type={'TASK'}>
          {(provided: any, snapshot: any) => (
            <TaskList
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {this.props.requests.map((request: any, index: any) => (
                <Task
                  key={request.id}
                  request={request}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </Container>
    );
  }
}

export default connect(null, null)(Column);
