import withMetadata from 'util/withMetadata';
import { Route } from './types';
import { AppRouteDefinition } from 'context/Core/types';

import RestaurantItemPageList from 'context/Order/Food/Page/Restaurant/List';
import RestaurantItemPageCreate from 'context/Order/Food/Page/Restaurant/Create';
import RestaurantItemPageEdit from 'context/Order/Food/Page/Restaurant/Edit';

import RestaurantCategoryPageList from 'context/Order/Food/Page/RestaurantCategory/List';
import RestaurantCategoryPageCreate from 'context/Order/Food/Page/RestaurantCategory/Create';
import RestaurantCategoryPageEdit from 'context/Order/Food/Page/RestaurantCategory/Edit';

import RestaurantMenuPageList from 'context/Order/Food/Page/RestaurantMenu/List';
import RestaurantMenuPageEdit from 'context/Order/Food/Page/RestaurantMenu/Edit';
import RestaurantMenuPageCreate from 'context/Order/Food/Page/RestaurantMenu/Create';

import RestaurantMenuProductPageList from 'context/Order/Food/Page/RestaurantMenuProduct/List';
import RestaurantMenuProductPageEdit from 'context/Order/Food/Page/RestaurantMenuProduct/Edit';
import RestaurantMenuProductPageCreate from 'context/Order/Food/Page/RestaurantMenuProduct/Create';

const routes: AppRouteDefinition[] = [
  {
    path: Route.LIST,
    Component: withMetadata(RestaurantItemPageList, {
      title: 'Restaurant Item',
    }),
    guarded: true,
  },
  {
    path: Route.CREATE,
    Component: withMetadata(RestaurantItemPageCreate, {
      title: 'Create Restaurant Item',
    }),
    guarded: true,
  },
  {
    path: Route.EDIT,
    Component: withMetadata(RestaurantItemPageEdit, {
      title: 'Edit Restaurant Item',
    }),
    guarded: true,
  },

  // Restaurant Categories
  {
    path: Route.CATEGORY_LIST,
    Component: withMetadata(RestaurantCategoryPageList, {
      title: 'Restaurant Tag',
    }),
    guarded: true,
  },
  {
    path: Route.CATEGORY_CREATE,
    Component: withMetadata(RestaurantCategoryPageCreate, {
      title: 'Create Restaurant Tag',
    }),
    guarded: true,
  },
  {
    path: Route.CATEGORY_EDIT,
    Component: withMetadata(RestaurantCategoryPageEdit, {
      title: 'Edit Restaurant Tag',
    }),
    guarded: true,
  },

  // Restaurant Menu
  {
    path: Route.TAG_LIST,
    Component: withMetadata(RestaurantMenuPageList, {
      title: 'Restaurant Category',
    }),
    guarded: true,
  },
  {
    path: Route.TAG_CREATE,
    Component: withMetadata(RestaurantMenuPageCreate, {
      title: 'Create Restaurant Category',
    }),
    guarded: true,
  },
  {
    path: Route.TAG_EDIT,
    Component: withMetadata(RestaurantMenuPageEdit, {
      title: 'Edit Restaurant Category',
    }),
    guarded: true,
  },

  // Restaurant Menu Products
  {
    path: Route.TAG_PRODUCT_LIST,
    Component: withMetadata(RestaurantMenuProductPageList, {
      title: 'Restaurant Category Product',
    }),
    guarded: true,
  },
  {
    path: Route.TAG_PRODUCT_CREATE,
    Component: withMetadata(RestaurantMenuProductPageCreate, {
      title: 'Create Restaurant Category Product',
    }),
    guarded: true,
  },
  {
    path: Route.TAG_PRODUCT_EDIT,
    Component: withMetadata(RestaurantMenuProductPageEdit, {
      title: 'Edit Restaurant Category Product',
    }),
    guarded: true,
  },
];

export default routes;
