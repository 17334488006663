import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as SectionRoute } from 'context/Section/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import Lozenge from '@atlaskit/lozenge';
import {
  TableHeadDefinition,
  TableRowDataDefinition,
  TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';

class SectionCategoryPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchSectionCategories();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Section Category',
        route: formatRoute(SectionRoute.CATEGORY_LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'title',
          content: 'Title',
          isSortable: false,
          width: 25,
        },
        {
          key: 'showOnConcierge',
          content: 'Concierge',
          isSortable: false,
          width: 10,
        },
        {
          key: 'showOnOrder',
          content: 'Order',
          isSortable: false,
          width: 10,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      showOnConcierge: ({ showOnConcierge }: TableRowDataDefinition & { showOnConcierge: boolean }): ReactNode => (
        <Lozenge appearance={ showOnConcierge === true ? 'success' : 'removed' }>
          { showOnConcierge ? 'Visible' : 'Hidden' }
        </Lozenge>
      ),

      showOnOrder: ({ showOnOrder }: TableRowDataDefinition & { showOnOrder: boolean }): ReactNode => (
        <Lozenge appearance={ showOnOrder === true ? 'success' : 'removed' }>
          { showOnOrder ? 'Visible' : 'Hidden' }
        </Lozenge>
      ),
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Section Category'
              head={head}
              data={this.props.sectionCategories}
              dataTransformation={dataTransformation}
              showActions={true}
              creationRoute={SectionRoute.CATEGORY_CREATE}
              modificationRoute={(id: string): string => formatRoute(SectionRoute.CATEGORY_EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removeSectionCategory(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ section: { sectionCategories } }: any) => {
  return {
    sectionCategories,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchSectionCategories: () => dispatch({ type: ActionType.FETCH_SECTION_CATEGORIES }),
    removeSectionCategory: (id: string) => {
      dispatch({ type: ActionType.REMOVE_SECTION_CATEGORY, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCategoryPageList);
