export const ActionType = {
  FETCH_PARTNER_TYPES: 'FETCH_PARTNER_TYPES',
  FETCH_PARTNER_TYPES_FULFILLED: 'FETCH_PARTNER_TYPES_FULFILLED',
  FETCH_PARTNER_TYPE_FULFILLED: 'FETCH_PARTNER_TYPE_FULFILLED',
  FETCH_PARTNER_TYPE: 'FETCH_PARTNER_TYPE',
  CREATE_PARTNER_TYPE: 'CREATE_PARTNER_TYPE',
  UPDATE_PARTNER_TYPE: 'UPDATE_PARTNER_TYPE',
  REMOVE_PARTNER_TYPE: 'REMOVE_PARTNER_TYPE',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',

  FETCH_PARTNER_ITEMS: 'FETCH_PARTNER_ITEMS',
  FETCH_PARTNER_ITEMS_FULFILLED: 'FETCH_PARTNER_ITEMS_FULFILLED',
  FETCH_PARTNER_ITEM_FULFILLED: 'FETCH_PARTNER_ITEM_FULFILLED',
  FETCH_PARTNER_ITEM: 'FETCH_PARTNER_ITEM',
  CREATE_PARTNER_ITEM: 'CREATE_PARTNER_ITEM',
  UPDATE_PARTNER_ITEM: 'UPDATE_PARTNER_ITEM',
  REMOVE_PARTNER_ITEM: 'REMOVE_PARTNER_ITEM',
};
