import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as BlogRoutes } from '../Router/types';
import AdminForm from '../Component/Form';
import { ActionType } from '../Store/types';
import { withRouter } from 'react-router';

class BlogEdit extends Component<any, any> {
  componentDidMount(): void {
    this.props.fetchBlog(this.props.match.params.id);
  }

  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Blog',
              route: formatRoute(BlogRoutes.LIST),
            },
            {
              title: 'Edit',
              route: formatRoute(BlogRoutes.EDIT),
            },
          ]
        }
        >
        </LayoutTitle>
        <AdminForm {...this.props.currentBlog} />
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ blog: { currentBlog } }: any) => {
  return {
    currentBlog,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchBlog: (id: string) => dispatch({ type: ActionType.FETCH_BLOG, ...{ id } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlogEdit));
