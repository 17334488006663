import { ActionType } from './types';

export function fetchGifts() {
  return {
    type: ActionType.FETCH_GIFTS,
  };
}

export function fetchGiftsFullFilled(gifts: [] = []) {
  return {
    type: ActionType.FETCH_GIFTS_FULFILLED,
    gifts,
  };
}

export function fetchGiftFullFilled(currentGift: object) {
  return {
    type: ActionType.FETCH_GIFT_FULFILLED,
    currentGift,
  };
}

export function fetchGift(id: string) {
  return {
    type: ActionType.FETCH_GIFT,
    id,
  };
}

export function createGift(data: object) {
  return {
    type: ActionType.CREATE_GIFT,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

export function updateGift(id: string, data: object) {
  return {
    type: ActionType.UPDATE_GIFT,
    id,
    data,
  };
}

export function removeGift(id: string) {
  return {
    type: ActionType.REMOVE_GIFT,
    id,
  };
}
