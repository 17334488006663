import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title.js';
import { formatRoute } from 'react-router-named-routes';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as CategoryRoutes } from '../../Router/types';
import SectionCategoryForm from '../../Component/Category/Form';

class SectionCategoryPageCreate extends Component<any, any> {
  render() {
    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={
          [
            {
              title: 'Dashboard',
              route: formatRoute(HomeRoutes.HOME),
            },
            {
              title: 'Section Category',
              route: formatRoute(CategoryRoutes.CATEGORY_LIST),
            },
            {
              title: 'Create',
              route: formatRoute(CategoryRoutes.CATEGORY_CREATE),
            },
          ]
        }
        >
        </LayoutTitle>
        <SectionCategoryForm />
      </LayoutWrapper>
    );
  }
}

export default connect()(SectionCategoryPageCreate);
