import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as RestaurantRoute } from 'context/Order/Food/Router/types';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { BreadcrumbDefinition } from 'context/Core/types';
import {
  TableHeadDefinition,
  TableRowDataDefinition,
  TableRowDataTransformation,
} from 'context/Core/Component/Layout/Module/Table/types';
import { ActionType } from '../../Store/types';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import { Link, withRouter } from 'react-router-dom';

class RestaurantItemPageList extends Component<any, any> {
  componentDidMount() {
    this.props.fetchRestaurantItems();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Restaurant',
        route: formatRoute(RestaurantRoute.LIST),
      },
    ];

    const head: TableHeadDefinition = {
      cells: [
        {
          key: 'id',
          content: '#',
          isSortable: true,
          width: 5,
        },
        {
          key: 'thumbnail',
          content: 'Thumbnail',
          isSortable: false,
          width: 5,
        },
        {
          key: 'name',
          content: 'Name',
          isSortable: false,
          width: 25,
        },
        {
          key: 'slug',
          content: 'Restaurant Slug',
          isSortable: false,
          width: 25,
        },
        {
          key: '_id',
          content: 'Restaurant Menu',
          isSortable: false,
          width: 25,
        },
      ],
    };

    const dataTransformation: TableRowDataTransformation = {
      thumbnail: ({ thumbnail }: TableRowDataDefinition & {
        thumbnail: string,
      }): ReactNode => (
        <AvatarItem avatar={<Avatar src={thumbnail} />} />
      ),
      _id: ({ _id }): ReactNode => (
        <Link to={formatRoute(RestaurantRoute.TAG_LIST, { id: _id })}>
          <Button appearance={'default'} iconBefore={<AppSwitcherIcon label={'Restaurant Menu'} size='small' />} />
        </Link>
      ),
    };

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          {
            <ModuleTable
              title='Restaurant Item'
              head={head}
              data={this.props.items}
              dataTransformation={dataTransformation}
              showActions={true}
              creationRoute={RestaurantRoute.CREATE}
              modificationRoute={(id: string): string => formatRoute(RestaurantRoute.EDIT, { id })}
              onDeleteConfirmed={(id: string): any => this.props.removeRestaurantItem(id)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ orderFood: { items } }: any) => {
  return {
    items,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchRestaurantItems: () => dispatch({ type: ActionType.FETCH_RESTAURANT_ITEMS }),
    removeRestaurantItem: (id: string) => {
      dispatch({ type: ActionType.REMOVE_RESTAURANT_ITEM, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RestaurantItemPageList));
