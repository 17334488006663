import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchRestaurantItems,
  redirectAfterCreation,
  fetchRestaurantItemFullFilled,
  fetchRestaurantItemsFullFilled,
  fetchRestaurantCategoryFullFilled,
  fetchRestaurantCategories,
  fetchRestaurantCategoriesFullFilled,
  fetchRestaurantTagFullFilled,
  fetchRestaurantTags,
  fetchRestaurantTagsFullFilled,
  fetchRestaurantTagProductFullFilled,
  fetchRestaurantTagProducts,
  fetchRestaurantTagProductsFullFilled,
} from './actions';
import { RestaurantCategory, Restaurant, RestaurantTag, RestaurantTagItem } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchRestaurantItemsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_ITEMS),
    mergeMap(() => {
      return from(Service.request(Restaurant.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchRestaurantItemsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createRestaurantItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_RESTAURANT_ITEM),
    mergeMap(({ payload }: any) => {
      return from(Service.request(Restaurant.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Restaurant Item successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeRestaurantItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_RESTAURANT_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Restaurant.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchRestaurantItems()),
            of(showNotification('Restaurant Item successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateRestaurantItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_RESTAURANT_ITEM),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Restaurant.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchRestaurantItems()),
            of(showNotification('Restaurant Item successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchRestaurantItemEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_ITEM),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Restaurant.find, { id })).pipe(
        map((response: any) => fetchRestaurantItemFullFilled(response)),
      );
    }),
  );
};

// Restaurant Categories
const fetchRestaurantCategoriesEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_CATEGORIES),
    mergeMap(() => {
      return from(Service.request(RestaurantCategory.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchRestaurantCategoriesFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createRestaurantCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_RESTAURANT_CATEGORY),
    mergeMap(({ payload }: any) => {
      return from(Service.request(RestaurantCategory.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Restaurant Category successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeRestaurantCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_RESTAURANT_CATEGORY),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(RestaurantCategory.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchRestaurantCategories()),
            of(showNotification('Restaurant Category successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateRestaurantCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_RESTAURANT_CATEGORY),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(RestaurantCategory.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchRestaurantCategories()),
            of(showNotification('Restaurant Category successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchRestaurantCategoryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_CATEGORY),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(RestaurantCategory.find, { id })).pipe(
        map((response: any) => fetchRestaurantCategoryFullFilled(response)),
      );
    }),
  );
};

// Restaurant Tags
const fetchRestaurantTagsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_TAGS),
    mergeMap(({ restaurantId }: any) => {
      return from(Service.request(RestaurantTag.list, { restaurantId })).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchRestaurantTagsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createRestaurantTagEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_RESTAURANT_TAG),
    mergeMap(({ payload, restaurantId }: any) => {
      return from(Service.request(RestaurantTag.create, { restaurantId }, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Restaurant tag successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeRestaurantTagEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_RESTAURANT_TAG),
    mergeMap(({ id, restaurantId }: { id: string, restaurantId: string }) => {
      return from(Service.request(RestaurantTag.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchRestaurantTags(restaurantId)),
            of(showNotification('Restaurant tag successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateRestaurantTagEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_RESTAURANT_TAG),
    mergeMap(({ payload, restaurantId }: { payload: object, restaurantId: string }) => {
      return from(Service.request(RestaurantTag.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchRestaurantTags(restaurantId)),
            of(showNotification('Restaurant tag successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchRestaurantTagEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_TAG),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(RestaurantTag.find, { id })).pipe(
        map((response: any) => fetchRestaurantTagFullFilled(response)),
      );
    }),
  );
};

// Restaurant Tag Products - Category Products
const fetchRestaurantTagProductsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_TAG_PRODUCTS),
    mergeMap(({ restaurantId, tagId }: any) => {
      return from(Service.request(RestaurantTagItem.list, { restaurantId, tagId })).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchRestaurantTagProductsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createRestaurantTagProductEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_TAG_PRODUCT),
    mergeMap(({ payload, restaurantId, tagId }: any) => {
      return from(Service.request(RestaurantTagItem.create, { restaurantId, tagId }, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Restaurant category product successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeRestaurantTagProductEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_TAG_PRODUCT),
    mergeMap(({ id, restaurantId, tagId }: { id: string, restaurantId: string, tagId: string }) => {
      return from(Service.request(RestaurantTagItem.delete, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchRestaurantTagProducts(restaurantId, tagId)),
            of(showNotification('Restaurant category product successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateRestaurantTagProductEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_TAG_PRODUCT),
    mergeMap(({ payload, restaurantId, tagId }: { payload: object, restaurantId: string, tagId: string }) => {
      return from(Service.request(RestaurantTagItem.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchRestaurantTagProducts(restaurantId, tagId)),
            of(showNotification('Restaurant category product successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchRestaurantTagProductEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_TAG_PRODUCT),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(RestaurantTagItem.find, { id })).pipe(
        map((response: any) => fetchRestaurantTagProductFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchRestaurantItemsEpic,
  fetchRestaurantItemEpic,
  createRestaurantItemEpic,
  updateRestaurantItemEpic,
  removeRestaurantItemEpic,

  fetchRestaurantCategoriesEpic,
  fetchRestaurantCategoryEpic,
  createRestaurantCategoryEpic,
  updateRestaurantCategoryEpic,
  removeRestaurantCategoryEpic,

  fetchRestaurantTagsEpic,
  fetchRestaurantTagEpic,
  createRestaurantTagEpic,
  updateRestaurantTagEpic,
  removeRestaurantTagEpic,

  fetchRestaurantTagProductsEpic,
  fetchRestaurantTagProductEpic,
  createRestaurantTagProductEpic,
  updateRestaurantTagProductEpic,
  removeRestaurantTagProductEpic,
};
