import { ActionType } from './types';

export function fetchRequests(payload: any = {}) {
  return {
    type: ActionType.FETCH_REQUESTS,
    payload,
  };
}

export function fetchRequestsFulFilled(requests: [] = []) {
  return {
    type: ActionType.FETCH_REQUESTS_FULFILLED,
    requests,
  };
}

export function fetchRequest() {
  return {
    type: ActionType.FETCH_REQUEST,
  };
}

export function fetchRequestFulFilled(request: {} = {}) {
  return {
    type: ActionType.FETCH_REQUEST_FULFILLED,
    request,
  };
}

export function updateConciergeRequest(data: any) {
  return {
    type: ActionType.UPDATE_REQUEST,
    data,
  };
}
