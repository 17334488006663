import { ActionType } from './types';

const initialState = {
  rentingTypes: [],
  rentingSubtypes: [],
  currentRentingType: undefined,
  currentRentingSubtype: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_RENTING_TYPES:
    case ActionType.FETCH_RENTING_TYPE:
    case ActionType.FETCH_RENTING_TYPE_FULFILLED:
      const rentingType = action.rentingType;
      return { ...state, currentRentingType: rentingType };

    case ActionType.REMOVE_RENTING_TYPE:
      return { ...state };

    case ActionType.FETCH_RENTING_TYPES_FULFILLED:
      const rentingTypes = action.rentingTypes || [];
      return { ...state, rentingTypes };

    case ActionType.CREATE_RENTING_TYPE:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    // Sub types
    case ActionType.FETCH_RENTING_SUBTYPES:
    case ActionType.FETCH_RENTING_SUBTYPE:
    case ActionType.FETCH_RENTING_SUBTYPE_FULFILLED:
      const rentingSubtype = action.rentingSubType;
      return { ...state, currentRentingSubtype: rentingSubtype };

    case ActionType.REMOVE_RENTING_SUBTYPE:
      return { ...state };

    case ActionType.FETCH_RENTING_SUBTYPES_FULFILLED:
      const rentingSubtypes = action.rentingSubtypes || [];
      return { ...state, rentingSubtypes };

    case ActionType.CREATE_RENTING_SUBTYPE:
      return { ...state };

    default:
      return state;
  }
};
