import React, { Component } from 'react';
import { connect } from 'react-redux';

import Page from '@atlaskit/page';
import '@atlaskit/css-reset';
import Sidebar from 'context/Core/Component/Layout/Sidebar';
import './styles.css';
import Loader from './Component/Loader';
import Toast from './Component/Layout/Toast';

class App extends Component<any, any> {
  render() {
    return (
      <>
        { this.props.showLoader ? <Loader /> : ('') }
        <div style={{ opacity: `${this.props.showLoader ? '.' : ''}1` }}>
          <Page
            id={'ak-page-wrapper'}
            navigationWidth={this.props.isAuthenticated ? 304 : 0}
            navigation={this.props.isAuthenticated && <Sidebar />}
          >
            {this.props.children}
          </Page>

          <Toast socket={this.props.socket} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any): any => {
  const { isAuthenticated } = state.auth;
  const { showLoader, socket } = state.core;

  return {
    isAuthenticated,
    showLoader,
    socket,
  };
};

export default connect(mapStateToProps)(App);
