export const ActionType = {
  FETCH_GIFTS: 'FETCH_GIFTS',
  FETCH_GIFTS_FULFILLED: 'FETCH_GIFTS_FULFILLED',
  FETCH_GIFT_FULFILLED: 'FETCH_GIFT_FULFILLED',
  FETCH_GIFT: 'FETCH_GIFT',
  CREATE_GIFT: 'CREATE_GIFT',
  UPDATE_GIFT: 'UPDATE_GIFT',
  REMOVE_GIFT: 'REMOVE_GIFT',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
