import { ActionType } from './types';

const initialState = {
  gifts: [],
  currentGift: undefined,
  shouldRedirect: false,
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_GIFTS:
    case ActionType.FETCH_GIFT:
    case ActionType.FETCH_GIFT_FULFILLED:
      const currentGift = action.currentGift;
      return { ...state, currentGift: currentGift };

    case ActionType.REMOVE_GIFT:
      return { ...state };

    case ActionType.FETCH_GIFTS_FULFILLED:
      const gifts = action.gifts || [];
      return { ...state, gifts };

    case ActionType.CREATE_GIFT:
      return { ...state };

    case ActionType.REDIRECT_AFTER_CREATION:
      return { ...state, shouldRedirect: action.shouldRedirect };

    default:
      return state;
  }
};
