import { ActionType } from './types';

export function fetchRestaurantItems() {
  return {
    type: ActionType.FETCH_RESTAURANT_ITEMS,
  };
}

export function fetchRestaurantItemsFullFilled(items: [] = []) {
  return {
    type: ActionType.FETCH_RESTAURANT_ITEMS_FULFILLED,
    items,
  };
}

export function fetchRestaurantItemFullFilled(currentRestaurantItem: object) {
  return {
    type: ActionType.FETCH_RESTAURANT_ITEM_FULFILLED,
    currentRestaurantItem,
  };
}

export function fetchRestaurantItem(id: string) {
  return {
    type: ActionType.FETCH_RESTAURANT_ITEM,
    id,
  };
}

export function updateRestaurantItem(id: string, data: object) {
  return {
    type: ActionType.UPDATE_RESTAURANT_ITEM,
    id,
    data,
  };
}

export function removeRestaurantItem(id: string) {
  return {
    type: ActionType.REMOVE_RESTAURANT_ITEM,
    id,
  };
}

// Restaurant Categories
export function fetchRestaurantCategories() {
  return {
    type: ActionType.FETCH_RESTAURANT_CATEGORIES,
  };
}

export function fetchRestaurantCategoriesFullFilled(restaurantCategories: [] = []) {
  return {
    type: ActionType.FETCH_RESTAURANT_CATEGORIES_FULFILLED,
    restaurantCategories,
  };
}

export function fetchRestaurantCategoryFullFilled(currentRestaurantCategory: object) {
  return {
    type: ActionType.FETCH_RESTAURANT_CATEGORY_FULFILLED,
    currentRestaurantCategory,
  };
}

export function fetchRestaurantCategory(id: string) {
  return {
    type: ActionType.FETCH_RESTAURANT_CATEGORY,
    id,
  };
}

export function updateRestaurantCategory(id: string, data: object) {
  return {
    type: ActionType.UPDATE_RESTAURANT_CATEGORY,
    id,
    data,
  };
}

export function removeRestaurantCategory(id: string) {
  return {
    type: ActionType.REMOVE_RESTAURANT_CATEGORY,
    id,
  };
}

export function createRestaurantCategory(data: object) {
  return {
    type: ActionType.CREATE_RESTAURANT_CATEGORY,
    data,
  };
}

// Restaurant Tags
export function fetchRestaurantTags(restaurantId?: string) {
  return {
    type: ActionType.FETCH_RESTAURANT_TAGS,
    restaurantId,
  };
}

export function fetchRestaurantTagsFullFilled(restaurantTags: [] = []) {
  return {
    type: ActionType.FETCH_RESTAURANT_TAGS_FULFILLED,
    restaurantTags,
  };
}

export function fetchRestaurantTagFullFilled(currentRestaurantTag: object) {
  return {
    type: ActionType.FETCH_RESTAURANT_TAG_FULFILLED,
    currentRestaurantTag,
  };
}

export function fetchRestaurantTag(id: string) {
  return {
    type: ActionType.FETCH_RESTAURANT_TAG,
    id,
  };
}

export function updateRestaurantTag(id: string, data: object) {
  return {
    type: ActionType.UPDATE_RESTAURANT_TAG,
    id,
    data,
  };
}

export function removeRestaurantTag(id: string) {
  return {
    type: ActionType.REMOVE_RESTAURANT_TAG,
    id,
  };
}

export function createRestaurantTag(data: object) {
  return {
    type: ActionType.CREATE_RESTAURANT_TAG,
    data,
  };
}

// Restaurant Tag Products
export function fetchRestaurantTagProducts(restaurantId: string, tagId: string) {
  return {
    type: ActionType.FETCH_TAG_PRODUCTS,
    restaurantId,
    tagId,
  };
}

export function fetchRestaurantTagProductsFullFilled(restaurantTagProducts: [] = []) {
  return {
    type: ActionType.FETCH_TAG_PRODUCTS_FULFILLED,
    restaurantTagProducts,
  };
}

export function fetchRestaurantTagProductFullFilled(currentRestaurantTagProduct: object) {
  return {
    type: ActionType.FETCH_TAG_PRODUCT_FULFILLED,
    currentRestaurantTagProduct,
  };
}

export function fetchRestaurantTagProduct(id: string) {
  return {
    type: ActionType.FETCH_TAG_PRODUCT,
    id,
  };
}

export function updateRestaurantTagProduct(id: string, data: object) {
  return {
    type: ActionType.UPDATE_TAG_PRODUCT,
    id,
    data,
  };
}

export function removeRestaurantTagProduct(id: string) {
  return {
    type: ActionType.REMOVE_TAG_PRODUCT,
    id,
  };
}

export function createRestaurantTagProduct(restaurantId: string, tagId: string, data: object) {
  return {
    type: ActionType.CREATE_TAG_PRODUCT,
    data,
    restaurantId,
    tagId,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}
