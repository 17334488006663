import React, { Component, ReactNode } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';

export default class TabFood extends Component<any, any> {
  render() {
    const dataTransformation: TableRowDataTransformation = {
      restaurant: ({restaurant}: any & {
        restaurant: string,
      }): ReactNode => (
        <AvatarItem
          avatar={<Avatar src={restaurant.thumbnail}/>}
          primaryText={restaurant.name}
        />
      ),
      extras: ({ extras }: any) => {
        return (
          <>
            <Tooltip content={extras.map((extra: any) => `${extra.label.toUpperCase()}`).join(',')}>
              <Button>Hover</Button>
            </Tooltip>
          </>
        );
      },
      price: ({ price }: any) => price + '€',
      total: ({ price, quantity }: any) => price * quantity + '€',
    };

    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'restaurant',
              content: 'Restaurant',
              isSortable: false,
              width: 25,
            },
            {
              key: 'category',
              content: 'Category',
              isSortable: false,
              width: 25,
            },
            {
              key: 'title',
              content: 'Product',
              isSortable: false,
              width: 25,
            },
            {
              key: 'quantity',
              content: 'Quantity',
              isSortable: false,
              width: 25,
            },
            {
              key: 'extras',
              content: 'Extras',
              isSortable: false,
              width: 25,
            },
            {
              key: 'price',
              content: 'Price',
              isSortable: false,
              width: 25,
            },
            {
              key: 'total',
              content: 'Total',
              isSortable: false,
              width: 25,
            },
          ],
        }}
        dataTransformation={dataTransformation}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={(this.props.request.data.food || []).map(
          (food: any) => ({
            id: food.item.id,
            title: food.item.title,
            quantity: food.quantity,
            restaurant: food.restaurant,
            price: Number(food.item.price),
            total: Number(food.item.price),
            extras: food.item.extras,
            category: food.category.title,
          }),
        )}
      />
    );
  }
}
