import { ActionType } from './types';
import moment from 'moment';

const initialState = {
  requests: [],
  request: {},
  form: {
    from: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD').toString(),
    to: moment(new Date()).add(1, 'days').format('YYYY-MM-DD').toString(),
    type: 'all',
    status: 'all',
    id: null,
  },
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.ADD_REQUEST:
      return { ...state, requests: [action.payload].concat(state.requests)};

    case ActionType.MUTATE_FORM:
      return { ...state, ...{ form: { ...state.form, [action.key]: action.value } } };

    case ActionType.FETCH_REQUEST:
      return { ...state, request: initialState.request };

    case ActionType.FETCH_REQUEST_FULFILLED:
      return { ...state, request: action.request };

    case ActionType.UPDATE_REQUEST:
      return { ...state, data: action.data };
    case ActionType.FETCH_REQUESTS:
      return { ...state, requests: initialState.requests, payload: state.form };

    case ActionType.FETCH_REQUESTS_FULFILLED:
      return { ...state, requests: action.requests };

    default:
      return state;
  }
};
