import { ActionType } from './types';

export function fetchDispatchers() {
  return {
    type: ActionType.FETCH_DISPATCHERS,
  };
}

export function fetchDispatchersFullFilled(dispatchers: [] = []) {
  return {
    type: ActionType.FETCH_DISPATCHERS_FULFILLED,
    dispatchers,
  };
}

export function fetchDispatcherFullFilled(dispatcher: object) {
  return {
    type: ActionType.FETCH_DISPATCHER_FULFILLED,
    dispatcher,
  };
}

export function fetchDispatcher(id: string) {
  return {
    type: ActionType.FETCH_DISPATCHER,
    id,
  };
}

export function createDispatcher(data: object) {
  return {
    type: ActionType.CREATE_DISPATCHER,
    data,
  };
}

export function redirectAfterCreation(shouldRedirect: boolean) {
  return {
    type: ActionType.REDIRECT_AFTER_CREATION,
    shouldRedirect,
  };
}

export function updateDispatcher(id: string, data: object) {
  return {
    type: ActionType.UPDATE_DISPATCHER,
    id,
    data,
  };
}

export function removeDispatcher(id: string) {
  return {
    type: ActionType.REMOVE_DISPATCHER,
    id,
  };
}
