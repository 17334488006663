export const ActionType = {
  FETCH_BLOGS: 'FETCH_BLOGS',
  FETCH_BLOGS_FULFILLED: 'FETCH_BLOGS_FULFILLED',
  FETCH_BLOG_FULFILLED: 'FETCH_BLOG_FULFILLED',
  FETCH_BLOG: 'FETCH_BLOG',
  CREATE_BLOG: 'CREATE_BLOG',
  UPDATE_BLOG: 'UPDATE_BLOG',
  REMOVE_BLOG: 'REMOVE_BLOG',
  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
