export const ActionType = {
  FETCH_DRINK_ITEMS: 'FETCH_DRINK_ITEMS',
  FETCH_DRINK_ITEMS_FULFILLED: 'FETCH_DRINK_ITEMS_FULFILLED',
  FETCH_DRINK_ITEM_FULFILLED: 'FETCH_DRINK_ITEM_FULFILLED',
  FETCH_DRINK_ITEM: 'FETCH_DRINK_ITEM',
  CREATE_DRINK_ITEM: 'CREATE_DRINK_ITEM',
  UPDATE_DRINK_ITEM: 'UPDATE_DRINK_ITEM',
  REMOVE_DRINK_ITEM: 'REMOVE_DRINK_ITEM',

  FETCH_DRINK_CATEGORIES: 'FETCH_DRINK_CATEGORIES',
  FETCH_DRINK_CATEGORIES_FULFILLED: 'FETCH_DRINK_CATEGORIES_FULFILLED',
  FETCH_DRINK_CATEGORY_FULFILLED: 'FETCH_DRINK_CATEGORY_FULFILLED',
  FETCH_DRINK_CATEGORY: 'FETCH_DRINK_CATEGORY',
  CREATE_DRINK_CATEGORY: 'CREATE_DRINK_CATEGORY',
  UPDATE_DRINK_CATEGORY: 'UPDATE_DRINK_CATEGORY',
  REMOVE_DRINK_CATEGORY: 'REMOVE_DRINK_CATEGORY',

  REDIRECT_AFTER_CREATION: 'REDIRECT_AFTER_CREATION',
};
