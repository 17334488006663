import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import styled from 'styled-components';

import LayoutWrapper from 'context/Core/Component/Layout/Wrapper';
import LayoutTitle from 'context/Core/Component/Layout/Title';
import { Route as HomeRoutes } from 'context/Home/Router/types';
import { Route as Routes } from 'context/Concierge/TripPlanning/Router/types';
import { BreadcrumbDefinition } from 'context/Core/types';
import TreeRenderer from 'context/Core/Component/Tree/Renderer';

import { ActionType } from '../Store/types';

const TopWrapper = styled.div`
  margin: 0 0 30px 0;
`;

class ReservationRootPage extends Component<any, any> {
  componentDidMount() {
    this.props.fetchTripPlanning();
  }

  render() {
    const breadcrumbs: BreadcrumbDefinition[] = [
      {
        title: 'Dashboard',
        route: formatRoute(HomeRoutes.HOME),
      },
      {
        title: 'Trip Planning Builder',
        route: formatRoute(Routes.TRIP_PLANNING_ROOT),
      },
    ];

    return (
      <LayoutWrapper>
        <LayoutTitle breadcrumbs={breadcrumbs} />
        <section>
          <TopWrapper>
            <h3 style={{ display: 'inline-block' }}>
              Trip Planning Builder
            </h3>
          </TopWrapper>
          {
            <TreeRenderer
              reservation={this.props.tripPlanning}
              onUpdate={(payload: any) => this.props.updateTripPlanning(payload)}
            />
          }
        </section>
      </LayoutWrapper>
    );
  }
}

const mapStateToProps = ({ conciergeTripplanning: { tripPlanning } }: any) => {
  return {
    tripPlanning,
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return {
    fetchTripPlanning: () => dispatch({ type: ActionType.FETCH_TRIP_PLANNING }),
    updateTripPlanning: (payload: any) => dispatch({ type: ActionType.UPDATE_TRIP_PLANNING, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRootPage);
