import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchCouriers,
  redirectAfterCreation,
  fetchCourierFullFilled,
  fetchCouriersFullFilled,
} from './actions';
import { Courier } from 'medium/api';
import { showNotification } from 'context/Core/Store/actions';

const fetchCouriersEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_COURIERS),
    mergeMap(() => {
      return from(Service.request(Courier.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(redirectAfterCreation(false)),
              of(fetchCouriersFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const createCourierEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.CREATE_COURIER),
    mergeMap(({ payload }: any) => {
      return from(Service.request(Courier.create, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(showNotification('Courier successfully created.')),
          );
        }),
      );
    }),
  );
};

const removeCourierEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REMOVE_COURIER),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Courier.remove, { id })).pipe(
        flatMap(() => {
          return concat(
            of(fetchCouriers()),
            of(showNotification('Courier successfully removed.')),
          );
        }),
      );
    }),
  );
};

const updateCourierEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_COURIER),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Courier.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(redirectAfterCreation(true)),
            of(fetchCouriers()),
            of(showNotification('Courier successfully updated.')),
          );
        }),
      );
    }),
  );
};

const fetchCourierEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_COURIER),
    mergeMap(({ id }: { id: string }) => {
      return from(Service.request(Courier.find, { id })).pipe(
        map((response: any) => fetchCourierFullFilled(response)),
      );
    }),
  );
};

export default {
  fetchCouriersEpic,
  createCourierEpic,
  fetchCourierEpic,
  updateCourierEpic,
  removeCourierEpic,
};
