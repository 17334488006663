import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from '../../Router/types';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { ActionType } from '../../Store/types';
import { Redirect, withRouter } from 'react-router';
import FormFooter from 'context/Core/Component/Form/Footer';
import ButtonBack from 'context/Core/Component/Form/ButtonBack';
import Select from '@atlaskit/select';

class RentingTypeForm extends Component<any, any> {
  componentDidMount(): void {
    this.props.fetchRentingTypes();
  }

  static defaultProps = {
    name: '',
    type: [],
  };

  render() {
    const redirectToReferrer = this.props.shouldRedirect;
    if (redirectToReferrer === true) {
      return <Redirect to={Route.SUBTYPE_LIST} />;
    }

    return (
      <section>
        <ButtonBack path={Route.SUBTYPE_LIST} titleBefore={'Renting Subtype Form'} />
        <Form
          onSubmit={(data: { data: object }) => this.props.submit(data, this.props.match.params.id)}>
          {({ formProps }: { formProps: object }) => (
            <form {...formProps} name={'renting-subtype'} >

              <Field name={'name'} defaultValue={this.props.name} isRequired={true} label={'Subtype Name'}>
                {({ fieldProps }: { fieldProps: any }) => <TextField placeholder={'Subtype Name'} {...fieldProps} />}
              </Field>

              <Field
                name={'type'}
                defaultValue={this.props.rentingTypes.filter((option: any) => option.value === this.props.type)}
                isRequired={true}
                label={'Select Type'}
              >
                {({ fieldProps }: { fieldProps: any }) => (
                  <Select
                    className={'single-select'}
                    classNamePrefix={'react-select'}
                    options={this.props.rentingTypes}
                    placeholder={'Choose a renting type'}
                    {...fieldProps}
                  />
                )}
              </Field>

              <FormFooter />
            </form>
          )}
        </Form>
      </section>
    );
  }
}

const mapStateToProps = ({ conciergeRenting: { shouldRedirect, rentingTypes } }: any) => {
  return {
    shouldRedirect,
    rentingTypes: rentingTypes.map(({ id, name }: any) => {
      return { label: name, value: id };
    }),
  };
};

const mapDispatchToProps = (dispatch: any): object => {
  return ({
    submit: (formData: any, id: any) => {
      const type = id ? ActionType.UPDATE_RENTING_SUBTYPE : ActionType.CREATE_RENTING_SUBTYPE;
      formData.type = formData.type.value;
      dispatch({ type, payload: { ...formData, id } });
    },

    fetchRentingTypes: () => dispatch({ type: ActionType.FETCH_RENTING_TYPES }),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RentingTypeForm));
