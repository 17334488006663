import { flatMap, map, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchSettings,
  fetchSettingsFulFilled,
} from './actions';
import { Settings } from 'medium/api';

const updateSettingsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.UPDATE_SETTINGS),
    mergeMap(({ payload }: { payload: object }) => {
      return from(Service.request(Settings.update, {}, payload)).pipe(
        flatMap(() => {
          return concat(
            of(fetchSettings()),
          );
        }),
      );
    }),
  );
};

const fetchSettingsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SETTINGS),
    mergeMap(() => {
      return from(Service.request(Settings.list)).pipe(
        map((response: any) => fetchSettingsFulFilled(response)),
      );
    }),
  );
};

export default {
  updateSettingsEpic,
  fetchSettingsEpic,
};
