import React from 'react';
import ModuleTable from '../../../../Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from '../../../../Core/Component/Layout/Module/Table/types';

class PersonalServiceRequest extends React.Component<any, any> {
  render() {
    const {
      name,
      phonePrefix,
      email,
      phoneNumber,
      item,
      location,
      zone,
      notes,
    } = this.props.request.data;

    const dataTransformation: TableRowDataTransformation = {
      phoneNumber: ({ phonePrefix, phoneNumber }: any) => phonePrefix + phoneNumber,
      item: ({ item }) => item.name,
      location: ({ location }: any) => `${location ? location.lat : ''},${location ? location.lng : ''}`,
    };

    return (
      <>
        <ModuleTable
          head={{
            cells: [
              {
                key: 'id',
                content: '#',
                isSortable: true,
                width: 5,
              },
              {
                key: 'item',
                content: 'Item',
                isSortable: false,
                width: 25,
              },
              {
                key: 'phoneNumber',
                content: 'Phone',
                isSortable: false,
                width: 25,
              },
              {
                key: 'email',
                content: 'Email',
                isSortable: false,
                width: 30,
              },
              {
                key: 'location',
                content: 'Location',
                isSortable: false,
                width: 25,
              },
              {
                key: 'zone',
                content: 'Zone',
                isSortable: false,
                width: 15,
              },
            ],
          }}
          dataTransformation={dataTransformation}
          showActions={false}
          showTableTop={[]}
          rowsPerPage={[]}
          data={[{
            id: this.props.request.id,
            name,
            phonePrefix,
            email,
            phoneNumber,
            item,
            location,
            zone,
            notes,
          }]}
        />
      </>
    );
  }
}

export default PersonalServiceRequest;
