import React, { Component } from 'react';
import ModuleTable from 'context/Core/Component/Layout/Module/Table';
import { TableRowDataTransformation } from 'context/Core/Component/Layout/Module/Table/types';

export default class TabBill extends Component<any, any> {
  render() {
    const dataTransformation: TableRowDataTransformation = {
      deliveryFee: ({ deliveryFee }: any) => deliveryFee + '€',
      orderSurcharge: ({ orderSurcharge }: any) => orderSurcharge + '€',
      orderExtraStopValue: ({ orderExtraStopValue }: any) => orderExtraStopValue + '€',
      orderAmount: ({ orderAmount }: any) => orderAmount + '€',
      orderTotal: ({ orderTotal }: any) => Number(orderTotal) + '€',
    };

    return (
      <ModuleTable
        head={{
          cells: [
            {
              key: 'id',
              content: '#',
              isSortable: true,
              width: 5,
            },
            {
              key: 'zone',
              content: 'Zone',
              isSortable: false,
              width: 25,
            },
            {
              key: 'deliveryFee',
              content: 'Delivery Fee',
              isSortable: false,
              width: 25,
            },
            {
              key: 'orderSurcharge',
              content: 'Surcharge',
              isSortable: false,
              width: 30,
            },
            {
              key: 'orderExtraStopValue',
              content: 'Extra Stop',
              isSortable: false,
              width: 25,
            },
            {
              key: 'orderTotal',
              content: 'Total',
              isSortable: false,
              width: 30,
            },
            {
              key: 'orderAmount',
              content: 'Amount',
              isSortable: false,
              width: 30,
            },
          ],
        }}
        dataTransformation={dataTransformation}
        showActions={false}
        showTableTop={[]}
        rowsPerPage={[]}
        data={[{
          id: this.props.request.id,
          zone: this.props.request.data.zone,
          deliveryFee: this.props.request.data.deliveryFee,
          orderSurcharge: this.props.request.data.orderSurcharge,
          orderExtraStopValue: this.props.request.data.orderExtraStopValue,
          orderAmount: this.props.request.data.orderAmount,
          orderTotal: this.props.request.data.orderTotal,
        }]}
      />
    );
  }
}
